import { fromJS, List } from "immutable";
import { round } from "lodash-es";
import { __, Context } from "../appcontext";
import { RealizerOperations, ViewRealizer, ViewRealizerAttachDetachControlFce, ViewRealizerManager, VRContext } from "../viewrealizer";
import {
  call,
  Helper,
  isPhoneNumber,
  Log,
  parseData,
  Size,
  GPSReader,
  isDesktopComponent,
  themeSwitchTo,
  getTheme,
  getDecimalsCountByFormat,
  copyToClipboard,
} from "./common";
import {
  Align,
  CheckBoxDataRequest,
  cJSonDefaultAcceptExecute,
  cJSonGanttContextMenu,
  cJSonRangeSelect,
  cJSonSelectPoint,
  cJSonUnselectPoint,
  cJSonFunctionContextMenu,
  cJSonFunctionExecute,
  cJSonFunctionGridOperation,
  cJsonFunctionHideFloater,
  cJsonFunctionShowFloater,
  ColumnProportionEm,
  ControlDataRequest,
  CSFile,
  CSNclButtonMetadata,
  CSNclCheckBoxMetaData,
  CSNclCommandItemMetadata,
  CSNclContainerMetadata,
  CSNclControlMetadata,
  CSNclDataGridBaseMetadata,
  CSNclInnerGanttMetadata,
  CSNclDataGridContent,
  CSNclGanttContent,
  CSNclDataGridMetadata,
  CSNclGanttMetadata,
  CSNclDataLabelMetadata,
  CSNclDynamicContentMetadata,
  CSNclExpanderMetadata,
  CSNclFloaterAccessorMetadata,
  CSNclFloaterViewMetadata,
  CSNclFormattableInputMetadata,
  CSNclGroupBoxMetaData,
  CSNclHeaderedMetadata,
  CSNclHeaderMetaData,
  CSNclImageMetadata,
  CSNclInnerDataGridMetadata,
  CSNclInputMetadata,
  CSNclKeyboardInputMetadata,
  CSNclLocatorPanelMetadata,
  CSNclMenuContainerMetadata,
  CSNclMenuDividerMetadata,
  CSNclMenuGroupMetadata,
  CSNclMenuItemBaseMetadata,
  CSNclMenuItemMetadata,
  CSNclMenuMetadata,
  CSNclMenuViewMetadata,
  CSNclPageMetadata,
  CSNclPanelMetadata,
  CSNclListViewMetadata,
  CSNclCalendarMetadata,
  CSNclPreviewPanelMetadata,
  CSNclSplitterPanelMetadata,
  CSNclTabControlMetadata,
  CSNclTabToolBarMetadata,
  CSNclToolBarMetadata,
  CSNclViewMetadata,
  CSNclVRTabControlMetadata,
  CSUFNclControlMetadata,
  CSUFUpdateControl,
  CSUpdateCommandItem,
  CSUpdateControl,
  CSUpdateDynamicContent,
  CSUpdateMenuContainer,
  DataActionDecorate,
  InnerDataGridDataRequest,
  GanttDataRequest,
  DisplayMode,
  FrgtCommandItemData,
  FrgtGridLinesStyle,
  Function,
  GlyphId,
  GridOperations,
  HorizontalAlignment,
  IconPosition,
  KeyboardInputDataRequest,
  InputDataRequest,
  OpenDialogDataRequest,
  Orientation,
  SplitterDataRequest,
  TabControlDataRequest,
  UFUpdateControl,
  Updatable,
  UpdateCheckBox,
  UpdateCommandItem,
  UpdateControl,
  UpdateDataLabel,
  UpdateDockControl,
  UpdateDynamicContent,
  UpdateExpander,
  UpdateFormattableInput,
  UpdateHeadered,
  UpdateImage,
  UpdateInnerDataGrid,
  UpdateInnerGantt,
  UpdateInput,
  UpdateMenuContainer,
  UpdateSplitterPanel,
  UpdateTabControl,
  UpdateVRTabControl,
  VerticalAlignment,
  CSNclSignInputMetadata,
  UpdateSignInput,
  SignData,
  SignInputDataRequest,
  FrameStyle,
  RectInDock,
  CSNclDataGridFooter,
  CSNclGanttFooter,
  cJSonFunctionGridChangeSortBy,
  cJSonChangeActiveDateRange,
  UpdateListView,
  UpdateCalendar,
  CSNclInnerSectionMetaData,
  CSNclInnerToolBarMetadata,
  CSNclRibbonMetadata,
  TUFActionDisplayStyle,
  CSNclBreakerMetadata,
  UpdateRibbon,
  ListViewDataRequest,
  TNclInputType,
  cJSonFunctionShowSetting,
  cJSonFunctionNextPriorValue,
  cJSonFunctionShowDocumentByK2PK,
  cJSonFunctionEditOnlineAppointment,
  DEFAULT_LANG_IMG,
  UpdateInnerToolbar,
  cJSonFunctionDragAndDropAppointment,
  UpdatePageControl,
  cJSonFunctionDeleteOnlineAppointment,
  CSNCLTreeViewMetadata,
  CSNclInnerTreeViewMetadata,
  UpdateInnerTreeView,
  cJSonFunctionToggleNodeExecute,
  TreeViewDataRequest,
  FrgtPanelBaseData,
  FrgtSplitterPanelData,
  SplitterPanelOrientation,
  TTreeDataMoveNodeMode,
  cJSonFunctionMoveNodeExecute,
  CSTreeDataItem,
  TTreeDataHasChildNodes,
  ModifyTreeItem,
  TDataItemModifyType,
  ModifyItem,
  CSDataItem,
  CSUpdateInnerDataGrid,
  CSNclQuickFilterMetadata,
  UpdateQuickFilter,
  CSUpdateQuickFilter,
  CSNclAggregationPanel,
  UpdateAggregationPanel,
  UpdateDataGrid,
  cJSonDataGridDblClickExecute,
  RibbonDataRequest,
  CSUpdateInnerTreeView,
  InnerColumnsListDataRequest,
  GetColumnWidthEm,
  ColumnsProportion,
  cJSonTreeViewDblClickExecute,
  CSUpdateInnerColumnsList,
  TUFCharCountMultiplierKind,
  TAlignment,
  TUFContextDisplayStyle,
  GetColumnWidth,
  CSTreeColumn,
  ClientData,
  CSUpdateRibbon,
  ViewDataRequest,
  ExplicitBounds,
  cJSonFunctionDefaultExplicitBounds,
  cJSonFunctionDeleteAppointment,
  CSNclClientMenuItemMetadata,
  UpdateFilePreview,
  UpdateDashboard,
  CSNCLDashboardMetadata,
  CSNclDeviceConnectorDialogMetadata,
  DeviceConnectorDataRequest,
  TBehaviorTypeByDevice,
  CSNclGPSDialogMetadata,
  GPSDataRequest,
  TDeviceConnectResult,
  cJSonFunctionPartClickExecute,
  CSUpdateDashboard,
  DashboardData,
  Part,
  PartState,
  TPartState,
  UpdateMultiFormatText,
  CSNclMultiFormatTextMetadata,
  TMouseButton,
  cJSonFunctionCloseView,
  cJSonMemberClickToExpand,
  clNone,
  AlphabetMode,
  cmdOKView,
  CSNclVirtualKeyboardDialogMetadata,
  CSUpdateInput,
  CSUpdateVirtualKeyboard,
  FrgtButtonData,
  FrgtInputData,
  TUFDisplayValueAs,
  UpdateVirtualKeyboard,
  VirtualKeyboardRequest,
  FilterPart,
  VisibleMember,
  cJSonTreeViewPartClick,
  CSNclWebViewMetadata,
  cJSonFunctionAutoClose,
  cJSonDynamicFilterPartClick,
  cJSonPartUpdate,
  CSNclCodeReaderDialogMetadata,
  CodeReaderRequest,
  cJSonFunctionODBeginUploadFile,
  cJSonFunctionODEndUploadFile,
  cJSonFunctionODCancelUploadFile,
  UpdateOpenDialogContent,
  SilentOpenDialogDataRequest,
  CSNclOpenDialogMetadata,
  cJSonExecuteCommandByNumber,
  CSUpdatePageControl,
  TGPSRequest,
  UpdateMenuItem,
  ClientActionNames,
  ModalPosition,
  cJSonExecuteCommandByNumber_InstantFilter,
  CSNclInstantFilterPanelMetadata,
  UpdateInstantFilterPanel,
  UpdateFlowChart,
  CSNclFlowChartMetadata,
  cJsonFunctionNodeClick,
  cJsonFunctionNodeDoubleClick,
  cJsonFunctionEdgeClick,
  cJsonFunctionEdgeDoubleClick,
  cJsonFunctionNodeDragStop,
  cJsonFunctionAddLink,
  cJsonFunctionAddNode,
  cJsonFunctionToolBarClick,
  cJSonFunctionResolveFocus,
  UpdateWebContentEditor,
  CSNclSimpleChartMetadata,
  UpdateSimpleChart,
  cJSonFunctionChartClick,
  CSUpdateSimpleChart,
  ReportPart,
  TSChLegendTextStyle,
  TSChMarksStyle,
  Series,
  SimpleChartReportPart,
  DataPoint,
  CSNclMapMetadata,
  UpdateMap,
  UpdateRadioBox,
  CSUFNclRadioBoxMetadata,
  RadioBoxDataRequest,
  UpdateKeyboardInput,
  FrgtKeyboardInputData,
  cJSonDatagridLinkClickExecute,
  UpdateView,
  CSUFNclClientWidgetMetadata,
  ClientWidgetUpdate,
  cJsonFunctionEdgeUpdate,
  CSNclColorPickerMetadata,
  ColorPickerDataRequest,
  cJSonFunctionAcceptData,
  CSNclWebcamMetadata,
  WebcamDataRequest,
  CSUpdateKeyboardInput,
  cJSonFunctionCloseVRPage,
  cJSonPointHover,
  cJSonUserRangeSelect,
  CSNclFilePreview,
  cJsonFunctionSelection,
  CSNclClientMenuGroupMetadata,
  cJsonFunctionNodeContextMenu,
  cJsonFunctionEdgeContextMenu,
  cJsonFunctionBackgroundClick,
  GanttUserRangeSelect,
  CSUpdateInnerGantt,
  UpdateGantt,
  CSUpdateGantt,
  cJSonGanttResolution,
  MFValue,
  TTreeViewNodeCheckState,
  cJSonFunctionCheckNodeExecute,
  TAcceptMode,
  UpdateWebView,
  DataFromDM,
  MainMenuData,
  cJSonFunctionLoadMainMenu,
  TimeAxisAppearanceType,
} from "./communication.base";
import { VisualContext } from "./visualContext";

let themeList: { [key: string]: any };

(async function () {
  try {
    const config = await fetch("./themes.json");
    themeList = await config.json();

    if (themeList["defaultTheme"] != undefined && themeList["defaultTheme"] != "") {
      // Pokud není nastaven styl uživatele
      if (!localStorage.getItem("k2theme")) {
        themeSwitchTo(themeList["defaultTheme"], false);
      }
    }
  } catch (error) {
    console.log(error);
  }
})();
import { ApplicationBase } from "../app";
import { FormatUtils } from "./formatUtils";
import { findPhoneNumbersInText } from "libphonenumber-js";

export type ForEachNestedControlFce = (control: NclControlBase) => any;

export interface UFOverAlign {
  getOverRect(): DOMRect;
}

export interface NclControlBase extends Updatable {
  setTabStop(value: boolean): unknown;
  appendFunction(fce: Function): void;
  init(listener: ControlListener): UpdateControl;
  willUnMount(onlyListener: boolean): void;
  getRealizerUID(): string;
  isInPreview(): boolean;
  afterUpdateState(): void;
  MetaData: CSNclControlMetadata;
  State: ControlStateBase;
  Listener: any; //typ any je zde schválně, jelikož je pak možné využít metody findDOMNode v reactu
  InEditMode: boolean;
  VCX: VisualContext;
  ComputedMinHeight: number;
  ComputedMinHeightWithMargin: number;
  MarginXFactor: number;
  MarginYFactor: number;
  Size: number;
  Parent: NclControlBase;
  IsFocused: boolean;
}

export interface UFNclControlBase extends NclControlBase {
  setAsActiveControl(isActive: boolean): void;
  setVCX(vcx: VisualContext, forceRefresh: boolean): void;
  MetaData: CSUFNclControlMetadata;
}

export interface ControlListener {
  setAsActive?(isActive: boolean): void;
  updateState(state: UpdateControl): void;
  updateVCX(vcxVersion: number): void;
  getOverRect?(): DOMRect | null;
  getScreenshot?(): string | null | undefined;
  updateFocus?(isFocused: boolean): void;
}

/**
 * Interface for window.
 */
export interface ViewListener extends ControlListener {
  /**
   * Method for close window. Promise resolve is called when window is closed, after all animation.
   */
  close(): Promise<void>;
  /**
   * Method for open window. Promise resolve is called when window is closed, after all animation.
   */
  show(): Promise<void>;

  /**
   * Start auto update interval when Interval > 0
   */
  startAutoUpdate(): void;

  /**
   * Stop auto update interval
   */
  stopAutoUpdate(): void;
}

/**
 * Interface for dock component
 */
export interface DockListener extends ControlListener {
  /**
   * Dock realizer to dock component.Promise resolve is called when VR is docked and all animation ended.
   */
  dock(state: UpdateDockControl): Promise<void>;
  /**
   * UnDock realizer from dock component.Promise resolve is called when VR is docked and all animation ended.
   */
  undock(): Promise<void>;
}

export interface DataGridListener extends ControlListener {
  updatePosition(oldRow: number, newRow: number, oldCol: number, newCol: number): void;
}

export interface TreeViewListener extends ControlListener {
  updatePosition(oldRow: string, newRow: string, oldCol: number, newCol: number): void;
}

/**
 * interface se základními vlastnostmi které mohou číst i ostatní komponenty a ne jen ty které ncl vlastní.
 */
export interface ControlStateBase {
  readonly Visible: boolean;
  readonly Enabled: boolean;
  readonly TabStop: boolean;
}

export interface InputSelectionText {
  start: number;
  end: number;
}
/**
 * Control implements this interface can be add client action to context menu.
 */
interface ClientContextMenuEmitor {
  appendActionTo(menu: CSNclMenuMetadata, dockRealizerUID: string): void;
}

let lastFceKey: { key: string; timestamp: number };
const limitedFceList = [cJSonFunctionExecute]; //list of functions for which multi-run is blocked

abstract class NclControl<T extends CSNclControlMetadata, S extends UpdateControl> implements NclControlBase {
  protected vr: ViewRealizer;
  protected vrAttachDetachFce: ViewRealizerAttachDetachControlFce = null;
  private listener: ControlListener;
  protected state: S;
  protected ncl: T;
  private request: ControlDataRequest;
  private isPreparedDataRequest = false;
  private nestedControls: NclControlBase[];
  protected parent: NclControlBase;
  private isFocused: boolean;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    this.vr = vr;
    this.vrAttachDetachFce = vrAttachDetachFce;
    this.ncl = Helper.clone(ncl);
    if (!this.ncl) {
      throw new Error("Ncl isn't defined.");
    }
    this.parent = parent;
    if (this.vrAttachDetachFce != null) this.vrAttachDetachFce.call(this.vr, this, true);
    this.state = this.createDefaultState();
    this.initDataRequest();
    this.isFocused = false;
  }

  get Size(): number {
    return 1;
  }

  get VCX(): VisualContext {
    return this.internalGetVCX();
  }

  get MetaData(): CSNclControlMetadata {
    return this.ncl;
  }

  get Ncl(): T {
    return this.ncl;
  }

  get State(): S {
    return this.state;
  }

  get Listener(): any {
    return this.listener;
  }

  get InEditMode(): boolean {
    return this.internalInEditMode();
  }

  get MarginXFactor(): number {
    return 1;
  }

  get MarginYFactor(): number {
    return 1;
  }

  get ComputedMinHeight(): number {
    return this.computeMinHeight(false);
  }

  get ComputedMinHeightWithMargin(): number {
    return this.computeMinHeight(true);
  }

  get Parent(): NclControlBase {
    return this.parent;
  }

  get IsPreparedDataRequest(): boolean {
    return this.isPreparedDataRequest;
  }

  get IsFocused(): boolean {
    return this.isFocused;
  }

  public setTabStop(value: boolean) {
    if (this.state.Visible === true) {
      this.internalSetTabStop(value);
    }
  }

  public isInPreview(): boolean {
    let ctrl: NclControl<any, any> = this;
    while (ctrl) {
      if (ctrl instanceof NclPreviewPanel) return true;
      ctrl = ctrl.Parent as NclControl<any, any>;
    }

    return false;
  }

  public addNestedControl(control: NclControlBase) {
    if (!this.nestedControls) this.nestedControls = [];

    this.nestedControls.push(control);
  }

  public removeNestedControl(control: NclControlBase) {
    if (this.nestedControls) {
      const i = this.nestedControls.indexOf(control);
      if (i >= 0) {
        this.nestedControls.splice(i, 1);
      }
    }
  }

  public afterUpdateState() {
    this.vr.removeFromChangeList(this.MetaData.ControlUID);
    this.initDataRequest();
  }

  protected internalSetTabStop(value: boolean) {}

  protected internalInEditMode(): boolean {
    return this.vr.InEditMode;
  }

  protected forEachNestedControls(fce: ForEachNestedControlFce) {
    if (this.nestedControls) this.nestedControls.map(fce);
  }

  protected internalGetVCX(): VisualContext {
    if (this.parent) {
      return this.parent.VCX;
    }
    if (this.vr) {
      return this.vr.VCX;
    }

    return VisualContext.Default;
  }

  protected computeMinHeight(withMargin: boolean): number {
    return this.internalComputeMinHeight();
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.MinRowHeight;
  }

  protected abstract createDefaultState(): S;

  public appendFunction(fce: Function, callServer = false) {
    if (isDesktopComponent(this.getRealizerUID())) {
      (window as any).k2handler.CallAppendFunction(fce.Name, ...fce.Args);

      return;
    }

    if (callServer && limitedFceList.indexOf(fce.Name) >= 0) {
      const key = `${this.MetaData.ControlUID}_${fce.Name}`;
      if (lastFceKey && key === lastFceKey.key && new Date().getTime() - lastFceKey.timestamp <= 300) {
        return;
      }
      lastFceKey = { key: key, timestamp: new Date().getTime() };
    }
    if (this.request.Functions == null) this.request.Functions = new Array<Function>();

    if (
      this.request.Functions.findIndex((value: Function, index: number, obj: Function[]) => {
        if (value.Name === fce.Name) return true;
      }) === -1
    ) {
      this.request.Functions.push(fce);
    }
    this.isPreparedDataRequest = true;
    if (callServer) {
      this.vr.sendRequest(RealizerOperations.None, this);
    }
  }

  public callCommand(cmd: string, args?: any) {
    if (this.request.Commands == null) this.request.Commands = new Array<Function>();

    if (
      this.request.Commands.findIndex((value: Function, index: number, obj: Function[]) => {
        if (value.Name === cmd) return true;
      }) === -1
    ) {
      this.request.Commands.push({ Name: cmd, Args: args });
    }
    this.isPreparedDataRequest = true;
    this.vr.sendRequest(RealizerOperations.None, this);
  }

  public resetToState() {
    if (this.listener) {
      const st = this.state.toJS() as CSUpdateControl;
      this.state = this.createDefaultState();
      this.updateState(st);
    }
  }

  public updateState<U extends CSUpdateControl>(data: Partial<U>) {
    const newState: S = this.state.with(fromJS(data) as Partial<CSUpdateControl>) as S;
    let canUpdate = true;
    if (
      Object.keys(data).length === 2 &&
      data.Visible === false &&
      this.parent &&
      this.parent.State.Visible === data.Visible &&
      (!(this.parent instanceof NclPage) || !(this.parent.parent instanceof NclVRTabControl))
    ) {
      canUpdate = false; //Can't call update when parent container is invisible.
    }
    this.setState(newState, canUpdate);
  }

  protected setState(newState: S, canUpdate: boolean) {
    if (newState !== this.state) {
      if (canUpdate) canUpdate = this.internalCanUpdate(newState);
      const oldState = this.state;
      this.state = newState;
      this.afterSetState(canUpdate, oldState);
      this.vr.removeFromChangeList(this.ncl.ControlUID);
    }
  }

  protected afterSetState(canUpdate: boolean, oldState: S) {
    if (canUpdate) {
      if (this.listener != null) {
        if (this.state.Visible != oldState.Visible && this.parent instanceof NclContainerBase) {
          this.parent.changeVisibleAnyPartsOfContainer();
        }
        this.listener.updateState(this.state);
      } else {
        if (this.state.Visible === oldState.Visible) {
          Log.warn("Listener not found."); //pouze zmena visible muze narazit na nenastavenneho listenera, v jinem pripade se jedna o zbytecny update a mel by byt zrusen
        }
      }
    }
  }

  public updateFocus(isFocused: boolean) {
    this.isFocused = isFocused;
    this.listener?.updateFocus?.(isFocused);
  }

  public collectData(collector: Array<ControlDataRequest>) {
    this.internalCollectData(collector);
  }

  public setAsActiveControl() {
    this.vr.ActiveControlUID = this.ncl.ControlUID;
  }

  public setActiveControlRequested() {
    this.vr.RequestActiveControlUID = this.ncl.ControlUID;
  }

  public willUnMount(onlyListener: boolean) {
    if (onlyListener) {
      this.listener = null;
    } else {
      this.vrAttachDetachFce.call(this.vr, this);
      this.vrAttachDetachFce = null;
      this.listener = null;
      this.parent = null;
      this.vr = null;
    }

    this.initDataRequest();
    this.forEachNestedControls((ctrl) => {
      ctrl.willUnMount(onlyListener);
    });
  }

  protected internalCanUpdate(newState: S): boolean {
    return true;
  }

  public getRealizerUID(): string {
    if (this.vr != null) {
      return this.vr.getRealizerUID();
    }

    return "";
  }

  protected internalCollectData(collector: Array<ControlDataRequest>) {
    if (this.isPreparedDataRequest) {
      if (Context.DeviceInfo.IsTestMode === true) {
        if (this instanceof NclViewBase) {
          this.request.CtrlName = this.getName();
        } else {
          this.request.CtrlName = this.Ncl.Name;
        }
        if (this instanceof NclBaseTabControl) {
          if ((this.request as TabControlDataRequest).CurrentPage) {
            const ctrl = this.vr.getControlByUID((this.request as TabControlDataRequest).CurrentPage);
            if (ctrl instanceof UFNclControl) (this.request as TabControlDataRequest).CurrentPageName = ctrl.Ncl.Name;
          }
        }
      }
      collector.push(this.request);
      this.initDataRequest();
    }

    this.forEachNestedControls((value) => {
      value.collectData(collector);
    });
  }

  public init(listener: ControlListener): UpdateControl {
    if (this.listener == null) this.listener = listener;
    return this.state;
  }

  public addListenerProp(prop: Partial<ControlListener>) {
    this.listener = { ...this.listener, getOverRect: prop.getOverRect, setAsActive: prop.setAsActive, getScreenshot: prop.getScreenshot };
  }

  protected internalSetVCX(vcx: VisualContext, forceRefresh: boolean) {
    if (this.listener != null && forceRefresh && this.state.Visible) {
      this.listener.updateVCX(this.VCX.getVersion());
    }

    this.forEachNestedControls((value) => {
      (value as NclControl<any, any>).internalSetVCX(vcx, forceRefresh);
    });
  }

  protected internalSetData<U extends ControlDataRequest>(
    data: Partial<U>,
    callServer: boolean,
    changeDataValue = false,
    rootOperation: RealizerOperations = RealizerOperations.None
  ) {
    if (data != null) {
      Object.assign(this.request, data);
      this.isPreparedDataRequest = true;
      if (changeDataValue) {
        this.vr.addToChangeList(this.MetaData.ControlUID);
      }
    }

    if (callServer) {
      this.vr.sendRequest(rootOperation, this);
    }
  }

  protected initDataRequest() {
    this.request = { ControlUID: this.ncl.ControlUID };
    this.isPreparedDataRequest = false;
  }

  public async copy(text: string) {
    copyToClipboard(text);
  }
}

abstract class UFNclControl<T extends CSUFNclControlMetadata, S extends UFUpdateControl> extends NclControl<T, S> implements UFNclControlBase {
  private vcx: VisualContext;

  protected internalGetVCX(): VisualContext {
    if (this.vcx) {
      return this.vcx;
    }

    return super.internalGetVCX();
  }

  get MetaData(): T {
    return this.ncl;
  }

  get Size(): number {
    const size = this.Ncl.FrgtData.Size;
    if (size && !Number.isNaN(size)) {
      return Math.max(size, 1);
    }

    return 1;
  }

  protected internalComputeMinHeight(): number {
    return super.internalComputeMinHeight() * this.Size;
  }

  public willUnMount(onlyListener: boolean) {
    super.willUnMount(onlyListener);
    if (!onlyListener) this.vcx = null;
  }

  public setVCX(vcx: VisualContext, forceRefresh: boolean) {
    this.internalSetVCX(vcx, forceRefresh);
  }

  protected internalSetVCX(vcx: VisualContext, forceRefresh: boolean) {
    if (vcx.ZoomFactor != this.MetaData.FrgtData.ZoomFactor) {
      this.vcx = vcx.createVCXForZoomFactor(this.MetaData.FrgtData.ZoomFactor);
    } else {
      this.vcx = null;
    }

    super.internalSetVCX(vcx, forceRefresh);
  }

  private tabStopChanged: boolean;

  public isActive(): boolean {
    return this.vr.getActualActiveControlUID() === this.MetaData.ControlUID;
  }

  protected internalSetTabStop(value: boolean) {
    if (this.state.TabStop === true || this.tabStopChanged === true) {
      this.tabStopChanged = this.state.TabStop === true ? true : false;
      this.updateState({ TabStop: value });
      this.forEachNestedControls((item) => {
        item.setTabStop(value);
      });
    }
  }

  protected forceCalcFullHeight() {
    const prevValue = this.vr.calcFullHeight;

    if (!this.vr.calcFullHeight) {
      this.vr.calcFullHeight = true;
    }

    const setOriginalValue = () => {
      this.vr.calcFullHeight = prevValue;
    };

    return setOriginalValue;
  }
}

export interface NclBaseContainer extends UFNclControlBase {
  Children: List<UFNclControlBase>;
  Parts: Array<Array<UFNclControlBase>>;
  VerticalLines: FrgtGridLinesStyle;
  HorizontalLines: FrgtGridLinesStyle;
  Parent: NclControlBase;
}

export enum ScrollOption {
  None,
  Enabled,
  Disabled,
}

export abstract class NclContainerBase<T extends CSNclContainerMetadata, S extends UFUpdateControl> extends UFNclControl<T, S> implements NclBaseContainer {
  private _Children: List<UFNclControlBase>;
  private _Parts: Array<Array<UFNclControlBase>>;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.initChildren(ncl);
  }

  public get VerticalLines(): FrgtGridLinesStyle {
    return this.InternalGetVerticalLines();
  }

  public get HorizontalLines(): FrgtGridLinesStyle {
    return this.InternalGetHorizontalLines();
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public willUnMount(onlyListener: boolean) {
    super.willUnMount(onlyListener);
    this.Children.forEach((child) => {
      child.willUnMount(onlyListener);
    });
  }

  protected internalSetVCX(vcx: VisualContext, forceRefresh: boolean) {
    super.internalSetVCX(vcx, forceRefresh);
    if (this.Children) {
      if (this.Children) {
        this.Children.forEach((value: UFNclControlBase, key: number, iter: List<UFNclControlBase>) => {
          value.setVCX(this.VCX, forceRefresh);
        });
      }
    }
  }

  protected internalSetTabStop(value: boolean) {
    super.internalSetTabStop(value);
    if (this.Children) {
      this.Children.forEach((item) => {
        item.setTabStop(value);
      });
    }
  }

  public changeVisibleAnyPartsOfContainer() {
    if (this.Listener?.updateState) {
      const newState = this.state.with({ RenderVersion: Date.now() });
      this.Listener.updateState(newState);
    }
  }

  protected InternalGetVerticalLines(): FrgtGridLinesStyle {
    return FrgtGridLinesStyle.glsNone;
  }

  protected InternalGetHorizontalLines(): FrgtGridLinesStyle {
    return FrgtGridLinesStyle.glsNone;
  }

  private initChildren(ncl: CSNclContainerMetadata) {
    if (ncl.Controls) {
      const children = new Array<UFNclControlBase>();
      for (let i = 0; i < ncl.Controls.length; i++) {
        children.push(NclContainerBase.createControl(ncl.Controls[i], this, this.vr, this.vrAttachDetachFce));
      }

      this._Children = List<UFNclControlBase>(children);
      this._Parts = NclContainerBase.splitToParts(this._Children);
    } else {
      this._Children = List<UFNclControlBase>();
      this._Parts = [];
    }
  }

  get Children(): List<UFNclControlBase> {
    return this.getChildren();
  }

  protected getChildren(): List<UFNclControlBase> {
    return this._Children;
  }

  get Parts(): Array<Array<UFNclControlBase>> {
    return this.getParts();
  }

  protected getParts(): Array<Array<UFNclControlBase>> {
    return this._Parts;
  }

  public static createControl(
    ncl: CSUFNclControlMetadata,
    parent: NclControlBase,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ): UFNclControlBase {
    switch (ncl.__type) {
      case ControlType.Input:
        return new NclInput(ncl as CSNclInputMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.KeyboardInput:
        return new NclKeyboardInput(ncl as CSNclKeyboardInputMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.SimpleDataGrid:
        return new NclSimpleDataGrid(ncl as CSNclDataGridMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.DataGrid:
        return new NclDataGrid(ncl as CSNclDataGridMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.RibbonAction:
      case ControlType.CommandAction:
      case ControlType.ActionTT:
      case ControlType.Action:
        return new NclCommandItem(ncl as CSNclCommandItemMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MenuButton:
        return new NclCommandItem(ncl as CSNclCommandItemMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.ButtonFaka:
      case ControlType.Button:
        return new NclButton(ncl as CSNclButtonMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.DetailTab:
      case ControlType.Tab:
        return new NclTabControl(ncl as CSNclTabControlMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MultiContent:
        return new NclMultiContent(ncl as CSNclTabControlMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.SplitPanel:
        return new NclSplitterPanel(ncl as CSNclSplitterPanelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.DataLabel:
        return new NclDataLabel(ncl as CSNclDataLabelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.PreviewPanel:
        return new NclPreviewPanel(ncl as CSNclPreviewPanelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.CheckBox:
        return new NclCheckBox(ncl as CSNclCheckBoxMetaData, parent, vr, vrAttachDetachFce);
      case ControlType.GroupBox:
        return new NclGroupBox(ncl as CSNclGroupBoxMetaData, parent, vr, vrAttachDetachFce);
      case ControlType.Expander:
        return new NclExpander(ncl as CSNclExpanderMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.DynamicContent:
        return new NclDynamicContent(ncl as CSNclDynamicContentMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Panel:
      case ControlType.Container:
        return new NclPanel<CSNclPanelMetadata, UFUpdateControl>(ncl as CSNclPanelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.ListView:
        return new NclListView(ncl as CSNclListViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Calendar:
        return new NclCalendar(ncl as CSNclCalendarMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Gantt:
        return new NclGantt(ncl as CSNclGanttMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.LibraryReference:
        return new NclLibraryReference(ncl as CSNclContainerMetadata, parent, vr, vrAttachDetachFce);
      //case 'TNclControl': return new K2Control(ncl as INclControl, vr);
      case ControlType.VRTab:
        return new NclVRTabControl(ncl as CSNclVRTabControlMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Image:
        return new NclImage(ncl as CSNclImageMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.FilePreview:
        return new NclFilePreview(ncl as CSNclFilePreview, parent, vr, vrAttachDetachFce);
      case ControlType.Space:
        return new NclSpace(ncl, parent, vr, vrAttachDetachFce);
      case ControlType.FloaterView:
        return new NclFloaterView(ncl as CSNclFloaterViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MenuView:
        return new NclMenuView(ncl as CSNclMenuViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.View:
        return new NclView(ncl as CSNclViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.InplaceView:
        return new NclInplaceView(ncl as CSNclViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.ToolBar:
        return new NclTabToolBar(ncl as CSNclTabToolBarMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.ActionSeparator:
        return new NclActionSeparator(ncl, parent, vr, vrAttachDetachFce);
      case ControlType.StackPanel:
        return new NclStackPanel(ncl as CSNclContainerMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Page:
        return new NclPage(ncl as CSNclPageMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.FloaterAccessor:
        return new NclFloaterAccessor(ncl as CSNclFloaterAccessorMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.OpenDialog:
        return new NclOpenDialog(ncl as CSNclOpenDialogMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.SilentOpenDialog:
        return new NclSilentOpenDialog(ncl as CSNclOpenDialogMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.OpenDialogContent:
        return new NclSilentOpenDialogContent(ncl as CSNclPanelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.ColorPicker:
        return new NclColorPicker(ncl as CSNclColorPickerMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.DeviceConnector:
        return new NclDeviceConnectorDialog(ncl as CSNclDeviceConnectorDialogMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.GPSReader:
        return new NclGPSDialog(ncl as CSNclGPSDialogMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.FormattableInput:
        return new NclFormattableInput(ncl as CSNclFormattableInputMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.LocatorPanel:
        return new NclLocatorPanel(ncl as CSNclLocatorPanelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.SignInput:
        return new NclSignInput(ncl as CSNclSignInputMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Ribbon:
        return new NclRibbon(ncl as CSNclRibbonMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Breaker:
        return new NclBreaker(ncl as CSNclBreakerMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.TreeView:
        return new NclTreeView(ncl as CSNCLTreeViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Dashboard:
        return new NclDashboard(ncl as CSNCLDashboardMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MultiFormatText:
        return new NclMultiFormatText(ncl as CSNclMultiFormatTextMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.VirtualKeyboardDialog:
        return new NclVirtualKeyboardDialog(ncl as CSNclVirtualKeyboardDialogMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.CodeReaderDialog:
        return new NclCodeReaderDialog(ncl as CSNclCodeReaderDialogMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.WebView:
        return new NclWebView(ncl as CSNclWebViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.InstantFilterPanel:
        return new NclInstantFilterPanel(ncl as CSNclInstantFilterPanelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.FlowChart:
        return new NclFlowChart(ncl as CSNclFlowChartMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.WebContentEditor:
        return new NclWebContentEditor(ncl, parent, vr, vrAttachDetachFce);
      case ControlType.SimpleChart:
      case ControlType.SimpleChartDynamic:
        return new NclSimpleChart(ncl as CSNclSimpleChartMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Map:
        return new NclMap(ncl as CSNclMapMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.RadioBox:
        return new NclRadioBox(ncl as CSUFNclRadioBoxMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.ClientWidget:
        return new NclClientWidget(ncl as CSUFNclClientWidgetMetadata, parent, vr, vrAttachDetachFce);
      // nasledujici vetve jsou jen pro desktop komponenty
      case ControlType.InnerGantt:
        return new NclInnerGantt(
          ncl as CSNclInnerGanttMetadata,
          parent as any,
          new NclGantt({ FrgtGanttData: {} } as any, parent as any, vr, vrAttachDetachFce),
          vr,
          vrAttachDetachFce
        ) as any;
      case ControlType.Webcam:
        return new NclWebcam(ncl as CSNclWebcamMetadata, parent, vr, vrAttachDetachFce);
      default:
        //Log.warn('Unknown ncl control: ' + ncl.__type);
        return new NclUndefinedControl(ncl, parent, vr, vrAttachDetachFce);
    }
  }

  protected internalCollectData(collector: Array<ControlDataRequest>) {
    super.internalCollectData(collector);
    this.internalCollectChildrenUpdate(collector);
  }

  protected internalCollectChildrenUpdate(collector: Array<ControlDataRequest>) {
    this.Children.map((child) => {
      child.collectData(collector);
    });
  }

  protected static splitToParts(controls: List<UFNclControlBase>): Array<UFNclControlBase[]> {
    const parts = new Array<UFNclControlBase[]>();

    let band: UFNclControlBase[] = null;
    controls.map((child) => {
      if (parts.length <= child.MetaData.Bounds.PartIndex) {
        band = new Array<UFNclControlBase>();
        parts.push(band);
      } else {
        band = parts[child.MetaData.Bounds.PartIndex];
      }

      band.push(child);
    });

    return parts;
  }

  private calcMinHeight(withMargin: boolean): number {
    let size = 0;
    let partSize = 0;
    let h = 0;
    this.Parts.forEach((band) => {
      partSize = 0;
      h = 0;
      band.forEach((value) => {
        h = withMargin ? value.ComputedMinHeightWithMargin : value.ComputedMinHeight;
        if (h > partSize) {
          partSize = h;
        }
      });
      size += partSize;
    });
    if (
      this instanceof NclSplitterPanel &&
      ((this.MetaData.FrgtData as FrgtSplitterPanelData).Orientation === SplitterPanelOrientation.spoLeft ||
        (this.MetaData.FrgtData as FrgtSplitterPanelData).Orientation === SplitterPanelOrientation.spoRight)
    ) {
      const minHeights: number[] = [];
      this.Parts.map((part) => part.map((p) => minHeights.push(withMargin ? p.ComputedMinHeightWithMargin : p.ComputedMinHeight)));
      size = Math.max(...minHeights);
    }

    return size;
  }

  protected computeMinHeight(withMargin: boolean): number {
    return Math.max(this.calcMinHeight(withMargin), this.VCX.MinRowHeight * this.Size);
  }
}

export class NclPanel<T extends CSNclPanelMetadata, S extends UFUpdateControl> extends NclContainerBase<T, S> {
  protected createDefaultState(): S {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() }) as S;
  }

  protected internalGetVerticalLines(): FrgtGridLinesStyle {
    return this.Ncl.FrgtData.VerticalLines;
  }

  public internalGetHorizontalLines(): FrgtGridLinesStyle {
    return this.Ncl.FrgtData.HorizontalLines;
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public static getScrollOption(frgt: FrgtPanelBaseData): ScrollOption {
    if (frgt.Scroll) {
      return ScrollOption.Enabled;
    }
    return ScrollOption.Disabled;
  }

  protected internalComputeMinHeight(): number {
    let result = 0;

    result += Math.max(this.VCX.MinRowHeight * this.Size, super.internalComputeMinHeight());

    return result;
  }

  protected computeMinHeight(withMargin: boolean): number {
    let onCalcFinish = null;

    if (!([Align.Client, Align.Left, Align.Right].includes(this.Ncl.Bounds.Align) && this.MetaData.FrgtData.Scroll)) {
      onCalcFinish = this.forceCalcFullHeight();
    }

    let height = super.computeMinHeight(withMargin);

    if (withMargin) {
      height += (this.Parts.length - 1) * this.VCX.Data.MarginY * 2;
    }

    if (
      !this.vr.calcFullHeight &&
      (this.MetaData.FrgtData as FrgtPanelBaseData).Scroll &&
      [Align.Client, Align.Left, Align.Right].includes(this.Ncl.Bounds.Align)
    ) {
      height = this.VCX.LabelControl.getHeight(1) * this.Size;
    }

    onCalcFinish?.();

    return height;
  }
}

export class NclCalendar extends UFNclControl<CSNclCalendarMetadata, UpdateCalendar> {
  constructor(ncl: CSNclCalendarMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    //this.maxRowCount = this.Ncl.FrgtData.Size;
  }
  protected createDefaultState(): UpdateCalendar {
    return new UpdateCalendar({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public ChangeActiveDateRange(StartDate: Date, EndDate: Date) {
    let Reload = false;
    if (!this.state.ActiveStartDate) {
      Reload = true;
    }
    if (!this.state.ActiveEndDate) {
      Reload = true;
    }

    if (StartDate < new Date(this.state.ActiveStartDate)) {
      Reload = true;
    }

    if (EndDate > new Date(this.state.ActiveEndDate)) {
      Reload = true;
    }

    if (Reload) {
      //console.log("Načítám pro " + StartDate.toLocaleDateString() + " end:" + EndDate.toLocaleDateString());
      this.appendFunction(
        { Name: cJSonChangeActiveDateRange, Args: [this.ConvertToIndependentFormat(StartDate), this.ConvertToIndependentFormat(EndDate)] },
        true
      );
      //this.resetToState;
    }
  }

  public executeShowSetting(args?: Array<any>) {
    this.appendFunction({ Name: cJSonFunctionShowSetting, Args: args }, true);
  }

  public executeDeleteAppointment(SerieUI: string, K2Pk: string) {
    this.appendFunction({ Name: cJSonFunctionDeleteAppointment, Args: [SerieUI, K2Pk] }, true);
  }

  public executeDeleteOnlineAppointment(ProviderUI: string, AppointmentId: string, AppType: number) {
    this.appendFunction({ Name: cJSonFunctionDeleteOnlineAppointment, Args: [ProviderUI, AppointmentId, AppType] }, true);
  }

  public executeShowDocumentByK2PK(SerieUI: string, K2Pk: string) {
    this.appendFunction({ Name: cJSonFunctionShowDocumentByK2PK, Args: [SerieUI, K2Pk] }, true);
  }

  public executeEditOnlineAppointment(ProviderUI: string, AppointmentId: string, AppType: number) {
    this.appendFunction({ Name: cJSonFunctionEditOnlineAppointment, Args: [ProviderUI, AppointmentId, AppType] }, true);
  }

  public ConvertToIndependentFormat(aDate: Date): string {
    if (aDate == null) {
      return " 0000-00-00 00:00:00:000";
    } else {
      let lDateAsStr, lMonth, lDate: string;
      let lMonthAsN: number;
      lMonthAsN = aDate.getMonth() + 1;
      lMonth = ("00" + lMonthAsN.toString()).slice(-2);
      lDate = ("00" + aDate.getDate().toString()).slice(-2);
      lDateAsStr = aDate.getFullYear() + "-" + lMonth + "-" + lDate;

      let lTimeAsStr, lHour, lMin, lSec, lMili: string;
      lHour = ("00" + aDate.getHours().toString()).slice(-2);
      lMin = ("00" + aDate.getMinutes().toString()).slice(-2);
      lSec = ("00" + aDate.getSeconds().toString()).slice(-2);
      lMili = ("000" + aDate.getMilliseconds().toString()).slice(-3);
      lTimeAsStr = lHour + ":" + lMin + ":" + lSec + ":" + lMili;

      return lDateAsStr + " " + lTimeAsStr;
    }
  }

  public DragAnDropAppointment(ProviderUI: string, K2Pk: string, oldstart: Date, start: Date, end: Date, AppointmentId: string, AppType: number) {
    //this.vr.addToChangeList(this.MetaData.ControlUID);
    //		this.resetToState;
    this.appendFunction(
      {
        Name: cJSonFunctionDragAndDropAppointment,
        Args: [
          ProviderUI,
          K2Pk,
          0,
          this.ConvertToIndependentFormat(oldstart),
          this.ConvertToIndependentFormat(start),
          this.ConvertToIndependentFormat(end),
          AppointmentId,
          AppType,
        ],
      },
      true
    );
  }

  public contextMenu(actualDate: Date) {
    this.appendFunction({ Name: cJSonFunctionContextMenu, Args: [this.ConvertToIndependentFormat(actualDate)] }, true);
  }
}

export class NclContainer extends NclContainerBase<CSNclContainerMetadata, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclLibraryReference extends NclContainer {
  protected computeMinHeight(withMargin: boolean): number {
    let height = 0;

    const onCalcFinish = this.forceCalcFullHeight();

    this.Parts.map((part) =>
      part.map((p) => {
        if (withMargin) {
          height = p.ComputedMinHeightWithMargin;
        } else {
          height = p.ComputedMinHeight;
        }
      })
    );

    onCalcFinish();

    return height;
  }
}

export class NclStackPanel extends NclContainer {}

export class NclPage extends UFNclControl<CSNclPageMetadata, UpdatePageControl> {
  private content: UFNclControlBase;
  public key = 0;

  constructor(ncl: CSNclPageMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Content) {
      this.content = NclContainer.createControl(ncl.Content, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.content);
    }
  }

  protected createDefaultState(): UpdatePageControl {
    return new UpdatePageControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalSetTabStop(value: boolean): void {
    super.internalSetTabStop(value);
    if (this.content) {
      this.content.setTabStop(value);
    }
  }

  public get InEditMode(): boolean {
    return this.internalInEditMode();
  }

  static create(
    controlUID: string,
    pageUID: string,
    parent: UFNclControlBase,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ): NclPage {
    const page = new NclPage(
      {
        ControlUID: controlUID,
        PageUID: pageUID,
        IsDynamic: true,
        Bounds: { Align: Align.Client, BandsCount: 0, FromBandIndex: 0, InteriorBandsCount: 0, PartIndex: 0 },
        FrgtData: { Size: 1, HorizontalAlignment: HorizontalAlignment.fhaCenter, VerticalAlignment: VerticalAlignment.fvaCenter },
      } as CSNclPageMetadata,
      parent,
      vr,
      vrAttachDetachFce
    );
    const dockVr: ViewRealizer = ViewRealizerManager.getViewRealizerByDock(pageUID);
    page.content = new NclDockControl(pageUID, page, vr, vrAttachDetachFce, dockVr ? dockVr.getRealizerUID() : "");
    return page;
  }

  public willUnMount(onlyListener: boolean) {
    super.willUnMount(onlyListener);
    if (this.content instanceof NclDockControl) this.content.willUnMount(onlyListener);
  }

  get Content(): UFNclControlBase {
    return this.content;
  }

  get Depth(): number {
    return this.vr.getDepth();
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public getTabParent(): NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl> {
    if (this.parent instanceof NclBaseTabControl) {
      return this.parent;
    }

    return null;
  }

  public showContextMenu() {
    if (this.parent instanceof NclVRTabControl) {
      this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
    }
  }
}

export class NclDataLabel extends UFNclControl<CSNclDataLabelMetadata, UpdateDataLabel> implements ClientContextMenuEmitor {
  protected createDefaultState(): UpdateDataLabel {
    return new UpdateDataLabel({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    let size: number = this.Size;

    if (this.state.GlyphId) {
      size = Math.max(this.VCX.InputControl.getInputHeight(1, true, false), this.VCX.LabelControl.getHeight(size));
    } else {
      size = this.VCX.LabelControl.getHeight(size);
    }

    if (this.ncl.FrgtData.Orientation === Orientation.foVertical && this.ncl.FrgtData.TitleDisplayMode !== DisplayMode.fpdmNone) {
      size += this.VCX.LabelControl.getHeight(1);
    }

    if (this.Ncl.FrgtData.BottomLine) {
      size += 1;
    }

    return size;
  }

  public contextMenu() {
    this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }

  public actionClick() {
    if (this.ncl.HasAction) {
      this.appendFunction({ Name: cJSonFunctionExecute }, true);
    }
  }

  public click() {
    this.appendFunction({ Name: cJSonFunctionResolveFocus }, true);
  }

  public appendActionTo(menu: CSNclMenuMetadata): void {
    appendSpecialActionsToMenu(this.state.Text, menu);
  }
}

function appendCallToPhone(value: string, menu: CSNclMenuMetadata, id = 0) {
  if (menu && menu.Actions) {
    if (isPhoneNumber(value)) {
      const item = {
        ControlUID: `${menu.ControlUID + id}_call`,
        GlyphId: "wui*call.center.call",
        Caption: value,
        __type: ControlType.ClientMenuItem,
        action: () => {
          call(value);
        },
      } as CSNclClientMenuItemMetadata;
      menu.Actions.push(item);
    }
  }
}

function appendEmailToMenu(email: string, menu: CSNclMenuMetadata, id = 0) {
  if (menu && menu.Actions) {
    const item = {
      ControlUID: `${menu.ControlUID + id}_email`,
      GlyphId: "wui*email",
      Caption: email,
      __type: ControlType.ClientMenuItem,
      action: () => {
        window.location.href = `mailto:${email}`;
      },
    } as CSNclClientMenuItemMetadata;
    menu.Actions.push(item);
  }
}

function appendUrlToMenu(url: string, menu: CSNclMenuMetadata, id = 0) {
  if (menu && menu.Actions) {
    const item = {
      ControlUID: `${menu.ControlUID + id}_url`,
      GlyphId: "wui*globe",
      Caption: url,
      __type: ControlType.ClientMenuItem,
      action: () => {
        if (!url.match(/^[a-zA-Z][a-zA-Z\d+\-.]*:/)) {
          url = "http://" + url;
        }
        window.open(url, "_blank");
      },
    } as CSNclClientMenuItemMetadata;
    menu.Actions.push(item);
  }
}

function appendSpecialActionsToMenu(value: string | Array<MFValue>, menu: CSNclMenuMetadata) {
  let index = 0;
  const addedPhoneNumbers = new Set<string>();
  const addedEmails = new Set<string>();
  const addedUrls = new Set<string>();
  if (typeof value == "string") {
    const numbersInText = findPhoneNumbersInText(value);
    numbersInText.forEach((element) => {
      if (!addedPhoneNumbers.has(element.number.number)) {
        appendCallToPhone(element.number.number, menu, index++);
        addedPhoneNumbers.add(element.number.number);
      }
    });
    const emailsInText = findEmailAddressesInText(value);
    emailsInText.emails.forEach((element) => {
      if (!addedEmails.has(element.email)) {
        appendEmailToMenu(element.email, menu, index++);
        addedEmails.add(element.email);
      }
    });
    const urlsInText = findUrlsInText(emailsInText.cleanedText);
    urlsInText.forEach((element) => {
      if (!addedUrls.has(element.url)) {
        appendUrlToMenu(element.url, menu, index++);
        addedUrls.add(element.url);
      }
    });
  } else {
    (value as Array<MFValue>).forEach((cellMultiText) => {
      const numbersInText = findPhoneNumbersInText(cellMultiText.Text);
      numbersInText.forEach((element) => {
        if (!addedPhoneNumbers.has(element.number.number)) {
          appendCallToPhone(element.number.number, menu, index++);
          addedPhoneNumbers.add(element.number.number);
        }
      });
      const emailsInText = findEmailAddressesInText(cellMultiText.Text);
      emailsInText.emails.forEach((element) => {
        if (!addedEmails.has(element.email)) {
          appendEmailToMenu(element.email, menu, index++);
          addedEmails.add(element.email);
        }
      });
      const urlsInText = findUrlsInText(emailsInText.cleanedText);
      urlsInText.forEach((element) => {
        if (!addedUrls.has(element.url)) {
          appendUrlToMenu(element.url, menu, index++);
          addedUrls.add(element.url);
        }
      });
    });
  }
}

function findEmailAddressesInText(text: string) {
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  const emails = [];
  let cleanedText = text;
  let match;
  while ((match = emailRegex.exec(text)) !== null) {
    emails.push({ email: match[0] });
    cleanedText = cleanedText.replace(match[0], "");
  }
  return { emails, cleanedText };
}

function findUrlsInText(text: string) {
  const urlRegex = /\b((https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\S*)?)\b/g;
  const urls = [];
  let match;
  while ((match = urlRegex.exec(text)) !== null) {
    urls.push({ url: match[0] });
  }
  return urls;
}

class NclUndefinedControl extends UFNclControl<CSUFNclControlMetadata, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclSplitterPanel extends NclContainerBase<CSNclSplitterPanelMetadata, UpdateSplitterPanel> {
  protected createDefaultState(): UpdateSplitterPanel {
    return new UpdateSplitterPanel({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public setRatio(ratio: number) {
    if (this.state.Ratio != ratio) {
      this.internalSetData<SplitterDataRequest>({ Ratio: ratio }, false);
    }
  }

  public toggleCollapsed() {
    this.internalSetData<SplitterDataRequest>({ Collapsed: !this.state.Collapsed }, true);
  }

  public get MarginXFactor(): number {
    return 0;
  }

  public get MarginYFactor(): number {
    return 0;
  }

  public isSplitterVertical() {
    if (this.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoLeft || this.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoRight) return true;
    else return false;
  }

  protected computeMinHeight(withMargin: boolean): number {
    let firstPanelHeight = 0;
    let secondPanelHeight = 0;

    if (this.Children.get(0)) {
      if (!this.vr.calcFullHeight && this.Children.get(0) instanceof NclPanel) {
        firstPanelHeight += this.VCX.LabelControl.getHeight(this.Children.get(0).Size);
      } else {
        firstPanelHeight += this.Children.get(0).ComputedMinHeightWithMargin;
      }
    }

    if (this.Children.get(1)) {
      if (!this.vr.calcFullHeight && this.Children.get(1) instanceof NclPanel) {
        secondPanelHeight += this.VCX.LabelControl.getHeight(this.Children.get(1).Size);
      } else {
        secondPanelHeight += this.Children.get(1).ComputedMinHeightWithMargin;
      }
    }

    if (this.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoTop || this.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoBottom) {
      return firstPanelHeight + secondPanelHeight + this.VCX.sizeMap(7) + this.VCX.Data.MarginY * 2; // vyska splitteru + gap
    } else {
      return Math.max(firstPanelHeight, secondPanelHeight);
    }
  }
}

export abstract class NclBaseTabControl<T extends CSNclTabControlMetadata, S extends UpdateTabControl> extends UFNclControl<T, S> {
  private _Pages: List<NclPage>;
  private btns: Array<NclCommandItem>;
  private isMainTabControl = false;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.initPages(ncl);
    if (ncl.Btns && ncl.Btns.length > 0) {
      this.btns = new Array<NclCommandItem>();
      let btn: NclCommandItem;
      ncl.Btns.map((item) => {
        item.FrgtData.DisplayStyle = TUFActionDisplayStyle.ufadsButtonSmall;
        btn = new NclCommandItem(item, this, vr, vrAttachDetachFce);
        this.addNestedControl(btn);
        this.btns.push(btn);
      });
    }
  }

  protected setState(newState: S, canUpdate: boolean): void {
    if (canUpdate && newState.Visible !== this.state.Visible) {
      if (newState.Visible === true) {
        this.isMainTabControl = Context.getApplication().registerMainTabControl(this);
      } else {
        Context.getApplication().unRegisterMainTabControl(this);
      }
    }
    super.setState(newState, canUpdate);
  }

  willUnMount(onlyListener: boolean) {
    super.willUnMount(onlyListener);
    Context.getApplication().unRegisterMainTabControl(this);
  }

  get CompanyColor(): string {
    const clr = Context.getApplication().getCompanyColor();

    if (this.isMainTabControl && clr > 0) {
      return this.VCX.ColorMap.getColor(Context.getApplication().getCompanyColor());
    }
    return undefined;
  }

  get MarginXFactor(): number {
    return 1;
  }

  get MarginYFactor(): number {
    return 1;
  }

  private initPages(ncl: T) {
    if (ncl.Pages) {
      let page: NclPage;
      const children = new Array<NclPage>();
      for (let i = 0; i < ncl.Pages.length; i++) {
        page = new NclPage(ncl.Pages[i], this, this.vr, this.vrAttachDetachFce);
        children.push(page);
        this.addNestedControl(page);
      }

      this._Pages = List<NclPage>(children);
    } else {
      this._Pages = List<NclPage>();
    }
  }

  protected internalSetTabStop(value: boolean): void {
    super.internalSetTabStop(value);
    if (this.Pages) {
      this.Pages.forEach((page) => {
        if (this.state.CurrentPage === (page.MetaData as CSNclPageMetadata).PageUID) {
          page.setTabStop(value);
          return;
        }
      });
    }
  }

  protected getPages(): List<NclPage> {
    return this._Pages;
  }

  get Pages(): List<NclPage> {
    return this.getPages();
  }

  get Btns(): Array<NclCommandItem> {
    return this.btns;
  }

  public changeCurrentPage(page: string) {
    if (page !== this.state.CurrentPage) {
      this.internalSetData<TabControlDataRequest>({ CurrentPage: page }, true);
    }
  }

  protected internalCollectData(collector: Array<ControlDataRequest>) {
    super.internalCollectData(collector);
    this.internalCollectChildrenUpdate(collector);
  }

  protected internalCollectChildrenUpdate(collector: Array<ControlDataRequest>) {
    if (this.state.CurrentPage) {
      const ctrl = this.vr.getControlByUID(this.state.CurrentPage);
      if (ctrl) ctrl.collectData(collector);
    }
  }

  protected computeMinHeight(withMargin: boolean) {
    let minHeight = 0;

    this.Pages.map((page) => {
      if (page.Content.ComputedMinHeight > minHeight) {
        if (withMargin) {
          minHeight = page.Content.ComputedMinHeightWithMargin;
        } else {
          minHeight = page.Content.ComputedMinHeight;
        }
      }
    });

    minHeight += this.VCX.ExpanderControl.GetHFHeight() + this.VCX.sizeMap(6); // header

    if (withMargin) {
      minHeight += this.VCX.Data.MarginY * 2 * 2;
    }

    minHeight += 1; // border-bottom

    return minHeight;
  }

  public getOrientation() {
    return this.Ncl.ListDetailTabControl ? Orientation.foVertical : Orientation.foHorizontal;
  }

  public visiblePages() {
    return this.Pages.filter((page) => {
      if ((page.State as CSUpdatePageControl).Hide === true) {
        return false;
      }

      return true;
    });
  }
}

export class NclTabControl extends NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl> {
  protected createDefaultState(): UpdateTabControl {
    return new UpdateTabControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclMultiContent extends NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl> {
  protected createDefaultState(): UpdateTabControl {
    return new UpdateTabControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public get MarginXFactor(): number {
    return 0;
  }

  public get MarginYFactor(): number {
    return 0;
  }

  protected computeMinHeight(withMargin: boolean): number {
    let onCalcFinish = null;

    if (![Align.Client, Align.Left, Align.Right].includes(this.Ncl.Bounds.Align)) {
      onCalcFinish = this.forceCalcFullHeight();
    }

    let minHeight = 0;

    this.Pages.map((page) => {
      if (this.state.CurrentPage === (page.MetaData as CSNclPageMetadata).PageUID) {
        minHeight = page.Content.ComputedMinHeightWithMargin;
      }
    });

    onCalcFinish?.();

    minHeight = Math.max(this.VCX.LabelControl.getHeight(this.Size), minHeight);

    return minHeight;
  }
}

export class NclDockControl extends UFNclControl<CSUFNclControlMetadata, UpdateDockControl> {
  private vrADFce: ViewRealizerAttachDetachControlFce;
  private dockRealizerRUID: string;

  constructor(controlUID: string, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce, dockRealizerUID: string) {
    super(
      {
        ControlUID: controlUID,
        Bounds: { Align: Align.Client, BandsCount: 0, FromBandIndex: 0, InteriorBandsCount: 0, PartIndex: 0 },
        FrgtData: { Size: 1, HorizontalAlignment: HorizontalAlignment.fhaCenter, VerticalAlignment: VerticalAlignment.fvaCenter },
      } as CSUFNclControlMetadata,
      parent,
      vr,
      null
    );
    this.dockRealizerRUID = dockRealizerUID;
    this.vrADFce = vrAttachDetachFce;
    this.vrAttachDetachFce = NclDockControl.dockAttachDettach;
    this.vrAttachDetachFce.call(this.vr, this, true);
  }

  private static dockAttachDettach(control: UFNclControlBase, attach: boolean) {
    const dock: NclDockControl = control as NclDockControl;
    if (dock.vrADFce) dock.vrADFce.call(dock.vr, dock, attach);
    const dockVr: ViewRealizer = ViewRealizerManager.getViewRealizer(dock.dockRealizerRUID);
    if (dockVr) {
      if (attach) {
        dockVr.setDockControl(dock);
      } else {
        dockVr.setDockControl(null);
      }
    }
  }

  public isDocked(): boolean {
    return this.state.DockRealizerUID === this.dockRealizerRUID;
  }

  public dockViewRealizer(realizerUID?: string): Promise<void> {
    if (realizerUID) {
      this.dockRealizerRUID = realizerUID;
    }
    this.state = this.state.merge({ DockRealizerUID: this.dockRealizerRUID } as any) as UpdateDockControl;
    if (this.Listener && (this.Listener as DockListener).dock) {
      return (this.Listener as DockListener).dock(this.state);
    } else {
      return Promise.resolve();
    }
  }

  public undockViewRealizer(): Promise<void> {
    if (this.Listener && (this.Listener as DockListener).undock) {
      return (this.Listener as DockListener).undock();
    } else {
      return Promise.resolve();
    }
  }

  protected createDefaultState(): UpdateDockControl {
    return new UpdateDockControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclVRTabControl extends NclBaseTabControl<CSNclVRTabControlMetadata, UpdateVRTabControl> {
  private reloadPages: boolean;
  private pages: List<NclPage>;
  private mainMenuData: MainMenuData;

  protected createDefaultState(): UpdateVRTabControl {
    return new UpdateVRTabControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  constructor(ncl: CSNclVRTabControlMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.reloadPages = true;
  }

  get CompanyColor(): string {
    const clr = Context.getApplication().getCompanyColor();
    if (clr > 0) return this.VCX.ColorMap.getColor(clr);

    return undefined;
  }

  get MainMenuData(): MainMenuData {
    return this.mainMenuData;
  }

  public closePage(page: string) {
    this.Pages.forEach((value) => {
      if (value.Content.MetaData.ControlUID === page && value.Content instanceof NclDockControl) {
        this.appendFunction({ Name: cJSonFunctionCloseVRPage, Args: [value.MetaData.PageUID] }, true);
        return;
      }
    });
  }

  public loadMainMenuData() {
    this.appendFunction({ Name: cJSonFunctionLoadMainMenu }, true);
  }

  protected getPages(): List<NclPage> {
    if (this.reloadPages) {
      if (this.pages == null) {
        this.pages = super.getPages();
      }

      if (this.state.RemovedTabs != null && this.state.RemovedTabs.size > 0) {
        this.state.RemovedTabs.map((ctrlId) => {
          const pageNdx = this.pages.findIndex((value) => {
            return value.Ncl.ControlUID === ctrlId;
          });
          if (pageNdx > -1) {
            const page = this.pages.get(pageNdx);
            if (page) {
              page.willUnMount(false);
              this.removeNestedControl(page);
              this.pages = this.pages.remove(pageNdx);
            }
          }
        });
      }

      if (this.state.AddedTabs != null && this.state.AddedTabs.size > 0) {
        this.state.AddedTabs.map(async (tab: List<string>) => {
          let page: NclPage = null;
          const tabIndex = this.pages.findIndex((value: NclPage, key: number) => {
            return value.Ncl.PageUID === tab.get(1);
          });

          if (tabIndex == -1) {
            page = NclPage.create(tab.get(0), tab.get(1), this, this.vr, this.vrAttachDetachFce);
            page.key = Date.now();
            this.addNestedControl(page);
            this.pages = this.pages.push(page);
          } else {
            throw new Error("Page already exist.");
          }
        });
      }
      this.state = this.state.withMutations((state) => {
        state.set("AddedTabs" as any, null);
        state.set("RemovedTabs" as any, null);
      }) as UpdateVRTabControl;
      this.reloadPages = false;
    }
    return this.pages;
  }

  protected internalCanUpdate(newState: UpdateVRTabControl): boolean {
    const result: boolean = super.internalCanUpdate(newState);

    if (result && ((newState.AddedTabs != null && newState.AddedTabs.size > 0) || (newState.RemovedTabs != null && newState.RemovedTabs.size > 0))) {
      this.reloadPages = true;
    }

    return result;
  }

  public updateState<U extends UpdateVRTabControl>(data: Partial<U>): void {
    if (data.MainMenuData && data.MainMenuData.DataFromDM) {
      this.mainMenuData = {} as MainMenuData;
      this.mainMenuData.DataFromDM = data.MainMenuData.DataFromDM.map<DataFromDM>((item) => ({
        ...item,
        NormalizedName: item.Name.normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .toLowerCase(),
        ChildrenIds: data.MainMenuData.DataFromDM.map((child) => {
          if (child.ParentRID === item.RID) return child.RID;
        }).filter((foo) => foo != null),
      }));
      delete data.MainMenuData;
      data.MainMenuDataVersion = Date.now();
    }

    super.updateState(data);
  }
}

abstract class NclActionBase<T extends CSNclCommandItemMetadata, S extends UpdateCommandItem> extends UFNclControl<T, S> {
  public executeCommand(args?: Array<any>) {
    this.appendFunction({ Name: cJSonFunctionExecute, Args: args }, true);
  }
}

export class NclBreaker extends UFNclControl<CSNclBreakerMetadata, UFUpdateControl> {
  protected createDefaultState(): UpdateCommandItem {
    return new UpdateCommandItem({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID(), Decorate: undefined });
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }
}

export class NclCommandItem extends NclActionBase<CSNclCommandItemMetadata, UpdateCommandItem> implements ClientContextMenuEmitor {
  constructor(ncl: CSNclCommandItemMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    ncl.Bounds.Align = Align.Client; ///
    super(ncl, parent, vr, vrAttachDetachFce);
  }

  protected createDefaultState(): UpdateCommandItem {
    return new UpdateCommandItem({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID(), Decorate: undefined });
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.InputControl.getEditHeight(1);
  }

  public get MarginXFactor(): number {
    return 0;
  }

  public get MarginYFactor(): number {
    return 0;
  }

  appendActionTo(menu: CSNclMenuMetadata, dockRealizerUID: string): void {
    if (menu && menu.Actions) {
      if (this.MetaData.Name.endsWith("C63158663E0A4934B598916517694B63")) {
        const actions: CSNclMenuItemBaseMetadata[] = [];

        //-- Default
        const defaultTheme = {
          ControlUID: `${menu.ControlUID}_style_default`,
          GlyphId: getTheme() === "default" ? `wui*commitchange` : `svgch*0`,
          Caption: `Defaultní`,
          __type: ControlType.ClientMenuItem,
          action: () => {
            themeSwitchTo("default");
            const vr = ViewRealizerManager.getViewRealizer(dockRealizerUID);
            if (vr) vr.closeRequest();
          },
        } as CSNclClientMenuItemMetadata;
        actions.push(defaultTheme);
        //----

        const myObj: { [key: string]: any } = themeList["themes"];

        Object.entries(myObj).forEach(([theme]) => {
          const item = {
            ControlUID: `${menu.ControlUID}_style_${theme}`,
            GlyphId: getTheme() === theme ? `wui*commitchange` : `${myObj[theme]["glyphId"]}`,
            Caption: `${myObj[theme]["name"]}`,
            __type: ControlType.ClientMenuItem,
            action: () => {
              themeSwitchTo(theme);
              const vr = ViewRealizerManager.getViewRealizer(dockRealizerUID);
              if (vr) vr.closeRequest();
            },
          } as CSNclClientMenuItemMetadata;
          actions.push(item);
        });

        const group: CSNclClientMenuGroupMetadata = {
          ControlUID: `${menu.ControlUID}_sep`,
          Name: "Styly",
          Caption: "Styly",
          GlyphId: "wui*paint.palette",
          __type: ControlType.ClientMenuGroup,
          Actions: actions,
        } as CSNclClientMenuGroupMetadata;

        menu.Actions.push(group);
      }
    }
  }

  protected computeMinHeight(withMargin: boolean): number {
    if (this.Parent instanceof NclInnerSection) {
      return this.VCX.sizeMap(23);
    }

    return super.computeMinHeight(withMargin);
  }
}

export class NclButton extends NclActionBase<CSNclButtonMetadata, UpdateCommandItem> {
  protected createDefaultState(): UpdateCommandItem {
    return new UpdateCommandItem({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID(), Decorate: undefined });
  }

  protected internalComputeMinHeight(): number {
    let result = 0;

    if (this.Ncl.Bounds.Align === Align.Top || this.Ncl.Bounds.Align === Align.Bottom) {
      result = this.VCX.InputControl.getInputHeight(this.Size, true, false);
    } else {
      result = this.VCX.LabelControl.getHeight(this.Size);
    }

    if (this.ncl.FrgtData.ShowCaption && (this.ncl.FrgtData.IconPosition === IconPosition.ipBottom || this.ncl.FrgtData.IconPosition === IconPosition.ipTop)) {
      result += this.VCX.LabelControl.getHeight(1);
    }

    return result;
  }
}

export class NclCheckBox extends UFNclControl<CSNclCheckBoxMetaData, UpdateCheckBox> {
  public resolveFocus(next: boolean) {
    this.appendFunction({ Name: cJSonFunctionResolveFocus, Args: [next] }, true);
  }

  public check() {
    this.internalSetData<CheckBoxDataRequest>({ Checked: !this.state.Checked }, true, true, RealizerOperations.Accept);
  }

  public contextMenu() {
    this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }

  public createDefaultState(): UpdateCheckBox {
    return new UpdateCheckBox({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalInEditMode(): boolean {
    return !this.state.ReadOnly;
  }

  protected computeMinHeight(withMargin: boolean): number {
    const outline = this.VCX.sizeMap(1) * 2; // hover ramecek
    return this.VCX.LabelControl.getHeight(this.Size) + this.VCX.InputControl.getInputFrameWidth() * 2 + outline;
  }
}

export class NclHeader extends NclControl<CSNclHeaderMetaData, UpdateControl> {
  private toolbar: NclToolBar;
  private separator: NclActionSeparator;
  private rQuickButtons: Array<NclCommandItem>;
  private locatorPanel: NclLocatorPanel;
  private lQuickButton: NclCommandItem;
  private lQuickButton1: NclCommandItem;

  constructor(ncl: CSNclHeaderMetaData, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (!(parent instanceof NclHeaderedControl)) {
      throw Error("Bad parent");
    }
    if (ncl.ToolBar) {
      this.toolbar = new NclToolBar(ncl.ToolBar, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.toolbar);
    }

    if (ncl.Separator) {
      this.separator = new NclActionSeparator(ncl.Separator, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.separator);
    }

    this.rQuickButtons = new Array<NclCommandItem>();
    if (ncl.RQuickButtons && ncl.RQuickButtons.length > 0) {
      let action: NclCommandItem;
      ncl.RQuickButtons.map((value) => {
        action = new NclCommandItem(value, this, vr, vrAttachDetachFce);
        this.rQuickButtons.push(action);
        this.addNestedControl(action);
      });
    }

    if (ncl.LQuickButton) {
      this.lQuickButton = new NclCommandItem(ncl.LQuickButton, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.lQuickButton);
    }

    if (ncl.LQuickButton1) {
      this.lQuickButton1 = new NclCommandItem(ncl.LQuickButton1, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.lQuickButton1);
    }

    if (ncl.LocatorPanel) {
      this.locatorPanel = new NclLocatorPanel(ncl.LocatorPanel, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.locatorPanel);
    }
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public get Headered(): NclHeaderedControl<CSNclHeaderedMetadata, UpdateHeadered> {
    return this.parent as NclHeaderedControl<CSNclHeaderedMetadata, UpdateHeadered>;
  }

  public get ToolBar(): NclToolBar {
    return this.toolbar;
  }

  public get Separator(): NclActionSeparator {
    return this.separator;
  }

  public get RQuickButtons(): Array<NclCommandItem> {
    return this.rQuickButtons;
  }

  public set RQuickButtons(items: NclCommandItem[]) {
    this.rQuickButtons = items;
  }

  public get LocatorPanel(): NclLocatorPanel {
    if (this.Headered.isLite()) return null;
    return this.locatorPanel;
  }

  public get LQuickButton(): NclCommandItem {
    return this.lQuickButton;
  }

  public get LQuickButton1(): NclCommandItem {
    return this.lQuickButton1;
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.ExpanderControl.GetHFHeight();
  }

  public click() {
    if (this.parent instanceof NclExpander) {
      this.appendFunction({ Name: cJSonFunctionResolveFocus }, true);
    } else if (this.parent instanceof NclControl) {
      this.parent.setAsActiveControl();
    }
  }
}

export abstract class NclHeaderedControl<T extends CSNclHeaderedMetadata, S extends UpdateHeadered> extends UFNclControl<T, S> {
  private header: NclHeader;
  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Header) {
      this.header = new NclHeader(ncl.Header, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.header);
    }
  }

  protected createDefaultState(): S {
    return new UpdateHeadered({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() }) as S;
  }

  public abstract isLite(): boolean;

  public isShowHeader(): boolean {
    return this.Header != undefined;
  }

  public get Header(): NclHeader {
    return this.header;
  }

  protected internalComputeMinHeight(): number {
    let result = 0;

    if (this.isShowHeader() && this.Header) {
      result = this.Header.ComputedMinHeight;
    }

    return result;
  }
}

export class NclTreeView extends NclHeaderedControl<CSNCLTreeViewMetadata, UpdateHeadered> {
  private content: NclInnerTreeView;

  constructor(ncl: CSNCLTreeViewMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Content) {
      this.content = new NclInnerTreeView(ncl.Content, this, this.vr, this.vrAttachDetachFce);
      this.addNestedControl(this.content);
    }
  }

  protected createDefaultState(): UpdateHeadered {
    return new UpdateHeadered({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get Content(): NclInnerTreeView {
    return this.content;
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public isLite(): boolean {
    return false;
  }

  public doubleClick() {
    this.appendFunction({ Name: cJSonTreeViewDblClickExecute }, true);
  }

  protected computeMinHeight(withMargin: boolean): number {
    let height = 0;

    if (this.isShowHeader() && this.Header) {
      height += this.Header.ComputedMinHeightWithMargin;
    }

    height += this.VCX.sizeMap(21) * this.Size;

    return height;
  }

  public isShowHeader(): boolean {
    return this.Header?.State.Visible;
  }
}

export class NclListView extends NclHeaderedControl<CSNclListViewMetadata, UpdateListView> {
  public maxRowCount: number;
  public iconPerRow: number;

  constructor(ncl: CSNclListViewMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.maxRowCount = this.Ncl.FrgtData.Size;
  }
  protected createDefaultState(): UpdateListView {
    return new UpdateListView({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public isLite(): boolean {
    return this.ncl.FrgtData.LiteHeader ? true : false;
  }

  public executeSetPosition(position: number, callServer = true) {
    if (this.state.Position != position) {
      this.internalSetData<ListViewDataRequest>({ Position: position }, callServer);
    }
  }

  public executeShortcut(args?: Array<any>) {
    this.appendFunction({ Name: cJSonDefaultAcceptExecute, Args: args }, true);
  }

  public setMaxRowCount(maxRowCount: number) {
    maxRowCount = Math.max(Math.round(maxRowCount), this.Ncl.FrgtData.Size);
    if (this.maxRowCount != maxRowCount) {
      //this.internalSetData({ MaxRowCount: maxRowCount } as DataGridDataRequest, true);
      this.maxRowCount = maxRowCount;
    }
  }

  public contextMenu(ndx: number) {
    if (ndx) this.appendFunction({ Name: cJSonFunctionContextMenu, Args: [ndx] }, true);
    else this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }
}

abstract class NclInnerColumnsListBase<
  T extends CSNclInnerDataGridMetadata | CSNclInnerTreeViewMetadata,
  S extends UpdateInnerDataGrid | UpdateInnerTreeView
> extends NclControl<T, S> {
  private columnMoveRequest: Partial<InnerColumnsListDataRequest>;
  private colNdx: number = undefined;

  public get ColNdx(): number {
    return this.colNdx;
  }

  public set ColNdx(value: number) {
    this.colNdx = value;
  }

  public updateState<U extends CSUpdateInnerColumnsList>(data: Partial<U>) {
    if ((data.ColumnsProportion && data.ComputedColumnsVersion === undefined) || (data.AutoAdjustWidth && this.state.AutoAdjustWidth)) {
      data.ColumnsVersion = Date.now();
    }
    super.updateState(data);
  }

  public setColumnsWidth(diffs: Array<number>, columnsProportion: ColumnsProportion) {
    if (!columnsProportion) return;
    const data: Array<Array<number>> = [];
    diffs.map((value, i) => {
      if (columnsProportion.length > i) {
        const v = GetColumnWidthEm(columnsProportion[i].Width + value, this.VCX.GridControl);
        data.push([i, v]);
      }
    });

    this.internalSetData<InnerColumnsListDataRequest>({ WidthColumns: data }, false, false, RealizerOperations.Update);
  }

  public columnMove(from: number, to: number, callToServer: boolean): boolean {
    if (!this.state.ColumnsProportion) return false;
    if (from <= this.state.ColumnsProportion.length && to < this.state.ColumnsProportion.length) {
      if (!this.columnMoveRequest) this.columnMoveRequest = { ColumnsMove: [] };

      this.columnMoveRequest.ColumnsMove.push([from, Math.max(to, -1)]);
      this.internalSetData(this.columnMoveRequest, callToServer, false, RealizerOperations.Update);
      return true;
    }
    return false;
  }

  public reCalculateColumnWidths(forWidth: number) {
    let adaptableWidth = 0;
    let occupyWidth = 0;
    let lW = 0;
    const columns: ColumnsProportion = [];

    if (this instanceof NclInnerTreeView) {
      if (this.state.MainColumnWidthEmW > 0 && this.state.ComputedColumnsVersion !== this.state.ColumnsVersion) {
        const mainCol = {
          Size: this.state.MainColumnWidthEmW,
          MultiplierKind: TUFCharCountMultiplierKind.ccmkEm,
          Adaptable: false,
          Alignment: TAlignment.taLeftJustify,
          Caption: "",
          DisplayStyleRT: TUFContextDisplayStyle.cdsText,
        } as ColumnProportionEm;
        mainCol.Width = GetColumnWidth(mainCol, this.VCX.GridControl);
        occupyWidth += mainCol.Width;
        if (mainCol.Adaptable) {
          adaptableWidth += mainCol.Width;
        }
        columns.push(mainCol);
      }
      forWidth = forWidth - 1.5 * this.VCX.GridControl.Font._MWidth; //expand button
    }

    if (this.state.ColumnsProportion && this.state.ColumnsProportion.length > 0) {
      this.state.ColumnsProportion.map((value: ColumnProportionEm) => {
        lW = GetColumnWidth(value, this.VCX.GridControl);
        occupyWidth += lW;
        if (value.Adaptable) {
          adaptableWidth += lW;
        }

        columns.push({ ...value, Width: lW });
      });
    }

    if (this.state.AutoAdjustWidth && forWidth > 0) {
      const fWidth: number = forWidth - this.state.ColumnsProportion.length * this.VCX.GridControl.LineWidth;
      let adaptPix = 0;
      let lastNdx = 0;
      let maxAdaptW = 0;
      if (fWidth != occupyWidth && adaptableWidth > 0) {
        adaptPix = 1 + (fWidth - occupyWidth) / adaptableWidth;
        occupyWidth = 0;
        lastNdx = -1;
        maxAdaptW = 0;

        columns.map((value: ColumnProportionEm, index: number) => {
          if (value.Adaptable) {
            value.Width = Math.max(Math.round(value.Width * adaptPix), this.VCX.GridControl.GetExteriorCellWidth(this.VCX.GridControl.GetRowHeight(1)));
            if (value.Width > maxAdaptW) {
              lastNdx = index;
              maxAdaptW = value.Width;
            }
          }
          occupyWidth += value.Width;
        });

        occupyWidth += 2;
        if (lastNdx > 0) {
          columns[lastNdx].Width = Math.max(
            columns[lastNdx].Width + (forWidth - occupyWidth),
            this.VCX.GridControl.GetExteriorCellWidth(this.VCX.GridControl.GetRowHeight(1))
          );
        }
      }
    }

    this.updateState({ ColumnsProportion: columns, ComputedColumnsVersion: this.state.ColumnsVersion });
  }

  protected initDataRequest() {
    super.initDataRequest();
    this.columnMoveRequest = null;
  }
}

export class K2TreeViewItem implements ClientData {
  private _parent: K2TreeViewItem;
  private nodes: Array<K2TreeViewItem>;
  private _key: string;
  private data: CSTreeDataItem;

  constructor(key: string, data: CSTreeDataItem, parent: K2TreeViewItem) {
    this._parent = parent;
    this._key = key;
    this.update(data);
    if (this._parent) this._parent.addAsChild(this);
  }

  public get parent(): K2TreeViewItem {
    return this._parent;
  }

  //property for UI

  public get key(): string {
    return this._key;
  }

  public get index(): number {
    if (this.data) {
      return this.data.Index;
    }
    return -1;
  }

  public get Data() {
    return this.data;
  }

  public get children(): K2TreeViewItem[] {
    return this.nodes;
  }

  public set children(data) {
    this.nodes = data;
  }

  public get columns(): CSTreeColumn[] {
    if (this.data) {
      return this.data.Columns;
    }
    return null;
  }

  public get expanded(): boolean {
    return this.data && this.data.Expanded === true;
  }

  public get checked(): boolean {
    return this.data && this.data.CheckState === TTreeViewNodeCheckState.tvncsChecked;
  }

  public getExpandedKeys(): string[] {
    const result = new Array<string>();
    if (this.expanded) {
      result.push(this.key);
    }
    if (this.children) {
      this.nodes.forEach((item) => {
        result.push(...item.getExpandedKeys());
      });
    }
    return result;
  }

  public getCheckedKeys(): string[] {
    const result = new Array<string>();
    if (this.checked) {
      result.push(this.key);
    }
    if (this.children) {
      this.nodes.forEach((item) => {
        result.push(...item.getCheckedKeys());
      });
    }
    return result;
  }

  //property for UI

  public move(newParent: K2TreeViewItem) {
    if (newParent && newParent !== this.parent) {
      if (this._parent && this.parent.children) {
        const index = this.parent.children.indexOf(this);
        if (index > -1) {
          this.parent.children.splice(index, 1);
        }
      }
      this._parent = newParent;
      this.parent.addAsChild(this);
    }
  }

  private addAsChild(child: K2TreeViewItem) {
    if (!this.nodes) {
      this.nodes = new Array<K2TreeViewItem>();
    }

    this.nodes.push(child);

    this.nodes.sort((a, b) => {
      return a.index - b.index;
    });
  }

  public delete(): boolean {
    if (this._parent && this._parent.children) {
      const ndx = this._parent.children.indexOf(this);
      if (ndx >= 0) {
        this._parent.children.splice(ndx, 1);
        return true;
      }
    }
    return false;
  }

  update(src: CSTreeDataItem) {
    if (src) {
      if (!this.data) {
        this.data = src;
      } else {
        const oldIndex = this.data.Index;
        if (src.Expanded !== undefined && this.data.Expanded !== src.Expanded) {
          this.data.Expanded = src.Expanded;
        }

        if (src.CheckState !== undefined && this.data.CheckState !== src.CheckState) {
          this.data.CheckState = src.CheckState;
        }

        if (src.Index !== undefined && this.data.Index !== src.Index) {
          this.data.Index = src.Index;
          if (this.data.Index !== oldIndex && this.parent && this.parent.nodes) {
            this.parent.nodes.sort((a, b) => {
              return a.index - b.index;
            });
          }
        }

        if (src.ParentKey !== undefined && this.data.ParentKey !== src.ParentKey) {
          this.data.ParentKey = src.ParentKey;
        }

        if (src.HasChildren !== undefined && this.data.HasChildren !== src.HasChildren) {
          this.data.HasChildren = src.HasChildren;
        }

        if (src.Columns !== undefined) {
          if (src.Columns.length === this.data.Columns.length) {
            src.Columns.forEach((column, index) => {
              if (column.CondFormatting && this.data.Columns[index].CondFormatting) {
                Object.assign(this.data.Columns[index].CondFormatting, column.CondFormatting);
                delete column.CondFormatting;
              }
              Object.assign(this.data.Columns[index], column);
            });
          } else {
            this.data.Columns = src.Columns; //when add or remove column server send all new columns
          }
        }
      }

      if (!this.nodes || src.HasChildren) {
        if (this.data.HasChildren !== TTreeDataHasChildNodes.chnNo) this.nodes = new Array<K2TreeViewItem>();
        else this.nodes = undefined;
      }
    }
  }
}

export enum TreeOperations {
  next = 1,
  prior = 2,
  firstPage = 3,
  lastPage = 4,
  expand = 5,
  collaps = 6,
}

export class NclInnerTreeView extends NclInnerColumnsListBase<CSNclInnerTreeViewMetadata, UpdateInnerTreeView> implements ClientContextMenuEmitor {
  private _tree: NclTreeView;
  private root: K2TreeViewItem;
  private map: Map<string, K2TreeViewItem>;
  private rowBmk: string;

  constructor(ncl: CSNclInnerTreeViewMetadata, parent: NclTreeView, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this._tree = parent;
    this.root = new K2TreeViewItem("", null, null);
    this.map = new Map<string, K2TreeViewItem>();
  }

  public get Tree(): NclTreeView {
    return this._tree;
  }

  public get Root(): K2TreeViewItem {
    return this.root;
  }

  public get RowBmk(): string {
    return this.rowBmk;
  }

  appendActionTo(menu: CSNclMenuMetadata): void {
    if (this.RowBmk && this.ColNdx >= 0 && this.map != null) {
      const row = this.map.get(this.RowBmk);
      if (row && this.ColNdx < row.columns.length) {
        const cell = row.columns[this.ColNdx];
        if (cell) {
          appendSpecialActionsToMenu(cell.Text, menu);
        }
      }
    }
  }

  protected createDefaultState(): UpdateInnerTreeView {
    return new UpdateInnerTreeView({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public executeExpand(value: string) {
    this.click(value, 0, 1, false);

    this.appendFunction({ Name: cJSonFunctionToggleNodeExecute, Args: null }, true);
  }

  public executeCheck(value: string, state: TTreeViewNodeCheckState) {
    this.appendFunction({ Name: cJSonFunctionCheckNodeExecute, Args: [value, state] }, true);
  }

  public invokeAction(op: TreeOperations) {
    // if (this.invalidData > 0) return;
    if (this.map && this.map.has(this.RowBmk)) {
      const current = this.map.get(this.RowBmk);
      if (current) {
        switch (op) {
          case TreeOperations.firstPage:
            if (this.Root && this.Root.children && this.Root.children.length > 0) {
              this.click(this.Root.children[0].key, this.ColNdx, 1, true);
            }
            break;
          case TreeOperations.lastPage:
            this.last(this.Root);
            break;
          case TreeOperations.next:
            this.next(current);
            break;
          case TreeOperations.prior:
            this.prior(current);
            break;
          case TreeOperations.expand:
            if (!current.expanded) {
              this.executeExpand(current.key);
            }
            break;
          case TreeOperations.collaps:
            if (current.expanded) {
              this.executeExpand(current.key);
            } else {
              if (current.parent && current.parent.expanded) {
                this.executeExpand(current.parent.key);
              }
            }
            break;
          default:
            break;
        }
      }
    }
  }

  private last(item: K2TreeViewItem) {
    if (item && item.children && item.children.length > 0) {
      const last = item.children[item.children.length - 1];
      if (last.expanded) {
        this.last(last);
      } else {
        this.click(last.key, this.ColNdx, 1, true);
      }
    }
  }

  private next(item: K2TreeViewItem, deeper = true) {
    if (item) {
      if (deeper && item.children && item.children.length > 0 && item.expanded === true) {
        this.click(item.children[0].key, this.ColNdx, 1, true);
      } else {
        if (item.parent && item.parent.children && item.parent.children.length > 0 && item.index + 1 < item.parent.children.length) {
          this.click(item.parent.children[item.index + 1].key, this.ColNdx, 1, true);
        } else {
          this.next(item.parent, false);
        }
      }
    }
  }

  private prior(item: K2TreeViewItem, deeper = true) {
    if (item) {
      if (item.index > 0 && item.parent && item.parent.children && item.parent.children.length > 0) {
        const prior = item.parent.children[item.index - 1];
        if (prior && prior.expanded && prior.children && prior.children.length > 0) {
          this.click(prior.children[prior.children.length - 1].key, this.ColNdx, 1, true);
        } else {
          this.click(prior.key, this.ColNdx, 1, true);
        }
      } else {
        this.click(item.parent.key, this.ColNdx, 1, true);
      }
    }
  }

  public executeMove(nodeBmk: string, toNodeBmk: string, mode: TTreeDataMoveNodeMode) {
    this.appendFunction({ Name: cJSonFunctionMoveNodeExecute, Args: [nodeBmk, toNodeBmk, mode] }, true);
  }

  public applyModification(changes: Array<ModifyTreeItem>): boolean {
    if (changes && changes.length > 0) {
      let item: K2TreeViewItem;
      let parent: K2TreeViewItem;
      let data: CSTreeDataItem;
      const unprocessed: ModifyTreeItem[] = [];
      changes.map((value) => {
        if (value.ModifyType !== TDataItemModifyType.tdimNew) {
          if (value.Key && this.map.has(value.Key)) {
            item = this.map.get(value.Key);
          } else {
            Log.warn(`TreeItem with key:${value.Key} not found.`);
          }
        }

        if (value.Data) {
          data = value.Data;
          if (typeof data === "string") {
            data = parseData(data);
          }
        }

        switch (value.ModifyType) {
          case TDataItemModifyType.tdimDelete:
            if (item) {
              this.map.delete(item.key);
              item.delete();
            }
            break;
          case TDataItemModifyType.tdimNew:
            if (data) {
              if (data.ParentKey) {
                if (this.map.has(data.ParentKey)) {
                  parent = this.map.get(data.ParentKey);
                } else {
                  unprocessed.push(value);
                  return;
                }
              } else {
                parent = this.root;
              }

              item = new K2TreeViewItem(value.Key, data, parent);
              this.map.set(item.key, item);
            }
            break;
          case TDataItemModifyType.tdimModify:
            if (item && data) {
              item.update(data);
              if (data.ParentKey !== undefined) {
                //move
                let newParent = null;
                if (this.map.has(data.ParentKey)) {
                  newParent = this.map.get(data.ParentKey);
                } else {
                  newParent = this.root;
                }
                if (newParent) {
                  item.move(newParent);
                }
              }
            }
            break;
          default:
            break;
        }
      });
      if (unprocessed.length > 0) {
        return this.applyModification(unprocessed);
      }
      return true;
    }

    return false;
  }

  public updateState<U extends CSUpdateInnerTreeView>(data: Partial<U>) {
    if (data.Data) {
      if (this.applyModification(data.Data)) {
        data.DataVersion = Date.now();
        delete data.Data;
      }
    }
    super.updateState(data);
  }

  public contextMenu(rowBmk: string, colIndex: number) {
    this.updatePositions(rowBmk, colIndex, true);

    if (!rowBmk && colIndex !== -1) this.appendFunction({ Name: cJSonFunctionContextMenu, Args: [-1] }, true);
    //row index can be -1 for heading
    else this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }

  public click(value: string, columnIndex: number, clickCount: number, callToServer = true) {
    const update = this.updatePositions(value, columnIndex, !callToServer);
    if (clickCount == 1 && callToServer && update) {
      this.vr.sendRequest(RealizerOperations.Update, this);
    }

    if (clickCount === 2 && callToServer) {
      this._tree.doubleClick();
    }
  }

  protected afterSetState(canUpdate: boolean, oldState: UpdateInnerTreeView) {
    let newCol: number;
    let newRBmk: string;
    if (canUpdate) {
      if (this.state.CurrentColumn !== oldState.CurrentColumn) {
        newCol = this.state.CurrentColumn;
      }
      if (this.state.CurrentBookmark !== oldState.CurrentBookmark) {
        newRBmk = this.state.CurrentBookmark;
      }
    }

    super.afterSetState(canUpdate, oldState);
    this.updatePositions(newRBmk, newCol, true, false);
  }

  private updatePositions(rowBmk: string, colIndex: number, forceUpdate: boolean, prepareDataToServer = true): boolean {
    const newColNdx = colIndex >= 0 ? colIndex : this.ColNdx;
    const newRowBmk = rowBmk ? rowBmk : this.rowBmk;
    let update = false;

    if (this.rowBmk != newRowBmk) {
      if (prepareDataToServer) this.internalSetData<TreeViewDataRequest>({ CurrentBookmark: newRowBmk }, false);
      update = true;
    }

    if (this.ColNdx !== newColNdx) {
      if (prepareDataToServer) this.internalSetData<InnerColumnsListDataRequest>({ CurrentColumn: newColNdx }, false, false, RealizerOperations.None);
      update = true;
    }

    if (update && forceUpdate) {
      const oldRow = this.rowBmk;
      this.rowBmk = newRowBmk;
      const oldCol = this.ColNdx;
      this.ColNdx = newColNdx;
      const listener: TreeViewListener = this.Listener as TreeViewListener;

      if (listener && listener.updatePosition) {
        this.Listener.updatePosition(oldRow, this.rowBmk, oldCol, this.ColNdx);
      }
    }

    return update;
  }
}

export class NclAggregationPanel extends NclControl<CSNclAggregationPanel, UpdateAggregationPanel> {
  private container: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>;

  constructor(
    ncl: CSNclAggregationPanel,
    parent: NclControlBase,
    container: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.container = container;
  }

  public updateState<U extends CSUpdateControl>(data: Partial<U>) {
    super.updateState(data);
  }

  contextMenu(index: number) {
    this.appendFunction({ Name: cJSonFunctionContextMenu, Args: [index] }, true);
  }

  protected createDefaultState(): UpdateAggregationPanel {
    return new UpdateAggregationPanel({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclDataGridFooter extends NclControl<CSNclDataGridFooter, UpdateControl> {
  private leftToolbar: NclToolBar;
  private rightToolbar: NclToolBar;

  constructor(
    ncl: CSNclDataGridFooter,
    parent: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.LeftToolbar) {
      this.leftToolbar = new NclToolBar(ncl.LeftToolbar, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.leftToolbar);
    }

    if (ncl.RightToolbar) {
      this.rightToolbar = new NclToolBar(ncl.RightToolbar, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.rightToolbar);
    }
  }

  get LeftToolbar(): NclToolBar {
    return this.leftToolbar;
  }

  get RightToolbar(): NclToolBar {
    return this.rightToolbar;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get MarginYFactor(): number {
    return 0;
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.sizeMap(25);
  }
}

export class NclGanttFooter extends NclControl<CSNclGanttFooter, UpdateControl> {
  private leftToolbar: NclToolBar;

  constructor(
    ncl: CSNclGanttFooter,
    parent: NclGantt /*<CSNclGanttMetadata,UpdateHeadered>*/,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.LeftToolbar) {
      this.leftToolbar = new NclToolBar(ncl.LeftToolbar, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.leftToolbar);
    }
  }

  get LeftToolbar(): NclToolBar {
    return this.leftToolbar;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get MarginYFactor(): number {
    return 0;
  }
}

export class NclDataGridContent extends NclControl<CSNclDataGridContent, UpdateControl> {
  private dataGrid: NclInnerDataGrid;

  constructor(
    ncl: CSNclDataGridContent,
    parent: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.DataGrid) {
      this.dataGrid = new NclInnerDataGrid(ncl.DataGrid, this, parent, vr, vrAttachDetachFce);
      this.addNestedControl(this.dataGrid);
    }
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  get DataGrid(): NclInnerDataGrid {
    return this.dataGrid;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    let result = super.internalComputeMinHeight();

    if (this.DataGrid) {
      result = this.DataGrid.ComputedMinHeightWithMargin;
    }

    return result;
  }
}

export class NclGanttContent extends NclControl<CSNclGanttContent, UpdateControl> {
  private ganttInner: NclInnerGantt;

  constructor(
    ncl: CSNclGanttContent,
    parent: NclGantt /*<CSNclGanttMetadata,UpdateHeadered>*/,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.GanttInner) {
      this.ganttInner = new NclInnerGantt(ncl.GanttInner, this, parent, vr, vrAttachDetachFce);
      this.addNestedControl(this.ganttInner);
    }
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  get GanttInner(): NclInnerGantt {
    return this.ganttInner;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    let result = super.internalComputeMinHeight();

    if (this.GanttInner) {
      result = this.GanttInner.ComputedMinHeightWithMargin;
    }

    return result;
  }
}

interface LastOptions {
  currentBookmark: string;
  topBookmark: string;
  operation: GridOperations;
}

class LocalGridCache {
  /** The number of remaining elements from which client ask for data from the CacheGrid */
  private UPDATEZONE_LENGTH = 15;
  /** The number of displayed rows in the CachePage above and below the viewport */
  private VIEWDELTA_COUNT = 50;

  private control: NclInnerDataGrid;

  private data: Array<CSDataItem>;
  private view: Array<CSDataItem>;

  private rowNdx: number;
  private __topNdxOfView: number;
  /** The starting row of the selection (CachePage) in the data*/
  private startViewNdx: number;
  private endViewNdx: number;
  private viewVersion: number;
  private last: LastOptions;

  private blockSwipeUp = false;
  private blockSwipeDown = false;

  private setPosition: (row?: number, col?: number) => void;

  constructor(control: NclInnerDataGrid, setPosition: (row?: number, col?: number) => void) {
    this.control = control;
    this.setPosition = setPosition;
    this.data = [];
    this.view = [];
    this.__topNdxOfView = 0;
    this.startViewNdx = 0;
  }

  public isEmpty(): boolean {
    return !this.data || this.data.length === 0;
  }

  public hasData(rowNdx: number): boolean {
    if (!this.isEmpty() && rowNdx > 0 && rowNdx < this.data.length) return true;
    return false;
  }

  public getDataItem(rowNdx: number): CSDataItem {
    if (this.hasData(rowNdx)) return this.data[rowNdx];
    return undefined;
  }

  /** Position of the first visible row in the scroll */
  private get topNdxOfView(): number {
    return this.__topNdxOfView;
  }

  public setTopNdxOfView(value: number) {
    if (this.__topNdxOfView !== value) {
      this.__topNdxOfView = value;
    }
  }

  public getUpdateZone(): number {
    return this.updateZone;
  }

  public get viewData(): Array<CSDataItem> {
    if (this.isEnabled()) {
      const dataVer = (this.control.State as UpdateInnerDataGrid).DataVersion;
      if (this.viewVersion !== dataVer) {
        if (this.maxRowCount > 0 && this.data) {
          let endW = this.topNdxOfView + this.startViewNdx + this.maxRowCount + this.viewDelta;
          let startW = endW - (this.maxRowCount + 2 * this.viewDelta);

          // Posune View o tolik kolik je StartView v mínusu (Např první načtení kdy je topViewNdx i ViewNdx 0)
          if (startW < 0) endW += Math.abs(startW);

          endW = Math.min(endW, this.data.length);
          startW = Math.max(startW, 0);

          if (endW > 0 && endW <= this.data.length && startW >= 0 && startW < this.data.length && endW > startW) {
            this.view = this.data.slice(startW, endW);
            this.setTopNdxOfView(Math.max(this.topNdxOfView - (startW - this.startViewNdx), 0));
            this.startViewNdx = startW;
            this.endViewNdx = endW;

            this.viewVersion = dataVer;
          }
        }
      }
      return this.view;
    }

    return this.data;
  }

  public getRowNdx(): number {
    return this.rowNdx;
  }

  public getViewRowNdx(): number {
    return this.convertToLocalRowNdx(this.rowNdx);
  }

  public getTopNdxOfView(): number {
    return this.topNdxOfView;
  }

  /** Number of visible rows */
  private get maxRowCount(): number {
    if (this.control && this.control.Container) {
      return this.control.Container.MaxRowCount;
    }
    return 0;
  }

  /** Number of loaded rows above and under viewport */
  private get viewDelta(): number {
    const maxDelta = Math.max((Context.DeviceInfo.ExplicitMaxRowCount - this.maxRowCount) / 2, 0);
    return Math.floor(Math.min(maxDelta, this.VIEWDELTA_COUNT)); // Pokud bude načteno míň řádků než je VIEWDELTA_COUNT
  }

  /**  The number of remaining elements from which client ask for data from the CacheGrid */
  private get updateZone(): number {
    return Math.floor(Math.min(this.viewDelta / 2, this.UPDATEZONE_LENGTH)); //  UPDATEZONE_LENGTH nemá být větší než polovina deltaCount
  }

  private getItemByKey(key: string): { index: number; data: CSDataItem } {
    if (key) {
      const i = this.data.findIndex((value) => value && value.Key === key);
      if (i >= 0) return { index: i, data: this.data[i] };
    }
    return undefined;
  }

  public applyModification(changes: Array<ModifyItem>): boolean {
    let result = false;
    let deleteCount = 0;
    let appendCount = 0;
    let isCurrSet = true;

    if (changes && changes.length > 0) {
      isCurrSet = false;
      let data: CSDataItem;
      let item: { index: number; data: CSDataItem };
      changes = changes.sort((a, b) => a.ModifyType - b.ModifyType);
      changes.map((value) => {
        if (value.ModifyType !== TDataItemModifyType.tdimNew) {
          item = this.getItemByKey(value.Key);

          if (!item) {
            Log.warn(`Item with key:${value.Key} not found.`);
          }
        }

        if (value.Changes) {
          data = value.Changes;
          if (typeof data === "string") {
            data = parseData(data);
          }
        }

        if (this.last && value.Key === this.last.currentBookmark && value.ModifyType !== TDataItemModifyType.tdimDelete) {
          this.rowNdx = data.Row;
          isCurrSet = true;
        }

        switch (value.ModifyType) {
          case TDataItemModifyType.tdimDelete:
            deleteCount++;
            if (item) {
              this.data[item.index] = undefined;
            }
            break;
          case TDataItemModifyType.tdimNew:
            if (data) {
              data.Key = value.Key;
              this.data[data.Row] = data;
              appendCount++;
            }
            break;
          case TDataItemModifyType.tdimModify:
            if (item && data) {
              if (data.Columns) {
                if (data.Columns.length === item.data.Columns.length) {
                  data.Columns.forEach((column, index) => {
                    if (column.CondFormatting) {
                      item.data.Columns[index].CondFormatting = Object.assign({}, item.data.Columns[index].CondFormatting, column.CondFormatting);
                      delete column.CondFormatting;
                    }
                    if (column.MultiTexts && item.data.Columns[index].MultiTexts) {
                      column.MultiTexts.map((multitext, i) => {
                        item.data.Columns[index].MultiTexts[i] = { ...item.data.Columns[index].MultiTexts[i], ...multitext };
                      });
                    } else {
                      Object.assign(item.data.Columns[index], column);
                    }
                  });
                } else {
                  item.data.Columns = data.Columns; //when add or remove column server send all new columns
                }
              }
              if (data.Row != undefined) {
                if (data.Row !== item.data.Row) {
                  [this.data[item.index], this.data[data.Row]] = [this.data[data.Row], this.data[item.index]];
                  item.data.Row = data.Row;
                }
              }
            }
            break;
          default:
            break;
        }
      });

      if (deleteCount > 0) {
        let i = 0;
        while (i < this.data.length && this.data[i] == undefined) {
          this.data.splice(i, 1);
          i++;
        }
        if (deleteCount > i) {
          i = this.data.length - 1;
          while (i >= 0 && this.data[i] == undefined) {
            this.data.splice(i, 1);
            i--;
          }
        }
      }
      result = true;
    }

    if (this.last) {
      // when didn't received data and lastCurrent is set, must set current to first or last
      if (this.data && this.data.length > 0) {
        if (this.last.operation === GridOperations.swipeUp) {
          if (isCurrSet == false) {
            this.rowNdx = changes && changes.length > 0 ? 0 : this.data.length - 1;
          }
          if (deleteCount > 0) {
            const topNdx = Math.max(this.startViewNdx + this.topNdxOfView - deleteCount, 0); // Index horního řádku v nových datech
            this.startViewNdx = Math.max(topNdx - this.viewDelta, 0);
            this.setTopNdxOfView(Math.max(topNdx - this.startViewNdx, 0)); // Nastavení horního řádku v posunutém view.
          } else {
            this.blockSwipeUp = true; // To block fetching new data at the top of all records
            this.setTopNdxOfView(this.topNdxOfView);
          }
        }

        if (this.last.operation === GridOperations.swipeDown) {
          if (isCurrSet == false) this.rowNdx = changes && changes.length > 0 ? this.data.length - 1 : 0;
          if (appendCount > 0) {
            const topNdx = Math.max(this.startViewNdx + this.topNdxOfView + appendCount, 0); // Index horního řádku v nových datech
            this.startViewNdx = Math.max(topNdx - this.viewDelta, 0);
            this.setTopNdxOfView(Math.max(topNdx - this.startViewNdx, 0));
          } else {
            this.blockSwipeDown = true; // To block fetching new data at the bottom of all records
          }
        }
      }
      this.last = undefined;
      result = true;
    }

    return result;
  }

  private convertToLocalRowNdx(rowNdx: number): number {
    return rowNdx - this.startViewNdx;
  }

  public isEnabled(): boolean {
    return this.data.length > 0 && (this.data.length > this.maxRowCount || Context.DeviceInfo.ExplicitMaxRowCount > 0);
  }

  /** Set the current row */
  public updatePositions(rowIndex: number, colIndex: number, forceUpdate: boolean, prepareDataToServer = true) {
    let update = false;
    const newRowNdx = rowIndex >= 0 ? rowIndex : this.rowNdx;
    const newColNdx = colIndex >= 0 ? colIndex : this.control.ColNdx;

    if (this.control.ColNdx !== newColNdx) {
      update = true;
      if (prepareDataToServer) this.setPosition(undefined, newColNdx);
    }

    if (this.rowNdx !== newRowNdx) {
      update = true;
      if (prepareDataToServer) this.setPosition(newRowNdx);
    }

    if (update && forceUpdate) {
      const oldRow = this.rowNdx;
      this.rowNdx = newRowNdx;
      const oldCol = this.control.ColNdx;
      this.control.ColNdx = newColNdx;
      const listener: DataGridListener = this.control.Listener as DataGridListener;
      if (listener && listener.updatePosition) {
        let newR = this.convertToLocalRowNdx(this.rowNdx);
        let oldR = this.convertToLocalRowNdx(oldRow);
        if (newR < 0 || newR > this.viewData.length) {
          // if (this.moveViewTo(newR) === false) return;
          newR = this.convertToLocalRowNdx(this.rowNdx);
          oldR = this.convertToLocalRowNdx(oldRow);
        }
        this.control.Listener.updatePosition(oldR, newR, oldCol, this.control.ColNdx);
      }
    }
  }

  public moveView(op: GridOperations, ignoreBlock = false): boolean {
    if (!this.isEnabled()) return undefined;

    if (op === GridOperations.next) {
      /* == Load more data downwards == */
      if (this.startViewNdx + this.maxRowCount + 2 * this.viewDelta + this.updateZone >= this.data.length) {
        this.blockSwipeDown = false;
        if (this.blockSwipeUp === true && ignoreBlock === false) {
          if (this.endViewNdx >= this.data.length) return false; // In case the user is at the very end of the data
        } else {
          // Loads new data
          this.last = { operation: GridOperations.swipeUp } as LastOptions;
          if (this.data.length > this.rowNdx) this.last.currentBookmark = this.data[this.rowNdx].Key;
          if (this.data.length > this.topNdxOfView) this.last.topBookmark = this.data[this.topNdxOfView].Key;

          this.control.invokeAction(this.last.operation);
          return undefined;
        }
      }

      // There are still more rows in Cache
      this.control.updateState({ DataVersion: Date.now() });
      return false;
    } else if (op === GridOperations.prior) {
      /* == Load more data upwards == */
      if (this.startViewNdx <= this.updateZone) {
        this.blockSwipeUp = false;

        if (this.blockSwipeDown === true && ignoreBlock === false) {
          if (this.startViewNdx <= 0) return false; // In case the user is at the very beginning of the data
        } else {
          // Loads new data
          this.last = { operation: GridOperations.swipeDown } as LastOptions;
          if (this.data.length > this.rowNdx) this.last.currentBookmark = this.data[this.rowNdx].Key;

          this.control.invokeAction(this.last.operation);
          return undefined;
        }
      }

      // There are still more rows in Cache
      this.control.updateState({ DataVersion: Date.now() });
      return false;
    }
  }

  /** Setting the CachePage position on the display relative to startViewNdx */
  public moveViewTo(ndx: number /** viewRowNdx */, cmp: number): boolean {
    if (!this.isEnabled()) return undefined;
    // In the case of upward movement (for prev button action from Ribbon)
    if (cmp < 0) {
      this.startViewNdx = this.startViewNdx + ndx;
    } else {
      this.startViewNdx = this.startViewNdx + ndx - this.maxRowCount;
    }

    this.setTopNdxOfView(0);

    this.control.updateState({ DataVersion: Date.now() });
    return true;
  }

  public isInVisibleView(ndx: number): number {
    if (!this.isEnabled()) return 0;
    if (ndx < this.topNdxOfView) return -1;
    if (ndx > this.topNdxOfView + this.maxRowCount - 1) return 1;
    return 0;
  }
}

export class NclInnerDataGrid extends NclInnerColumnsListBase<CSNclInnerDataGridMetadata, UpdateInnerDataGrid> implements ClientContextMenuEmitor {
  private container: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>;
  private quickFilter: NclQuickFilter;
  private clmVersion: number;
  private rowHeight: number;
  private cache: LocalGridCache;
  private timer: number;
  private ctimer: number;

  constructor(
    ncl: CSNclInnerDataGridMetadata,
    parent: NclControlBase,
    container: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (container.Ncl.QuickFilter) {
      this.quickFilter = new NclQuickFilter(container.Ncl.QuickFilter, this, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.quickFilter);
    }
    this.container = container;
    this.cache = new LocalGridCache(this, (row, col) => {
      if (row != undefined) this.internalSetData<InnerDataGridDataRequest>({ Position: row }, false, false, RealizerOperations.None);
      if (col != undefined) this.internalSetData<InnerDataGridDataRequest>({ CurrentColumn: col }, false, false, RealizerOperations.None);
    });
  }

  appendActionTo(menu: CSNclMenuMetadata): void {
    if (this.rowNdx >= 0 && this.ColNdx >= 0) {
      const data = this.cache.getDataItem(this.rowNdx);
      if (data && data.Columns && this.ColNdx < data.Columns.length) {
        const cell = data.Columns[this.ColNdx];
        if (cell) {
          if (cell.MultiTexts) appendSpecialActionsToMenu(cell.MultiTexts, menu);
          else if (cell.Text) appendSpecialActionsToMenu(cell.Text, menu);
        }
      }
    }
  }

  public get LastVisibleLocatorPanel(): NclLocatorPanel {
    return this.vr.LastVisibleLocator;
  }

  public getRowHeightMultiplier(): number {
    return this.container ? this.container.Ncl.FrgtData.RowHeightMultiplier : 1;
  }

  public isHideHeading(): boolean {
    return this.container ? this.container.Ncl.FrgtData.HideHeading : false;
  }

  public getRowHeight(): number {
    const rhm = this.VCX.GridControl.GetRowHeight(this.getRowHeightMultiplier());

    if (this.state.ColumnsVersion == undefined) return rhm;
    if (this.rowHeight && this.state.ColumnsVersion === this.clmVersion) return this.rowHeight;

    let max = -1;

    this.clmVersion = this.state.ColumnsVersion;
    if (this.state.ColumnsProportion) {
      this.state.ColumnsProportion.map((column) => {
        if (this.VCX.calcMFMeasure(column.MFMeasure)) {
          if (column.MFMeasure.Height > max) max = column.MFMeasure.Height;
        }
      });
    }

    this.rowHeight = Math.max(rhm, max);

    return this.rowHeight;
  }

  public get QuickFilter(): NclQuickFilter {
    return this.quickFilter;
  }
  /**
   * Index of actual row in data cache
   */
  private get rowNdx(): number {
    return this.cache.getRowNdx();
  }

  /**
   * Index of actual row in view
   */
  public get viewRowNdx(): number {
    return this.cache.getViewRowNdx();
  }

  public get topNdxOfView(): number {
    return this.cache.getTopNdxOfView();
  }

  /**  The number of remaining elements from which client ask for data from the CacheGrid */
  public get UPDATEZONE_LENGTH(): number {
    return this.cache.getUpdateZone();
  }

  public setTopNdxOfView(value: number): void {
    this.cache.setTopNdxOfView(value);
  }

  public get data(): Array<CSDataItem> {
    return this.cache.viewData;
  }

  public updateState<U extends CSUpdateInnerDataGrid>(data: Partial<U>) {
    if (data.Data) {
      if (this.cache.applyModification(data.Data)) {
        data.DataVersion = Date.now();
      }
      delete data.Data;
    }
    super.updateState(data);
    // NclInnerDataGrid.log("updateState", this.data, this.topNdxOfView, this.viewRowNdx, this.container.MaxRowCount);
  }
  //   public static log(prefix: string, data: Array<CSDataItem>, topNdxOfView: number, current: number, maxRowCount: number) {
  //     //return;
  //     if (data && topNdxOfView >= 0 && topNdxOfView < data.length) {
  //       if (data[topNdxOfView].Columns.length > 2) {
  //         console.log(`${prefix}
  //         Top: ${data[topNdxOfView].Columns[1].Text}...
  //         Current:${data.length > current && current >= 0 ? data[current].Columns[1].Text : "mimo"}
  //         Bottom:${topNdxOfView + maxRowCount - 1 < data.length ? data[topNdxOfView + maxRowCount - 1].Columns[1].Text : "mimo"}`);
  //       } else {
  //         console.log(`${prefix}
  // Top: ${data[topNdxOfView].Columns[1].MultiTexts[1]}...
  // Current:${data.length > current && current >= 0 ? data[current].Columns[1].MultiTexts[1] : "mimo"}
  // Bottom:${topNdxOfView + maxRowCount - 1 < data.length ? data[topNdxOfView + maxRowCount - 1].Columns[1].MultiTexts[1] : "mimo"}`);
  //       }
  //     }
  //   }

  protected afterSetState(canUpdate: boolean, oldState: UpdateInnerDataGrid) {
    let newRow: number;
    let newCol: number;
    if (canUpdate) {
      if (this.state.Position !== oldState.Position) {
        newRow = this.state.Position;
      }

      newCol = this.state.CurrentColumn;
    }

    this.cache.updatePositions(newRow, newCol, true, false);

    super.afterSetState(canUpdate, oldState);
  }

  protected createDefaultState(): UpdateInnerDataGrid {
    return new UpdateInnerDataGrid({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public contextMenu(rowIndex: number, colIndex: number) {
    if (colIndex >= 0) this.cache.updatePositions(rowIndex, colIndex, true);

    if (rowIndex === -1) this.appendFunction({ Name: cJSonFunctionContextMenu, Args: colIndex >= 0 ? [rowIndex] : [rowIndex, colIndex] }, true);
    //row index can be -1 for heading
    else this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }

  public click(rowIndex: number, columnIndex: number, clickCount: number = undefined, shiftKey = false, ctrlKey = false, isLink = false, timeout = 0) {
    if (this.ctimer) clearTimeout(this.ctimer);
    if (Context.getApplication().isBusy()) return;

    if (ctrlKey) {
      this.cache.updatePositions(rowIndex, columnIndex, false, true);
      if (isLink) {
        this.internalSetData<InnerDataGridDataRequest>({ HotTrackColumn: columnIndex, HotTrackRow: rowIndex }, false);
        this.Container.appendFunction({ Name: cJSonDatagridLinkClickExecute }, true);
        return;
      } else {
        this.internalSetData<InnerDataGridDataRequest>({ HotTrackColumn: columnIndex, HotTrackRow: rowIndex, CtrlKey: ctrlKey }, false);
      }
    }

    if (shiftKey) {
      this.internalSetData<InnerDataGridDataRequest>({ ShiftKey: shiftKey }, false);
    }

    this.cache.updatePositions(rowIndex, columnIndex, clickCount < 2);

    this.ctimer = window.setTimeout(() => {
      if (clickCount === 1) {
        this.vr.sendRequest(RealizerOperations.Update, this);
      }

      if (clickCount === 2) {
        this.container.doubleClick();
      }
    }, timeout);
  }

  get Container(): NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid> {
    return this.container;
  }

  protected internalComputeMinHeight(): number {
    let result = super.internalComputeMinHeight();

    const gridLineWidth = 1;
    result = (this.getRowHeight() + gridLineWidth) * this.container.Ncl.FrgtData.Size /*+ FixedRowCount*/;
    return result;
  }

  public isCacheEnabled(): boolean {
    return this.cache.isEnabled();
  }

  /** Loads another view from Cache or next Data set */
  public moveView(op: GridOperations, ignoreBlock = false): boolean {
    return this.cache.moveView(op, ignoreBlock);
  }

  public moveViewTo(ndx: number, cmp: number): boolean {
    return this.cache.moveViewTo(ndx, cmp);
  }

  public isInVisibleView(ndx: number): number {
    return this.cache.isInVisibleView(ndx);
  }

  public invokeAction(op: GridOperations) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (Context.getApplication().isBusy()) return;

    let diff = 0;
    if (op === GridOperations.next) {
      if (this.rowNdx + 1 < this.data.length) diff = 1;
    } else if (op === GridOperations.prior) {
      if (this.rowNdx > 0) diff = -1;
    }

    if (diff === 0) {
      this.container.appendFunction({ Name: cJSonFunctionGridOperation, Args: [op] }, true);
    } else {
      this.cache.updatePositions(this.rowNdx + diff, this.ColNdx, true, true);
      this.timer = window.setTimeout(() => {
        this.vr?.sendRequest(RealizerOperations.Update, this);
      }, 350);
    }
  }

  public executeCommandByNumber = (commandNumber: number) => {
    this.cache.updatePositions(this.rowNdx, this.ColNdx, false);
    this.container.appendFunction({ Name: cJSonExecuteCommandByNumber, Args: [commandNumber] });
  };

  public willUnMount(onlyListener: boolean): void {
    window.clearTimeout(this.timer);
    window.clearTimeout(this.ctimer);
    super.willUnMount(onlyListener);
  }
}

export class NclInnerGantt extends NclControl<CSNclInnerGanttMetadata, UpdateInnerGantt> {
  private container: NclGantt; // NclGantt<CSNclGanttMetadata,UpdateHeadered>;
  private maxRowCount: number;
  private dataItems: Highcharts.GanttPointOptionsObject[] = [];
  private seriesDataItems: Array<Highcharts.SeriesOptionsType> = []; //Highcharts.SeriesGanttOptions[] = [];
  private categories: string[] = [];
  private yAxisOptions: Highcharts.YAxisOptions;
  private draggableX: boolean;
  private draggableY: boolean;
  private hideHint: boolean;
  private xAxisOptions: {
    minRangeDate: number;
    maxRangeDate: number;
    plotBands: { from: number; to: number; color: string; zIndex: number }[];
  };

  constructor(
    ncl: CSNclInnerGanttMetadata,
    parent: NclControlBase,
    container: NclGantt /*<CSNclGanttMetadata,UpdateHeadered>*/,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.container = container;
    this.maxRowCount = -1;
  }

  protected createDefaultState(): UpdateInnerGantt {
    return new UpdateInnerGantt({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public updateState<U extends CSUpdateInnerGantt>(data: Partial<U>) {
    if (data.HoverHint) {
      data.HoverHintVersion = this.state.HoverHintVersion + 1;
    }
    if (data.SelectedList) {
      data.SelectedListVersion = this.state.SelectedListVersion + 1;
    }
    if (data.ExportToImage) {
      data.ExportToImage = this.state.ExportToImage + 1;
    }
    if ("RangeXMax" in data || "RangeXMin" in data) {
      data.RangeXVersion = this.state.RangeXVersion + 1;
    }
    if ("RangeYMax" in data || "RangeYMin" in data) {
      data.RangeYVersion = this.state.RangeYVersion + 1;
    }
    super.updateState(data);
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public setMaxRowCount(maxRowCount: number) {
    if (isDesktopComponent(this.getRealizerUID())) return;

    maxRowCount = Math.max(Math.round(maxRowCount), this.Container.Ncl.FrgtData.Size);
    if (this.maxRowCount != maxRowCount) {
      this.internalSetData<GanttDataRequest>({ MaxRowCount: maxRowCount }, true);
      this.maxRowCount = maxRowCount;
    }
  }

  public contextMenu(rowIndex: number, colIndex: number) {
    this.appendFunction({ Name: cJSonFunctionContextMenu, Args: [rowIndex, colIndex] }, true);
  }

  public rowClick(clickCount: number, rowIndex: number) {
    if (this.state.Position !== rowIndex) {
      this.internalSetData<GanttDataRequest>(
        { Position: rowIndex },
        clickCount === 1,
        false,
        clickCount === 1 ? RealizerOperations.Update : RealizerOperations.None
      );
    }
    if (clickCount === 2) {
      this.container.doubleClick();
    }
  }

  get Container(): NclGantt {
    //<CSNclGanttMetadata,UpdateHeadered>{
    return this.container;
  }

  set Container(container: NclGantt) {
    this.container = container;
  }

  /** Roztáhnutí gantu na 100% výšky parenta a řešení scrollbaru komponentou */
  get IsFit() {
    return this.Container.byResources() && !this.Container.Ncl.FrgtData.DoNotFitToPage;
  }

  protected internalComputeMinHeight(): number {
    let result = super.internalComputeMinHeight();

    const gridLineWidth = 1;
    result = (this.VCX.GridControl.GetRowHeight(1) + gridLineWidth) * (this.container.Ncl.FrgtData.Size + 2) /*+ FixedRowCount*/;
    return result;
  }

  public defaultAccept(args?: Array<any>) {
    this.appendFunction({ Name: cJSonDefaultAcceptExecute, Args: args }, true);
  }

  public ganttContextMenu(args?: Array<any>) {
    this.appendFunction({ Name: cJSonGanttContextMenu, Args: args }, true);
  }

  public rangeSelect(args?: Array<any>) {
    this.appendFunction({ Name: cJSonRangeSelect, Args: args }, true);
  }

  public selectPoint(args?: Array<any>) {
    this.appendFunction({ Name: cJSonSelectPoint, Args: args }, true);
  }

  public unselectPoint(args?: Array<any>) {
    this.appendFunction({ Name: cJSonUnselectPoint, Args: args }, true);
  }

  public ganttGetRecord(args?: Array<any>) {
    this.appendFunction({ Name: "GanttGetRecord", Args: args }, true);
  }

  public change(args?: Array<any>) {
    this.appendFunction({ Name: "SetData", Args: args }, true);
  }

  public setClientZoom(args?: Array<any>) {
    this.appendFunction({ Name: "SetClientZoom", Args: args }, true);
  }

  public exportImageToString(args?: Array<any>) {
    this.appendFunction({ Name: "ImageSvgString", Args: args }, true);
  }

  public userRangeSelect(userRangeSelect: GanttUserRangeSelect) {
    this.appendFunction(
      {
        Name: cJSonUserRangeSelect,
        Args: [userRangeSelect.x1 || "", userRangeSelect.x2 || "", userRangeSelect.y1 || "", userRangeSelect.y2 || ""],
      },
      true
    );
  }

  public pointHover(id: string, type: string) {
    this.appendFunction({ Name: cJSonPointHover, Args: [id, type] }, true);
  }

  public ganttResolution(width: string, height: string) {
    this.appendFunction({ Name: cJSonGanttResolution, Args: [width, height] }, true);
  }

  public set DataItems(dataItems: Highcharts.GanttPointOptionsObject[]) {
    this.dataItems = dataItems;
  }

  public get DataItems() {
    return this.dataItems;
  }

  public set SeriesDataItems(seriesDataItems: Array<Highcharts.SeriesOptionsType>) {
    this.seriesDataItems = seriesDataItems;
  }

  public get SeriesDataItems() {
    return this.seriesDataItems;
  }

  public set Categories(categories: string[]) {
    this.categories = categories;
  }

  public get Categories() {
    return this.categories;
  }

  public set YAxisOptions(options: Highcharts.YAxisOptions) {
    this.yAxisOptions = options;
  }

  public get YAxisOptions() {
    return this.yAxisOptions;
  }

  public get XAxisOptions() {
    return this.xAxisOptions;
  }

  public get DraggableX() {
    return this.draggableX;
  }

  public set DraggableX(value: boolean) {
    this.draggableX = value;
  }

  public get DraggableY() {
    return this.draggableY;
  }

  public set DraggableY(value: boolean) {
    this.draggableY = value;
  }

  public get HideHint() {
    return this.hideHint;
  }

  public set HideHint(value: boolean) {
    this.hideHint = value;
  }

  public get OwnBackgroundColor() {
    return this.container.Ncl.OwnBackgroundColor;
  }

  public get CapacitiesColor() {
    return this.container.Ncl.CapacitiesColor;
  }

  public set XAxisOptions(options: { minRangeDate: number; maxRangeDate: number; plotBands: { from: number; to: number; color: string; zIndex: number }[] }) {
    this.xAxisOptions = options;
  }

  public get InEditMode(): boolean {
    return Boolean(this.Container.Ncl?.FrgtData?.InEditMode);
  }

  public get VCXZoom(): number {
    if (isDesktopComponent(this.getRealizerUID())) {
      return this.container.Ncl.Fake_VCXZoom;
    }

    return this.VCX.Zoom / 100;
  }

  public get ClientZoom(): number {
    return this.container.Ncl.ClientZoom;
  }

  public get Colors(): any {
    if (this.Container.Ncl.Fake_VCXColorMap) {
      return this.Container.Ncl.Fake_VCXColorMap.split(";");
    }

    return {
      AccentBaseColorBck: this.VCX.getColor(this.VCX.Data.ColorMap.AccentBaseColorBck),
      GridRulerColorBck: this.VCX.getColor(this.VCX.Data.ColorMap.GridRulerColorBck),
      BaseColorBck1: this.VCX.getColor(this.VCX.Data.ColorMap.BaseColorBck1),
      ContentColorBck1: this.VCX.getColor(this.VCX.Data.ColorMap.ContentColorBck1),
      ContentChangeDecorateColorFrg: this.VCX.getColor(this.VCX.Data.ColorMap.ContentChangeDecorateColorFrg),
      BaseColorFrg1: this.VCX.getColor(this.VCX.Data.ColorMap.BaseColorFrg1),
      ContentFrame1: this.VCX.getColor(this.VCX.Data.ColorMap.ContentFrame1),
      DataBrowseColorBck: this.VCX.getColor(this.VCX.Data.ColorMap.DataBrowseColorBck),
      DataBrowseColorFrg: this.VCX.getColor(this.VCX.Data.ColorMap.DataBrowseColorFrg),
      DataChangeColorBck: this.VCX.getColor(this.VCX.Data.ColorMap.DataChangeColorBck),
      DataChangeColorFrg: this.VCX.getColor(this.VCX.Data.ColorMap.DataChangeColorFrg),
    };
  }
}

abstract class NclDataGridBase<T extends CSNclDataGridBaseMetadata, S extends UpdateDataGrid> extends NclHeaderedControl<T, S> {
  private content: NclDataGridContent;
  private footer: NclDataGridFooter;
  private aggregationPanel: NclAggregationPanel;
  private maxRowCount: number;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.maxRowCount = -1;

    if (ncl.Content) {
      this.content = new NclDataGridContent(ncl.Content, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.content);
    }

    if (ncl.Footer) {
      this.footer = new NclDataGridFooter(ncl.Footer, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.footer);
    }

    if (ncl.AggregationPanel) {
      this.aggregationPanel = new NclAggregationPanel(ncl.AggregationPanel, this, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.aggregationPanel);
    }
  }

  protected createDefaultState(): S {
    return new UpdateDataGrid({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() }) as S;
  }

  get AggregationPanel(): NclAggregationPanel {
    return this.aggregationPanel;
  }

  get Content(): NclDataGridContent {
    return this.content;
  }

  get Footer(): NclDataGridFooter {
    return this.footer;
  }

  get MaxRowCount(): number {
    return this.maxRowCount;
  }

  public changeSortBy(column: number, append: boolean) {
    this.appendFunction({ Name: cJSonFunctionGridChangeSortBy, Args: [column, append] }, true);
  }

  public doubleClick() {
    this.appendFunction({ Name: cJSonDataGridDblClickExecute }, true);
  }

  public refreshMaxRowCount() {
    if (this.maxRowCount <= 0) return;
    this.setMaxRowCount(this.maxRowCount);
  }

  public setMaxRowCount(maxRowCount: number) {
    maxRowCount = Math.round(maxRowCount);

    if (Context.DeviceInfo.ExplicitMaxRowCount < this.Ncl.FrgtData.Size) {
      maxRowCount = Math.max(maxRowCount, this.Ncl.FrgtData.Size);
    }

    if (this.maxRowCount != maxRowCount) {
      const explicitValue = Math.max(Context.DeviceInfo.ExplicitMaxRowCount, maxRowCount);
      this.internalSetData<InnerDataGridDataRequest>({ MaxRowCount: explicitValue }, true);
      this.maxRowCount = maxRowCount;
    }
  }

  protected internalComputeMinHeight(): number {
    let result = super.internalComputeMinHeight();

    if (!this.Ncl.FrgtData.HideHeading) {
      result += this.VCX.sizeMap(21);
    }

    if (this.Content) {
      result += this.content.ComputedMinHeight;
    }

    if (this.Footer && this.showFooter()) {
      result += this.footer.ComputedMinHeight;
    }

    return result;
  }

  abstract showFooter(): boolean;
}

export class NclDataGrid extends NclDataGridBase<CSNclDataGridMetadata, UpdateDataGrid> {
  private instantFilterPanel: NclInstantFilterPanel;

  constructor(ncl: CSNclDataGridMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.InstantFilterPanel) {
      this.instantFilterPanel = new NclInstantFilterPanel(ncl.InstantFilterPanel, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.instantFilterPanel);
    }
  }

  get InstantFilterPanel(): NclInstantFilterPanel {
    return this.instantFilterPanel;
  }

  isShowHeader(): boolean {
    return super.isShowHeader() && !this.Ncl.FrgtData.HideHeader;
  }

  showFooter(): boolean {
    return !this.Ncl.FrgtData.HideFooter;
  }

  public isLite(): boolean {
    return this.Ncl.FrgtData.LiteHeader ? true : false;
  }

  public executeCommandByNumber_InstantFilter = (commandNumber: number) => {
    this.appendFunction({ Name: cJSonExecuteCommandByNumber_InstantFilter, Args: [commandNumber] }, true);
  };
}

export class NclSimpleDataGrid extends NclDataGrid {
  // extends NclDataGridBase<CSNclSimpleDataGridMetadata, UpdateBaseGrid>{

  showFooter(): boolean {
    return false;
  }

  isShowHeader(): boolean {
    return false;
  }
}

export class NclViewBase<T extends CSNclViewMetadata, S extends UpdateHeadered> extends NclHeaderedControl<T, S> {
  private content: NclPanel<CSNclPanelMetadata, UFUpdateControl> | undefined;
  private setEBRequest: Partial<ViewDataRequest> | null = null;
  private currentExplicitBounds: Partial<ExplicitBounds>;
  private name: string | undefined;
  private _isNotificationBox: boolean | undefined;
  private autoUpdateTimer: number | null = null;
  private autoCloseTimer: number | null = null;
  private maximizeCommand: NclCommandItem | undefined;
  private defaultCommand: NclCommandItem | undefined;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Content) {
      this.content = new NclPanel<CSNclPanelMetadata, UFUpdateControl>(ncl.Content, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.content);
    }
    if (vr.isForm()) this.currentExplicitBounds = ncl.FormExplicitBounds;
    else this.currentExplicitBounds = ncl.ExplicitBounds;

    if (!this.currentExplicitBounds) {
      this.currentExplicitBounds = {};
    }

    this.updateState<UpdateHeadered>({ isMaximized: this.isMaximized() });

    if (Context.DeviceInfo.StyleOfModalWindowShow === TBehaviorTypeByDevice.btbdNormal && this.isShowHeader() && this.Header?.RQuickButtons.length >= 0) {
      this.maximizeCommand = vr.createClientControl((context) => {
        return new ClientNclCommandItem("", "wui*maximize", this, this.toggleMaximize, this.Header, true, context, ClientActionNames.MAXIMIZE);
      }) as NclCommandItem;

      this.defaultCommand = vr.createClientControl((context) => {
        return new ClientNclCommandItem("", "wui*defaultsize", this, this.handleDefaultState, this.Header, false, context, ClientActionNames.DEFAULT);
      }) as NclCommandItem;

      this.Header.RQuickButtons = [this.defaultCommand, this.maximizeCommand, ...this.Header.RQuickButtons];
      this.maximizeCommand.updateState<CSUpdateCommandItem>(this.state.isMaximized ? { GlyphId: "wui*unmaximize" } : { GlyphId: "wui*maximize" });

      if (this.getExplicitPosition() || this.getExplicitSize()) {
        this.defaultCommand.updateState<CSUpdateCommandItem>({ Enabled: true });
      } else {
        this.updateState<UpdateHeadered>({ isDefaultSizeAndPosition: true });
      }
    }

    if (
      Context.DeviceInfo.StyleOfModalWindowShow === TBehaviorTypeByDevice.btbdMobile &&
      !this.isShowHeader() &&
      this.Header &&
      this.VCX.Data.Placement?.MaximizeAll != 1 &&
      !this.isNotificationBox
    ) {
      this.Header.updateState({ Visible: true });

      if (this.getRectInDock().FrameStyle >= FrameStyle.frsNone) {
        this.getRectInDock().FrameStyle = FrameStyle.frsTitle;
      }

      const closeCommand = vr.createClientControl((context) => {
        return new ClientNclCommandItem("", "wui*close", this, this.handleClose, this.Header, true, context, ClientActionNames.CLOSE);
      }) as NclCommandItem;

      this.Header.RQuickButtons.push(closeCommand);
    }
  }

  public toggleMaximize() {
    this.updateState<UpdateHeadered>({ isMaximized: !this.state.isMaximized });
    this.maximizeCommand?.updateState<CSUpdateCommandItem>(this.state.isMaximized ? { GlyphId: "wui*unmaximize" } : { GlyphId: "wui*maximize" });

    if (this.state.isMaximized) {
      this.maximizeView(true);
    } else {
      this.maximizeView(false);
    }
  }

  public handleDefaultState() {
    this.defaultExplicitBounds();
    this.updateState<UpdateHeadered>({ isDefaultSizeAndPosition: true });
    this.defaultCommand?.updateState<CSUpdateCommandItem>({ Enabled: !this.defaultCommand.State.Enabled });
  }

  public handleClose() {
    this.closeRequest();
  }

  protected afterSetState(canUpdate: boolean, oldState: S): void {
    super.afterSetState(canUpdate, oldState);
    if (canUpdate && this.state.Enabled !== oldState.Enabled && this.Content) {
      this.Content.setTabStop(this.state.Enabled);
    }
  }

  get CurrentExplicitBounds() {
    return this.currentExplicitBounds;
  }

  public getName(): string {
    if (!this.name) {
      this.name = this.MetaData.Name; //origin name of component received from server
      const rid = this.getRectInDock();
      if (rid && rid.AnchorControlUID !== "") {
        const vr: ViewRealizer = this instanceof NclFloaterView ? this.vr : this.vr.getPriorRealizer();
        if (vr) {
          const ctrl = vr.getControlByUID(rid.AnchorControlUID);
          if (ctrl) {
            this.name = `${ctrl.MetaData.Name}.${this.MetaData.Name}`; //name of component calculate in klient for better identify component in selenium
          }
        }
      }
    }

    return this.name;
  }

  public get isNotificationBox(): boolean {
    if (this._isNotificationBox === undefined) {
      this._isNotificationBox = this.MetaData.Name === "NotificationBox";
    }
    return this._isNotificationBox;
  }

  public getAutoCloseTime(): number {
    return this.vr.getAutoCloseTime();
  }

  public getAutoCloseTimeRemaining(): number {
    return this.vr.getAutoCloseTimeRemaining();
  }

  public callAutoFocus(ctrlUID: string) {
    if (!ctrlUID) return;

    const ctrl = this.vr.getControlByUID(ctrlUID);
    if (ctrl) {
      ctrl.updateFocus(true);
    }
  }

  public callAutoClose() {
    this.appendFunction({ Name: cJSonFunctionAutoClose });
    this.vr.sendRequest(RealizerOperations.None, this);
  }

  public get Content(): NclControlBase | undefined {
    return this.content;
  }

  public isLite(): boolean {
    return false;
  }

  public callAutoUpdate() {
    this.vr.callAutoUpdate(this);
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public closeRequest() {
    this.vr.closeRequest();
  }

  public isForbiddenHotKey(hotKey: string) {
    const hk = hotKey.toUpperCase();
    if (hk === "TAB" || hk === "SHIFT+TAB") {
      return true;
    }
    return false;
  }

  isShowHeader(): boolean {
    const rect: RectInDock = this.getRectInDock();
    return super.isShowHeader() && rect && rect.FrameStyle < FrameStyle.frsNone;
  }

  public getRectInDock(): RectInDock {
    return this.vr.getRectInDock();
  }

  showView(): Promise<void> {
    if (this.getAutoCloseTime() > 0) {
      this.startAutoClose();
    }

    return Promise.resolve();
  }

  closeView(): Promise<void> {
    return Promise.resolve();
  }

  startAutoClose = () => {
    if (this.getAutoCloseTime() > 0) {
      this.stopAutoClose();
      this.autoCloseTimer = window.setInterval(() => {
        if (this.getAutoCloseTimeRemaining() <= 0) {
          this.callAutoClose();
        }
      }, 1000);
    }
  };

  stopAutoClose = () => {
    if (this.autoCloseTimer) {
      window.clearInterval(this.autoCloseTimer);
      this.autoCloseTimer = null;
    }
  };

  startAutoUpdate() {
    if (this.Ncl.FrgtData.AutoUpdateInterval > 0) {
      this.stopAutoUpdate();
      this.autoUpdateTimer = window.setInterval(() => {
        this.callAutoUpdate();
      }, Math.max(this.Ncl.FrgtData.AutoUpdateInterval, 1000));
    }
  }

  stopAutoUpdate() {
    if (this.autoUpdateTimer) {
      window.clearInterval(this.autoUpdateTimer);
      this.autoUpdateTimer = null;
    }
  }

  public clearExplicitBoundsRequest() {
    this.initDataRequest();
  }

  public moveView(x: number, y: number) {
    this.defaultCommand?.updateState<CSUpdateCommandItem>({ Enabled: true });
    this.updateState<UpdateHeadered>({ isDefaultSizeAndPosition: false });
    this.internalSetEBRequest({ Left: Math.round(x), Top: Math.round(y) });
  }

  public resizeView(height: number, width: number) {
    this.defaultCommand?.updateState<CSUpdateCommandItem>({ Enabled: true });
    this.updateState<UpdateHeadered>({ isDefaultSizeAndPosition: false });
    this.internalSetEBRequest({ Height: Math.round(height), Width: Math.round(width) });
  }

  public maximizeView(value: boolean) {
    this.internalSetEBRequest({ Maximized: value });
  }

  protected initDataRequest() {
    super.initDataRequest();
    this.setEBRequest = null;
  }

  private internalSetEBRequest(value: Partial<ExplicitBounds>) {
    let bounds: Partial<ExplicitBounds>;
    if (!this.setEBRequest) {
      this.setEBRequest = {};
    }

    if (this.vr.isForm()) {
      if (!this.setEBRequest.FormExplicitBounds) {
        this.setEBRequest.FormExplicitBounds = {};
      }
      bounds = this.setEBRequest.FormExplicitBounds;
    } else {
      if (!this.setEBRequest.ExplicitBounds) {
        this.setEBRequest.ExplicitBounds = {};
      }
      bounds = this.setEBRequest.ExplicitBounds;
    }

    Object.assign(this.currentExplicitBounds, value); // save new values for next open
    Object.assign(bounds, value);

    if (this.VCX.Data.Placement?.MaximizeAll === 1) {
      bounds.Maximized = false;
    }
    this.internalSetData(this.setEBRequest, false);
  }

  public getExplicitPosition(): ModalPosition | undefined {
    if (this.currentExplicitBounds && this.currentExplicitBounds.Left + this.currentExplicitBounds.Top > 0) {
      return { x: this.currentExplicitBounds.Left, y: this.currentExplicitBounds.Top };
    }
    return undefined;
  }

  public isMaximized(): boolean {
    if (this.ncl.ExplicitBounds?.Maximized || this.ncl.FormExplicitBounds?.Maximized || this.VCX.Data.Placement?.MaximizeAll == 1) {
      return true;
    }
    return false;
  }

  public getExplicitSize(): Size | undefined {
    if (this.currentExplicitBounds && this.currentExplicitBounds.Height + this.currentExplicitBounds.Width > 0) {
      return { height: this.currentExplicitBounds.Height, width: this.currentExplicitBounds.Width };
    }
    return undefined;
  }

  public defaultExplicitBounds() {
    this.currentExplicitBounds = {};
    this.clearExplicitBoundsRequest();
    this.appendFunction({ Name: cJSonFunctionDefaultExplicitBounds }, true);
  }

  protected computeMinHeight(withMargin: boolean): number {
    let size = super.computeMinHeight(withMargin);

    if (this.content) {
      if (withMargin) {
        size += this.content.ComputedMinHeightWithMargin;
      } else {
        size += this.content.ComputedMinHeight;
      }
    }

    size += this.VCX.Data.MarginY * 2;

    return size;
  }

  calcFullHeight = () => {
    let height = 0;
    this.vr.calcFullHeight = true;
    height = this.ComputedMinHeightWithMargin;
    this.vr.calcFullHeight = false;

    return height;
  };

  public willUnMount(onlyListener: boolean) {
    this.stopAutoUpdate();
    this.stopAutoClose();
    super.willUnMount(onlyListener);
  }
}

export class NclView extends NclViewBase<CSNclViewMetadata, UpdateView> {
  private autoFocusTimer: number | null = null;

  private startAutoFocus() {
    this.stopAutoFocus();
    if (this.state.FocusHolderUID) {
      this.autoFocusTimer = window.setInterval(() => {
        this.callAutoFocus(this.state.FocusHolderUID);
      }, 500);
    }
  }

  stopAutoFocus() {
    if (this.autoFocusTimer) {
      window.clearInterval(this.autoFocusTimer);
      this.autoFocusTimer = null;
    }
  }

  public willUnMount(onlyListener: boolean) {
    this.stopAutoFocus();
    super.willUnMount(onlyListener);
  }

  protected createDefaultState(): UpdateView {
    return new UpdateView({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected afterSetState(canUpdate: boolean, oldState: UpdateView): void {
    super.afterSetState(canUpdate, oldState);
    if (!Context.DeviceInfo.ForceFocusHolder) return;
    if (this.state.Enabled != oldState.Enabled) {
      if (this.state.FocusHolderUID) {
        if (this.state.Enabled) {
          this.startAutoFocus();
        } else {
          this.stopAutoFocus();
        }
        return;
      }
    }
    if (this.state.FocusHolderUID != oldState.FocusHolderUID && this.state.Enabled) {
      this.startAutoFocus();
    }
  }
}

export class NclInplaceView extends NclViewBase<CSNclViewMetadata, UpdateHeadered> {
  protected computeMinHeight(withMargin: boolean): number {
    return this.VCX.GridControl.GetRowHeight(1);
  }

  public isForbiddenHotKey(hotKey: string) {
    const hk = hotKey.toUpperCase();
    if (hk === "TAB" || hk === "SHIFT+TAB") {
      return false;
    }
    return false;
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }
}

export class NclExpander extends NclHeaderedControl<CSNclExpanderMetadata, UpdateExpander> {
  private flowPanel: NclPanel<CSNclPanelMetadata, UFUpdateControl>;

  constructor(ncl: CSNclExpanderMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Content) {
      this.flowPanel = new NclPanel<CSNclPanelMetadata, UFUpdateControl>(ncl.Content, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.flowPanel);
    }
  }

  public get Content(): NclPanel<CSNclPanelMetadata, UFUpdateControl> {
    return this.flowPanel;
  }

  public isLite(): boolean {
    return this.ncl.FrgtData.LiteHeader ? true : false;
  }

  protected internalSetTabStop(value: boolean): void {
    super.internalSetTabStop(value);
    if (this.flowPanel) {
      this.flowPanel.setTabStop(value);
    }
  }

  protected createDefaultState(): UpdateExpander {
    return new UpdateExpander({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected computeMinHeight(withMargin: boolean): number {
    const onCalcFinish = this.forceCalcFullHeight();

    let result = withMargin ? this.Header.ComputedMinHeightWithMargin : this.Header.ComputedMinHeight;

    if (!this.state.Collapsed) {
      result += Math.max(
        this.VCX.MinRowHeight * this.Size,
        this.Content ? (!withMargin ? this.Content.ComputedMinHeight : this.Content.ComputedMinHeightWithMargin) : 0
      );

      result += this.MarginYFactor * this.VCX.Data.MarginY * 2 * 2;
    }

    result += 1; // border-bottom

    onCalcFinish();

    return result;
  }

  public isCollapsed(): boolean {
    return this.state.Collapsed;
  }
}

export class NclPreviewPanel extends NclContainerBase<CSNclPreviewPanelMetadata, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.MetaData.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected computeMinHeight(withMargin: boolean): number {
    let height = super.computeMinHeight(withMargin);

    height += this.VCX.Data.TitleControl.Font.FontSize + 2 * 8; // header + padding

    height += this.VCX.Data.MarginY * 3; // 2 * gap + padding-bottom

    if (withMargin) {
      height += (this.Parts.length - 1) * this.VCX.Data.MarginY * 2;
    }

    return height;
  }
}

export enum EditCheckValueResult {
  default = 0,
  block = 1,
  newValue = 2,
}

export interface CheckResult {
  Value: string;
  ToPosition: number;
  Result: EditCheckValueResult;
}

export class NclInput extends UFNclControl<CSNclInputMetadata, UpdateInput> implements ClientContextMenuEmitor {
  private toolBar: NclToolBar;
  private inPlaceView: boolean;
  private position: number;
  private changedValues: Array<string>;

  constructor(ncl: CSNclInputMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.ToolBar) {
      this.toolBar = new NclToolBar(ncl.ToolBar, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.toolBar);
    }
  }

  appendActionTo(menu: CSNclMenuMetadata): void {
    appendSpecialActionsToMenu(this.state.Text, menu);
  }

  public isDateOrTime() {
    return (
      this.state.InputType === TNclInputType.nitDateTime || this.state.InputType === TNclInputType.nitDate || this.state.InputType === TNclInputType.nitTime
    );
  }

  public isVisibleTitle() {
    if (this.Ncl.FrgtData.ShowLabel) {
      return true;
    }

    return false;
  }

  public get ToolBar(): NclToolBar {
    return this.toolBar;
  }

  public get Position(): number {
    return this.position;
  }

  public set Position(position: number) {
    this.position = position;
  }

  public isEmpty(): boolean {
    switch (this.state.InputType) {
      case TNclInputType.nitFloat:
      case TNclInputType.nitInteger:
        return this.state.Text === "0";
      case TNclInputType.nitDateTime:
        return this.state.Text === "0000-00-00T00:00:00.000" || this.state.Text === "0000-00-00 00:00:00.000";
      case TNclInputType.nitDate:
        return this.state.Text === "0000-00-00";
      case TNclInputType.nitTime:
        return this.state.Text === "00:00:00.000";
      case TNclInputType.nitString:
        return this.state.Text === "";
    }
    return false;
  }

  public updateState<U extends CSUpdateInput>(data: Partial<U>): void {
    if (data.AcceptMode) {
      if (data.AcceptMode === TAcceptMode.amImmediate) {
        this.changedValues = new Array<string>();
      } else {
        this.changedValues = undefined;
      }
    }

    if (this.changedValues?.length > 0 && data.Text !== undefined) {
      const oldTxt = this.changedValues.shift();
      if (data.Text == oldTxt) {
        delete data.Text;
      }
    }

    super.updateState(data);
  }

  public change(value: string, accept = false) {
    if (this.state.ReadOnly) return;

    if (this.state.Text === value) {
      this.initDataRequest();
      return;
    }

    if (this.state.IsUpperCase) {
      value = value.toUpperCase();
    }

    if (this.changedValues) {
      this.changedValues.push(value);
      this.setState(this.state.with({ Text: value }), true);
    }
    this.internalSetData<InputDataRequest>({ Text: value }, accept, true, accept ? RealizerOperations.Accept : RealizerOperations.None);
  }

  public nextPriorValue(next: boolean, value: string) {
    this.appendFunction({ Name: cJSonFunctionNextPriorValue, Args: [next, value] }, true);
  }

  public accept() {
    if (this.state.ReadOnly || !this.IsPreparedDataRequest) return;
    this.vr.sendRequest(RealizerOperations.Accept, this);
  }

  protected createDefaultState(): UpdateInput {
    return new UpdateInput({ ControlUID: this.MetaData.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    let size: number = this.Size;
    size = this.VCX.InputControl.getInputHeight(size, this.ncl.FrgtData.ShowFrame, this.ncl.FrgtData.ShowLabel && this.isVisibleTitle());
    return size;
  }

  public contextMenu() {
    this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }

  public checkInput(value: string, puttingValue: string, selection: InputSelectionText, replaceAll?: boolean): CheckResult {
    const result: CheckResult = { Result: EditCheckValueResult.block, ToPosition: -1, Value: "" };
    const li = Context.getApplication().getLocalInputInfo(this);
    const reg: RegExp | null = li?.regExpr;
    let separator = FormatUtils.getSeparator(this.state.InputType);
    let newValue: string | null = null;
    let puttingLength: number = puttingValue.length;
    let clearReg: RegExp = null;
    let dateTimeSeparator: string;
    let dateTimeSeparatorIndex: number;

    let postfix = "";
    let prefix = "";

    switch (this.state.InputType) {
      case TNclInputType.nitInteger:
      case TNclInputType.nitFloat:
        clearReg = this.getThousandSeparatorByFormat();
        if (puttingValue.length > 1) {
          const match = puttingValue.match(/\.|,/g);
          if (match && match.length > 0 && match[0] !== separator) {
            puttingValue = puttingValue.replace(match[0], separator);
          }
        }
        if ((puttingValue === "." || puttingValue === ",") && puttingValue !== separator) {
          puttingValue = separator;
        }
        if (reg && reg.test(puttingValue)) {
          if (replaceAll === true) {
            newValue = puttingValue;
          } else {
            if (puttingValue === separator && value === "") {
              value = "0"; // automaticke doplneni nuly, pokud zadam jen desetinnou carku
              selection.start += 1;
              selection.end += 1;
            }

            newValue = NclInput.createNewValue(value, puttingValue, selection, separator, clearReg, false);
          }
        }
        break;
      case TNclInputType.nitString:
      case TNclInputType.nitUndefined:
        result.Result = EditCheckValueResult.default;
        return result;
      case TNclInputType.nitDateTime:
        if (!replaceAll && reg && !reg.test(value)) {
          newValue = puttingValue;
          break;
        } else {
          if (puttingValue.length > 2 || selection.start !== selection.end) {
            newValue = value;
          } else {
            dateTimeSeparator = separator; // mezera mezi datem a casem
            dateTimeSeparatorIndex = value.indexOf(dateTimeSeparator);

            if (dateTimeSeparatorIndex > 0) {
              if (selection.start <= dateTimeSeparatorIndex) {
                postfix = ` ${value.substring(dateTimeSeparatorIndex + 1)}`;
                value = value.substring(0, dateTimeSeparatorIndex);
                separator = FormatUtils.getSeparator(TNclInputType.nitDate);
              } else {
                prefix = `${value.substring(0, dateTimeSeparatorIndex)} `;
                value = value.substring(dateTimeSeparatorIndex + 1);
                separator = FormatUtils.getSeparator(TNclInputType.nitTime);

                // jelikoz se dale pouziva spolecna logika pro typ datum nebo cas, posunuji selection, aby se nepracovalo s obema casovymi slozkami
                selection.start -= prefix.length;
                selection.end -= prefix.length;
              }
            } else {
              separator = FormatUtils.getSeparator(TNclInputType.nitDate);
            }
          }
        }
      case TNclInputType.nitDate:
      case TNclInputType.nitTime:
        if (this.state.InputType !== TNclInputType.nitDateTime) {
          if (!replaceAll && reg && !reg.test(value)) {
            replaceAll = true; //pokud hodnota inputu neodpovída regulárnímu výrazu, tak se maže a píše od začátku, jinak se přepisuje
          }
        }

        let putValue: string = puttingValue;
        let skipSeparator = false;
        if (puttingValue.length > 2 && puttingValue.indexOf(separator) < 0) {
          const vals = puttingValue.match(/.{1,2}/g);
          if (vals) {
            putValue = vals.join(separator);
          }
        } else {
          const segments: string[] = value.split(separator);
          if (segments.length > 0) {
            let pos = 0;
            for (let i = 0; i < segments.length; i++) {
              pos += 2;
              if (selection.start === pos) {
                skipSeparator = true;
                break;
              }
              pos++; //separator
              if (selection.start < pos) break;
            }
          }
        }

        puttingLength = putValue.length;

        if (replaceAll === true) {
          newValue = putValue;
        } else {
          newValue = NclInput.createNewValue(value, putValue, selection, separator, clearReg, skipSeparator);

          if (this.state.InputType === TNclInputType.nitDateTime) {
            newValue = `${prefix}${newValue}${postfix}`;
          }
        }

        if (putValue !== separator) {
          let parts = newValue.split(separator);

          if (this.state.InputType === TNclInputType.nitDateTime) {
            // vraci selection na puvodni hodnotu
            if (prefix) {
              selection.start += prefix.length;
              selection.end += prefix.length;
            }

            // extrahuje jen jednu casovou slozku, aby prosla podminka pro automaticke vkladani separatoru
            if (dateTimeSeparatorIndex > -1 && selection.end > dateTimeSeparatorIndex) {
              parts = newValue.split(dateTimeSeparator);
              parts.shift();
              parts = parts[0].split(separator);
            }

            // vlozeni mezery
            if (parts.length === 3 && parts[parts.length - 1].length === 4) {
              newValue += dateTimeSeparator;
              puttingLength += 1;
            }
          }

          // vlozeni separatoru
          if (parts && parts.length < NclInput.getPartsCount(this.State.InputType, this.State.Format, separator) && parts[parts.length - 1].length === 2) {
            newValue += separator;
          }
        }
        break;
      default:
        console.error(`Invalid input type.`);
        break;
    }
    if (!reg || !newValue) return result;
    const checkValue = clearReg !== null ? newValue.replace(clearReg, "") : newValue; //when exist any separator, for check use raw value, but for user use newValue
    if (!checkValue || !reg.test(checkValue)) return result;

    result.Value = newValue;
    result.ToPosition = selection.start + puttingLength;
    result.Result = EditCheckValueResult.newValue;
    this.change(newValue, false); //numeric and date field must set new value to server request, string set in input component onchange event
    return result;
  }

  private static getPartsCount(type: TNclInputType, format: string, separator: string): number {
    if (type != TNclInputType.nitTime || !format || !separator) return 3; // zatim pouze pro time

    return format.split(separator).length;
  }

  private static createNewValue(
    value: string,
    puttingValue: string,
    selection: InputSelectionText,
    separator: string,
    clearReg: RegExp,
    skipSeparator = true
  ): string | null {
    let newValue = value;
    if (selection.start < 0) return null;

    if (!value) return puttingValue;

    const nextSelectionStartIndex = value.indexOf(puttingValue, selection.start);
    if (puttingValue === separator && value.includes(separator) && nextSelectionStartIndex > -1) {
      selection.start = nextSelectionStartIndex;
      selection.end = nextSelectionStartIndex;
    }

    if (selection.start == selection.end) {
      const replaceChar = newValue.substr(selection.start, 1);
      if (replaceChar === puttingValue) return newValue;

      if ((separator === replaceChar && skipSeparator) || (clearReg && replaceChar.match(clearReg))) {
        selection.start += 1; //when replaced char is separators then inc selection position
        selection.end += 1;
      }
      if (separator !== replaceChar || skipSeparator) selection.end += 1;
    }
    newValue = [newValue.slice(0, selection.start), puttingValue, newValue.slice(selection.end)].join("");

    return newValue;
  }

  public getDecimalsRegExpByFormat(): string {
    const decimals = getDecimalsCountByFormat(this.state.Format);
    if (decimals > 0) {
      return `{0,${decimals}}`;
    }

    return "";
  }

  // public getLocaleFormat():string{
  //     switch (this.ncl.InputType) {
  //         case TNclInputType.nitDate:
  //             return this.state.Format.replace(new RegExp('/', 'g'), this.getLocalSeparator()).toUpperCase();
  //         case TNclInputType.nitTime:
  //             return this.state.Format.replace(new RegExp('n', 'g'), 'm').replace(new RegExp(':', 'g'), this.getLocalSeparator());
  //         default:
  //             break;
  //     }
  //     return '';
  // }

  public getClipboardText(text: string): string {
    switch (this.state.InputType) {
      case TNclInputType.nitInteger:
      case TNclInputType.nitFloat:
        return text.split(this.getThousandSeparatorByFormat()).join("");

      default:
        return text;
    }
  }

  public getThousandSeparatorByFormat(): RegExp {
    const n = 1234.5;
    const str = n.toLocaleString();
    if (str.length === 6) return null;
    const sep = str.substring(1, 2);
    if (sep.charCodeAt(0) === 160 || sep.charCodeAt(0) === 32) {
      return /\s/g;
    } else return new RegExp(sep, "g");
  }

  protected get InPlaceView(): boolean {
    if (this.inPlaceView === undefined && this.vr.getRoot()) {
      this.inPlaceView = this.vr.getRoot() instanceof NclInplaceView;
    }

    return this.inPlaceView;
  }

  get MarginXFactor(): number {
    return this.InPlaceView ? 0 : 1;
  }

  get MarginYFactor(): number {
    return this.InPlaceView ? 0 : 1;
  }
}

export class NclKeyboardInput extends UFNclControl<CSNclKeyboardInputMetadata, UpdateKeyboardInput> implements ClientContextMenuEmitor {
  constructor(ncl: CSNclKeyboardInputMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
  }

  appendActionTo(menu: CSNclMenuMetadata): void {
    appendSpecialActionsToMenu(this.state.Text, menu);
  }

  public isEmpty(): boolean {
    return this.state.Text === "";
  }

  public change(value: string, accept = false) {
    if (this.state.ReadOnly) return;

    if (this.state.Text === value) {
      this.initDataRequest();
      return;
    }

    this.internalSetData<KeyboardInputDataRequest>({ KeyboardInput: value }, accept, true, accept ? RealizerOperations.Accept : RealizerOperations.None);
  }

  public accept() {
    if (this.state.ReadOnly || !this.IsPreparedDataRequest) return;
    this.vr.sendRequest(RealizerOperations.Accept, this);
  }

  protected createDefaultState(): UpdateKeyboardInput {
    return new UpdateKeyboardInput({ ControlUID: this.MetaData.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export abstract class NclImageBase<T extends CSNclImageMetadata, S extends UpdateImage> extends UFNclControl<T, S> {}

interface ImageItem {
  prefix: string;
  isStatic: boolean;
  extension?: string;
  folder?: string;
  isSvg?: boolean;
  withsize?: boolean;
  language?: boolean;
}

interface ImageAddressResult {
  url: string;
  altUrl?: string;
}

export const InlineSVGGlyphsPattern = "^svg\\*";
const autoCompanyPrefix = "auto*company";
const GlyphIdParam = "glyphId=";
const RequiredSizeParam = "requiredSize=";
const sizes: number[] = [16, 24, 32, 48, 64, 256];
export class NclImage extends UFNclControl<CSNclImageMetadata, UpdateImage> {
  private static repository: Map<string, ImageItem>;
  private static dynamicGlyphsPattern: string;
  private static svgGlyphsPattern: string;
  private static allGlpyhpsWithSize: string;
  private static allGlyphsPattern: string;

  public static getRepository(): Map<string, ImageItem> {
    if (!NclImage.repository) {
      const map = new Map<string, ImageItem>();
      map.set("chr", { prefix: "chr", isStatic: true, folder: "chr", extension: ".png", isSvg: false, withsize: true });
      map.set("zoo", { prefix: "zoo", isStatic: true, folder: "zoo", extension: ".png", isSvg: false, withsize: true });
      map.set("mui", { prefix: "mui", isStatic: true, folder: "mui", extension: ".png", isSvg: false, withsize: true });
      map.set("ui", { prefix: "ui", isStatic: true, folder: "ui", extension: ".png", isSvg: false, withsize: true });

      map.set("wicon", { prefix: "wicon", isStatic: true, folder: "wicon", extension: ".svg", isSvg: true, withsize: false });
      map.set("stdbmp", { prefix: "stdbmp", isStatic: true, folder: "Standard.bmp", extension: ".bmp", isSvg: false, withsize: false });
      map.set("wui", { prefix: "wui", isStatic: true, folder: "wui", extension: ".svg", isSvg: true, withsize: false });
      map.set("flags", { prefix: "flags", isStatic: true, folder: "flags", extension: ".svg", isSvg: true, withsize: false });
      map.set("wuil", { prefix: "wuil", isStatic: true, folder: "wuil", extension: ".svg", isSvg: true, withsize: false, language: true });
      //dynamic
      map.set("svgl", { prefix: "svgl", isStatic: false, isSvg: true });
      map.set("svgch", { prefix: "svgch", isStatic: false, isSvg: true });
      map.set("k2", { prefix: "k2", isStatic: false });
      NclImage.repository = map;
    }

    return NclImage.repository;
  }

  private static getDynamicGlyphsPattern(): string {
    if (!NclImage.dynamicGlyphsPattern) {
      let str = "";
      this.getRepository().forEach((value) => {
        if (!value.isStatic) {
          if (str != "") {
            str += `|`;
          }
          str += `^${value.prefix}\\*`;
        }
      });
      NclImage.dynamicGlyphsPattern = str;
    }
    return NclImage.dynamicGlyphsPattern;
  }

  private static getSVGGlyphsPattern(): string {
    if (!NclImage.svgGlyphsPattern) {
      let str = "";
      this.getRepository().forEach((value) => {
        if (value.isSvg) {
          if (str != "") {
            str += `|`;
          }
          str += `^${value.prefix}\\*`;
        }
      });
      NclImage.svgGlyphsPattern = str;
    }
    return NclImage.svgGlyphsPattern;
  }

  private static getAllGlpyhpsWithSize(): string {
    if (!NclImage.allGlpyhpsWithSize) {
      let str = "";
      this.getRepository().forEach((value) => {
        if (value.withsize) {
          if (str != "") {
            str += `|`;
          }
          str += `^${value.prefix}\\*`;
        }
      });
      NclImage.allGlpyhpsWithSize = str;
    }
    return NclImage.allGlpyhpsWithSize;
  }

  private static getAllGlyphsPattern(): string {
    if (!NclImage.allGlyphsPattern) {
      let str = "";
      this.getRepository().forEach((value) => {
        if (str != "") {
          str += `|`;
        }
        str += `^${value.prefix}\\*`;
      });
      NclImage.allGlyphsPattern = str;
    }
    return NclImage.allGlyphsPattern;
  }

  private static isDynamicImg(value: GlyphId): boolean {
    if (value) {
      const re = new RegExp(NclImage.getDynamicGlyphsPattern());

      if (re.exec(value)) {
        return true;
      }
    }

    return false;
  }

  public static isSVG(value: GlyphId): boolean {
    if (value) {
      const re = new RegExp(NclImage.getSVGGlyphsPattern() + "|" + InlineSVGGlyphsPattern);

      if (re.exec(value)) {
        return true;
      }
    }

    return false;
  }

  public static isInlineSVG(value: GlyphId): boolean {
    if (value) {
      const re = new RegExp(InlineSVGGlyphsPattern);

      if (re.exec(value)) {
        return true;
      }
    }

    return false;
  }

  private static encodeGlyphId(value: string): string {
    if (!value) return value;
    if (value.startsWith(autoCompanyPrefix)) {
      value = value.replace(autoCompanyPrefix, `${autoCompanyPrefix}(${Context.getApplication().getCompanyID()})`);
    }

    return encodeURIComponent(value);
  }

  public static getSourceUrl(value: GlyphId, requiredSize?: number, forceDynamicMethod = false): ImageAddressResult {
    if (!value) return undefined;
    if (value.includes("\\")) {
      value = value.split("\\").join("/");
    }

    let url: string;
    let altUrl: string;

    if (forceDynamicMethod || NclImage.isDynamicImg(value)) {
      url = `${Context.getApplication().getPictureServerUrl(false)}${GlyphIdParam}${NclImage.encodeGlyphId(value)}`;
      const rSize: number = NclImage.correctRequiredSize(requiredSize, value);
      if (rSize) {
        url += "&" + RequiredSizeParam + rSize;
      }
    } else {
      const rSize: number = NclImage.correctRequiredSize(requiredSize, value);
      const sizeFolder: string = rSize ? `${rSize}/` : "";
      const strs = value.split("*");
      if (strs.length > 1) {
        const item = NclImage.getRepository().get(strs[0]);
        if (item) {
          const lngFolder = item.language == true ? `${Context.getApplication().getCurrentK2LanguageImgFolder()}/` : "";
          url = `${Context.getApplication().getPictureServerUrl(true)}/${lngFolder}${item.folder}/${sizeFolder}${NclImage.encodeGlyphId(strs[1])}`;
          if (item.language === true) {
            altUrl = `${Context.getApplication().getPictureServerUrl(true)}/${DEFAULT_LANG_IMG}/${item.folder}/${sizeFolder}${NclImage.encodeGlyphId(strs[1])}`;
          }

          if (!url.endsWith(item.extension)) {
            url += item.extension;
          }
        } else {
          return NclImage.getSourceUrl(value, requiredSize, true);
        }
      } else {
        return NclImage.getSourceUrl(value, requiredSize, true);
      }
    }

    return { url: url, altUrl: altUrl };
  }

  private static correctRequiredSize(requiredSize: number, value: GlyphId): number {
    let result: number;
    if (requiredSize) {
      if (value.match(NclImage.getAllGlpyhpsWithSize())) {
        for (let index = sizes.length - 1; index >= 0; index--) {
          result = sizes[index];
          if (result <= requiredSize) {
            break;
          }
        }
      }
    }

    return result;
  }

  public static IsStaticGlyphId(value: string): boolean {
    return !NclImage.isDynamicImg(value);
  }

  public get MarginXFactor(): number {
    return 0;
  }

  public get MarginYFactor(): number {
    return 0;
  }

  protected internalComputeMinHeight(): number {
    return Math.max(this.VCX.InputControl.getInputHeight(1, true, false), this.VCX.LabelControl.getHeight(this.Size));
  }
  protected createDefaultState(): UpdateImage {
    return new UpdateImage({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclFilePreview extends UFNclControl<CSNclFilePreview, UpdateFilePreview> {
  protected createDefaultState(): UpdateFilePreview {
    return new UpdateFilePreview({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclGroupBox extends NclContainerBase<CSNclGroupBoxMetaData, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected computeMinHeight(withMargin: boolean): number {
    const onCalcFinish = this.forceCalcFullHeight();

    let result = super.computeMinHeight(withMargin);
    result += this.VCX.ExpanderControl.GetHFHeight(); // Výška dle headerfontu
    result += this.VCX.Data.MarginY * 2; // Výška odsazení headeru

    if (withMargin) {
      result += (this.Parts.length - 1) * this.VCX.Data.MarginY * 2;
    }

    onCalcFinish();

    return result;
  }
}

export class NclSpace extends UFNclControl<CSUFNclControlMetadata, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    if (this.Ncl.FrgtData.Size === 0) return 0;

    return this.VCX.LabelControl.getHeight(this.Size);
  }
}

export class NclTabToolBar extends UFNclControl<CSNclTabToolBarMetadata, UFUpdateControl> {
  private toolBars: Array<NclToolBar>;

  constructor(ncl: CSNclTabToolBarMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.ToolBars) {
      this.toolBars = Array<NclToolBar>();
      ncl.ToolBars.map((bar) => {
        const tb = new NclToolBar(bar, this, this.vr, this.vrAttachDetachFce);
        this.toolBars.push(tb);
        this.addNestedControl(tb);
      });
    }
  }

  public get ToolBars(): Array<NclToolBar> {
    return this.toolBars;
  }

  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.sizeMap(45) + this.Size * this.VCX.sizeMap(15);
    return this.VCX.InputControl.getInputHeight(this.Size * 0.7, false, true) * 1.2;
  }
}

function getParentName(parent: NclControlBase): string {
  while (parent) {
    if (parent.MetaData.Name) {
      return parent.MetaData.Name;
    }
    parent = parent.Parent;
  }
  return "X_";
}

export class NclToolBar extends NclControl<CSNclToolBarMetadata, UpdateControl> {
  private actions: Array<UFNclControlBase>;
  private menuBtn: NclCommandItem;
  private hasMainMenu: boolean;

  constructor(ncl: CSNclToolBarMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Actions) {
      const parentName: string = getParentName(parent);
      this.actions = new Array<UFNclControlBase>();
      ncl.Actions.map((action) => {
        action.Name = action.Name.startsWith("_") ? parentName + action.Name : `${parentName}_${action.Name}`;
        const act = NclContainer.createControl(action, this, vr, this.vrAttachDetachFce);
        if (act) {
          this.addNestedControl(act);
          this.actions.push(act);
        }
      });
    }
    if (ncl.MenuBtn) {
      this.menuBtn = NclContainer.createControl(ncl.MenuBtn, this, vr, vrAttachDetachFce) as NclCommandItem;
      this.addNestedControl(this.menuBtn);
    }
  }

  removeAction(cmdSuffix: string) {
    const index = this.actions.findIndex((value) => {
      return value.MetaData.Name.endsWith(cmdSuffix);
    });
    if (index >= 0) {
      this.actions.splice(index, 1);
    }
  }

  public get HasMainMenu(): boolean {
    if (this.hasMainMenu === undefined) {
      if (this.menuBtn) {
        if (this.parent instanceof NclTabToolBar) {
          this.hasMainMenu = true;
        } else {
          this.hasMainMenu = false;
        }
      } else {
        this.hasMainMenu = false;
      }
    }

    return this.hasMainMenu;
  }

  public get Actions(): Array<UFNclControlBase> {
    return this.actions;
  }

  public get MenuBtn(): NclCommandItem {
    return this.menuBtn;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.InputControl.getEditHeight(1);
  }

  public get MarginXFactor(): number {
    return 0;
  }

  public get MarginYFactor(): number {
    return 0;
  }
}

export class NclActionSeparator extends UFNclControl<CSUFNclControlMetadata, UFUpdateControl> {
  constructor(ncl: CSUFNclControlMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    ncl.Bounds.Align = Align.Client; ///
    super(ncl, parent, vr, vrAttachDetachFce);
  }
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    // let size = this.VCX.InputControl.getInputHeight(this.Size, true, false);
    // size += this.VCX.LabelControl.getHeight(1);
    return 1;
  }

  public get MarginYFactor(): number {
    return 0;
  }
}

export class NclFloaterAccessor extends NclContainerBase<CSNclFloaterAccessorMetadata, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public show() {
    this.appendFunction({ Name: cJsonFunctionShowFloater }, true);
  }

  hide() {
    this.appendFunction({ Name: cJsonFunctionHideFloater }, true);
  }

  get MarginYFactor(): number {
    return 1;
  }

  get MarginXFactor(): number {
    return 1;
  }

  protected computeMinHeight(withMargin: boolean): number {
    let minHeight = 0;

    if (this.Ncl.Bounds.Align === Align.Top || this.Ncl.Bounds.Align === Align.Bottom) {
      minHeight = this.VCX.InputControl.getInputHeight(this.Size, true, false);
    } else {
      minHeight = this.VCX.LabelControl.getHeight(this.Size);
    }

    return minHeight;
  }
}

export class NclFloaterView extends NclViewBase<CSNclFloaterViewMetadata, UpdateHeadered> {
  private okCommand: NclCommandItem;

  constructor(ncl: CSNclFloaterViewMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Header) {
      this.okCommand = new ClientNclCommandItem(
        "",
        "wui*ok",
        this,
        this.okExecute,
        this,
        true,
        { viewRealizer: vr, attachDettachFce: vrAttachDetachFce },
        ClientActionNames.OKCOMMAND
      );
      this.Header.RQuickButtons.push(this.okCommand);
    }
  }
  protected setState(newState: UpdateHeadered, canUpdate: boolean) {
    if (newState !== this.state) {
      if (this.state.Visible !== newState.Visible) {
        if (newState.Visible) {
          ViewRealizer.getNearestListener(this.vr).showModal(this.vr.getRealizerUID(), this.MetaData.ControlUID);
        } else {
          this.Content.willUnMount(true); //odpojeni ncl od komponenty, protoze pokud se pri zavirani dela animace tak behem ni se nastavi celemu conentu visible na false a okno zmizi. cast ncl je stale aktivni a viditelna cast stejne konci.
          this.closeView().then(async () => {
            await ViewRealizer.getNearestListener(this.vr).closeModal(this.vr.getRealizerUID(), this.MetaData.ControlUID);
            super.setState(newState, canUpdate); // mohu nastavit state az po zavreni, kvuli animaci
          });
          return;
        }
      }
    }
    super.setState(newState, canUpdate);
  }

  public closeRequest() {
    this.hide();
  }

  private okExecute(ctrl: ClientNclCommandItem) {
    this.hide();
  }

  hide() {
    if (this.parent instanceof NclFloaterAccessor) {
      this.parent.hide();
    }
  }

  public getRectInDock(): RectInDock {
    return this.Ncl.RectInDock;
  }
}

export class NclOpenDialog extends NclViewBase<CSNclOpenDialogMetadata, UpdateHeadered> {
  private ok: NclButton;
  private cancel: NclButton;

  constructor(ncl: CSNclOpenDialogMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.OKBtn) {
      this.ok = new NclButton(ncl.OKBtn, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.ok);
    }

    if (ncl.CancelBtn) {
      this.cancel = new NclButton(ncl.CancelBtn, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.cancel);
    }
  }

  public get OK(): NclButton {
    return this.ok;
  }

  public get Cancel(): NclButton {
    return this.cancel;
  }

  public acceptFiles(files: Array<CSFile>) {
    if (files && files.length > 0) {
      this.internalSetData<OpenDialogDataRequest>({ Files: files }, false);
    }
  }

  protected createDefaultState(): UpdateHeadered {
    return new UpdateHeadered({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected computeMinHeight(withMargin: boolean): number {
    let result = super.computeMinHeight(withMargin);
    result += this.VCX.LabelControl.getHeight(12); // Výška file area
    return result;
  }
}

export abstract class NclEmptyViewDialog<T extends CSNclViewMetadata, S extends UpdateHeadered> extends NclViewBase<T, S> {
  protected abstract internalAfterViewUpdated(): any;

  afterViewUpdated() {
    this.internalAfterViewUpdated();
  }
}

export class NclSilentOpenDialog extends NclViewBase<CSNclOpenDialogMetadata, UpdateHeadered> {
  private contnet: NclSilentOpenDialogContent;

  constructor(ncl: CSNclOpenDialogMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Content) {
      this.contnet = new NclSilentOpenDialogContent(ncl.Content as CSNclPanelMetadata, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.contnet);
    }
  }

  public get Content(): NclControlBase {
    return this.contnet;
  }

  isShowHeader(): boolean {
    return false;
  }
}

export class NclSilentOpenDialogContent extends NclPanel<CSNclPanelMetadata, UpdateOpenDialogContent> {
  private repeatBtn: ClientNclButton;
  private closeBtn: ClientNclButton;

  constructor(ncl: CSNclPanelMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.repeatBtn = new ClientNclButton("", "wui*node.open", this, undefined, this, true, { viewRealizer: vr, attachDettachFce: vrAttachDetachFce });
    this.closeBtn = new ClientNclButton("", "wui*close", this, this.handleClose, this, true, { viewRealizer: vr, attachDettachFce: vrAttachDetachFce });
  }

  protected createDefaultState(): UpdateOpenDialogContent {
    return new UpdateOpenDialogContent({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public get RepeatBtn(): ClientNclButton {
    return this.repeatBtn;
  }

  public get CloseBtn(): NclControlBase {
    return this.closeBtn;
  }

  private handleClose = (ctrl: ClientNclButton) => {
    if (!ctrl) return;
    const __this = ctrl.Parent as NclSilentOpenDialogContent;
    if (__this) {
      __this.appendFunction({ Name: cJSonFunctionCloseView }, true);
    }
  };
  public beginUpload(expectedFilesCount: number) {
    this.appendFunction({ Name: cJSonFunctionODBeginUploadFile, Args: [expectedFilesCount] }, true);
  }

  public endUpload() {
    this.appendFunction({ Name: cJSonFunctionODEndUploadFile, Args: undefined }, true);
  }

  public cancelUpload() {
    this.appendFunction({ Name: cJSonFunctionODCancelUploadFile, Args: undefined }, true);
  }

  public uploadfile(file: CSFile) {
    if (!file) return;
    this.internalSetData<SilentOpenDialogDataRequest>({ File: file }, true);
  }
}

export class NclDeviceConnectorDialog extends NclEmptyViewDialog<CSNclDeviceConnectorDialogMetadata, UpdateHeadered> {
  protected internalCanUpdate(newState: UpdateHeadered): boolean {
    if (newState.Visible != this.state.Visible) {
      return true;
    }
    return false;
  }

  private result(result: Partial<DeviceConnectorDataRequest>) {
    this.internalSetData(result, true);
  }

  protected internalAfterViewUpdated() {
    if (this.state.Visible === true) {
      Context.getApplication().BusyIndicator.setBusyIndicator(true, __("loadingPleaseWait") + "...", __("deviceConnectorCommunication"), false);
      if (!Context.DeviceConnector.isConnected) {
        if (!this.Ncl.DeviceData.RequestMessage && !this.Ncl.DeviceData.DisconnectRequest) {
          Context.DeviceConnector.connect(this.Ncl.DeviceData.ConnectionString)
            .then((value) => {
              Context.getApplication().BusyIndicator.setBusyIndicator(false);
              this.result(value);
            })
            .catch((reason) => {
              Context.getApplication().BusyIndicator.setBusyIndicator(false);
              this.result({ ResultType: TDeviceConnectResult.dcrError, ResultMessage: reason });
            });
        } else {
          Context.getApplication().BusyIndicator.setBusyIndicator(false);
          this.result({
            ResultType: TDeviceConnectResult.dcrError,
            ResultMessage: __("deviceConnectorDisconnected"),
          });
        }
      } else {
        if (this.Ncl.DeviceData.DisconnectRequest == true) {
          Context.DeviceConnector.disconnect()
            .then((value) => {
              Context.getApplication().BusyIndicator.setBusyIndicator(false);
              this.result(value);
            })
            .catch((reason) => {
              Context.getApplication().BusyIndicator.setBusyIndicator(false);
              this.result({ ResultType: TDeviceConnectResult.dcrError, ResultMessage: reason });
            });
        } else if (this.Ncl.DeviceData.RequestMessage) {
          Context.DeviceConnector.sendRequest(this.Ncl.DeviceData.RequestMessage)
            .then((value) => {
              Context.getApplication().BusyIndicator.setBusyIndicator(false);
              this.result(value);
            })
            .catch((reason) => {
              Context.getApplication().BusyIndicator.setBusyIndicator(false);
              this.result({ ResultType: TDeviceConnectResult.dcrError, ResultMessage: reason });
            });
        } else {
          Context.getApplication().BusyIndicator.setBusyIndicator(false);
        }
      }
    }
  }
}

export class NclGPSDialog extends NclEmptyViewDialog<CSNclGPSDialogMetadata, UpdateHeadered> {
  protected internalAfterViewUpdated() {
    if (navigator.geolocation) {
      const gpsReader: GPSReader = Context.getApplication().getGPSReader();
      switch (this.Ncl.FrgtData.Request) {
        case TGPSRequest.gpsrStart:
          try {
            gpsReader.startWatchCurrentPosition(this.ncl.FrgtData.Accuracy);
            this.callCommand(cmdOKView);
          } catch (er) {
            this.sendResult({ GPSErrMsg: er.message });
          }
          break;
        case TGPSRequest.gpsrCheck:
          if (gpsReader.isWatching()) {
            gpsReader.acquireActualPosition(this.sendResult.bind(this), this.ncl.FrgtData.Accuracy);
          } else {
            this.sendResult({ GPSErrMsg: __("gpsIsNotTurnedOn") });
          }
          break;
        case TGPSRequest.gpsrStop:
          if (gpsReader.isWatching()) {
            try {
              gpsReader.stopWatchCurrentPosition();
              this.callCommand(cmdOKView);
            } catch (er) {
              this.sendResult({ GPSErrMsg: er.message });
            }
            this.sendResult({ GPSErrMsg: undefined });
          } else {
            this.sendResult({ GPSErrMsg: __("gpsIsNotTurnedOn") });
          }
          break;
        default:
          this.sendResult({ GPSErrMsg: `${__("gpsUnknownRequest")} (${this.ncl.FrgtData.Request})` });
          break;
      }
    } else {
      this.sendResult({ GPSErrMsg: __("gpsBrowserError") });
    }
  }

  private sendResult(request: Partial<GPSDataRequest>) {
    this.internalSetData(request, true);
  }
}

export class NclVirtualKeyboardDialog extends NclViewBase<CSNclVirtualKeyboardDialogMetadata, UpdateVirtualKeyboard> {
  private keyControls: Map<string, UFNclControlBase>;
  private input: ClientNclInput;

  public static NUM_CLEAR = "num-clear";
  public static NUM_CLEAR_ALL = "num-clear-all";
  public static OK = "OK";
  public static CANCEL = "CANCEL";
  public static INPUT = "INPUT";
  public static NUM = "num";
  public static UPPER_CASE = "upper-case";
  public static LOWER_CASE = "lower-case";
  public static ALPHABET = "alphabet";

  constructor(ncl: CSNclVirtualKeyboardDialogMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.generateNclControls();
  }

  protected createDefaultState(): UpdateVirtualKeyboard {
    return new UpdateVirtualKeyboard({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  private generateNclControls() {
    this.keyControls = new Map<string, UFNclControlBase>();

    this.input = new ClientNclInput(this.ncl.FrgtData.MainMessage, ``, this, true, { attachDettachFce: this.vrAttachDetachFce, viewRealizer: this.vr }, 200);

    this.keyControls.set(NclVirtualKeyboardDialog.INPUT, this.input);

    //numeric
    for (let i = 0; i < 10; i++) {
      //this.appendKeyBtn(i.toString(), `wui*number.${i.toString()}`, this.handleKeyExecute);
      this.appendKeyBtn(i.toString(), `svgch*${i.toString()}`, this.handleKeyExecute);
    }

    this.appendKeyBtn(",", `wui*number.decimalpoint`, (ctrl) => {
      const __this = ctrl.Parent as NclVirtualKeyboardDialog;
      if (__this && __this.input) {
        if (this.Ncl.FrgtData.IsNumeric) {
          if (__this.input.Text == "") this.input.pressKey(`0` + (ctrl.State as CSUpdateCommandItem).Title);
          if (__this.input.Text.indexOf(",") < 0) {
            this.input.pressKey((ctrl.State as CSUpdateCommandItem).Title);
          }
        } else {
          this.input.pressKey((ctrl.State as CSUpdateCommandItem).Title);
        }
      }
    });

    this.appendKeyBtn(NclVirtualKeyboardDialog.NUM_CLEAR, `wui*number.clear`, (ctrl) => {
      const __this = ctrl.Parent as NclVirtualKeyboardDialog;
      if (__this && __this.input) {
        __this.input.pressKey("");
      }
    });

    this.appendKeyBtn(NclVirtualKeyboardDialog.NUM_CLEAR_ALL, `wui*number.clear.all`, (ctrl) => {
      const __this = ctrl.Parent as NclVirtualKeyboardDialog;
      if (__this && __this.input && __this.input.Listener) {
        this.input.updateState<CSUpdateInput>({ Text: "" });
        this.input.Position = 0;
      }
    });

    if (!this.Ncl.FrgtData.IsNumeric) {
      //qwerty
      let alphabet = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < alphabet.length; i++) {
        this.appendKeyBtn(alphabet[i], `wui*letter.${alphabet[i]}2`, this.handleKeyExecute);
      }

      alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for (let i = 0; i < alphabet.length; i++) {
        this.appendKeyBtn(alphabet[i], `wui*letter.${alphabet[i]}`, this.handleKeyExecute);
      }

      this.appendKeyBtn(NclVirtualKeyboardDialog.NUM, `wui*numbers_key`, (ctrl) => {
        this.setAlphabetMode(AlphabetMode.numeric);
      });

      this.appendKeyBtn(NclVirtualKeyboardDialog.ALPHABET, `wui*letters_key`, (ctrl) => {
        this.setAlphabetMode(AlphabetMode.unknown);
      });

      this.appendKeyBtn(NclVirtualKeyboardDialog.UPPER_CASE, `wui*caps_lock`, (ctrl) => {
        this.setAlphabetMode(AlphabetMode.upperCase);
      });

      this.appendKeyBtn(NclVirtualKeyboardDialog.LOWER_CASE, `wui*caps_lock_off`, (ctrl) => {
        this.setAlphabetMode(AlphabetMode.unknown);
      });

      this.appendKeyBtn("=", `svgch*=`, this.handleKeyExecute);
      this.appendKeyBtn(" ", ``, this.handleKeyExecute);
      this.appendKeyBtn("/", `svgch*/`, this.handleKeyExecute);
      this.appendKeyBtn("?", `svgch*?`, this.handleKeyExecute);
      this.appendKeyBtn("!", `svgch*!`, this.handleKeyExecute);
      this.appendKeyBtn("@", "svgch*@", this.handleKeyExecute);
      this.appendKeyBtn("#", "svgch*#", this.handleKeyExecute);
      this.appendKeyBtn("$", "svgch*$", this.handleKeyExecute);
      this.appendKeyBtn("%", "svgch*%", this.handleKeyExecute);
      this.appendKeyBtn("&", "svgch*&", this.handleKeyExecute);
      this.appendKeyBtn("-", "svgch*-", this.handleKeyExecute);
      this.appendKeyBtn("+", "svgch*+", this.handleKeyExecute);
      this.appendKeyBtn("(", "svgch*(", this.handleKeyExecute);
      this.appendKeyBtn(")", "svgch*)", this.handleKeyExecute);
      this.appendKeyBtn("*", "svgch**", this.handleKeyExecute);
      this.appendKeyBtn(`"`, `svgch*"`, this.handleKeyExecute);
      this.appendKeyBtn("'", "svgch*'", this.handleKeyExecute);
      this.appendKeyBtn(":", "svgch*:", this.handleKeyExecute);
      this.appendKeyBtn(";", "svgch*;", this.handleKeyExecute);
      this.appendKeyBtn(".", "svgch*.", this.handleKeyExecute);
      this.appendKeyBtn("_", "svgch*_", this.handleKeyExecute);
    }

    this.appendKeyBtn(NclVirtualKeyboardDialog.OK, `wui*OK`, this.executorCallback(null));

    this.appendKeyBtn(NclVirtualKeyboardDialog.CANCEL, `wui*close`, (ctrl) => {
      const vr = ViewRealizerManager.getViewRealizer(ctrl.getRealizerUID());
      if (vr) vr.closeRequest();
    });
  }

  public executorCallback = (value: string) => (ctrl: ClientNclButton) => {
    const vr = ViewRealizerManager.getViewRealizer(ctrl.getRealizerUID());
    if (vr) {
      const __this = vr.getRoot() as NclVirtualKeyboardDialog;
      if (__this) {
        __this.internalSetData<VirtualKeyboardRequest>({ InputText: value == null ? __this.input.Text : value }, false);
        __this.callCommand(cmdOKView);
      }
    }
  };

  protected internalSetVCX(vcx: VisualContext, forceRefresh: boolean) {
    super.internalSetVCX(vcx, forceRefresh);
    if (this.keyControls) {
      this.keyControls.forEach((value) => {
        value?.setVCX(vcx, forceRefresh);
      });
    }
  }

  private handleKeyExecute = (ctrl: ClientNclButton) => {
    const __this = ctrl.Parent as NclVirtualKeyboardDialog;
    if (__this && __this.input) {
      this.input.pressKey((ctrl.State as CSUpdateCommandItem).Title);
    }
  };

  private appendKeyBtn(key: string, glyphId: GlyphId, executor: ClientExecuteFce<ClientNclButton>) {
    if (this.keyControls.has(key)) return;
    this.keyControls.set(
      key,
      new ClientNclButton(key, glyphId, this, executor, this, true, { attachDettachFce: this.vrAttachDetachFce, viewRealizer: this.vr })
    );
  }

  private setAlphabetMode(value: AlphabetMode) {
    this.updateState<CSUpdateVirtualKeyboard>({ Mode: value });
  }

  public getAlphabetMode(): AlphabetMode {
    return this.state.Mode;
  }

  public getKeyControlByValue(value: string) {
    return this.keyControls.get(value);
  }

  protected afterSetState(canUpdate: boolean, oldState: UpdateVirtualKeyboard) {
    if (this.input && this.state.OldValue !== (this.input.State as UpdateInput).SuffixText) {
      this.input.updateState<CSUpdateInput>({ SuffixText: this.state.OldValue });
    }

    super.afterSetState(canUpdate, oldState);
  }
}

export class NclCodeReaderDialog extends NclViewBase<CSNclCodeReaderDialogMetadata, UpdateHeadered> {
  private result: string;
  private ok: ClientNclButton;
  private cancel: ClientNclButton;

  constructor(ncl: CSNclCodeReaderDialogMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.generateNclControls();
  }

  public get OK(): ClientNclButton {
    return this.ok;
  }

  public get CANCEL(): ClientNclButton {
    return this.cancel;
  }

  public setResult(code: string) {
    if (code && code !== this.result) {
      this.result = code;

      if (this.ncl.FrgtData.ImmediatelyAccept) {
        this.acceptAndClose(code);
        return;
      }
      this.ok.updateState({ Enabled: true });
    }
  }

  private acceptAndClose(value: string) {
    this.internalSetData<CodeReaderRequest>({ Result: value }, false);
    this.callCommand(cmdOKView);
  }

  private generateNclControls() {
    this.ok = new ClientNclButton(
      NclVirtualKeyboardDialog.OK,
      "wui*OK",
      this,
      (ctrl) => {
        const vr = ViewRealizerManager.getViewRealizer(ctrl.getRealizerUID());
        if (vr) {
          const __this = vr.getRoot() as NclCodeReaderDialog;
          if (__this) {
            __this.acceptAndClose(__this.result);
          }
        }
      },
      this,
      false,
      { attachDettachFce: this.vrAttachDetachFce, viewRealizer: this.vr }
    );

    this.addNestedControl(this.ok);

    this.cancel = new ClientNclButton(
      NclVirtualKeyboardDialog.CANCEL,
      `wui*close`,
      this,
      (ctrl) => {
        const vr = ViewRealizerManager.getViewRealizer(ctrl.getRealizerUID());
        if (vr) vr.closeRequest();
      },
      this,
      true,
      { attachDettachFce: this.vrAttachDetachFce, viewRealizer: this.vr }
    );
    this.addNestedControl(this.cancel);
  }

  protected createDefaultState(): UpdateHeadered {
    return new UpdateHeadered({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclWebView extends UFNclControl<CSNclWebViewMetadata, UpdateWebView> {
  protected createDefaultState(): UpdateWebView {
    return new UpdateWebView({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.sizeMap(360);
  }
}

export class NclInstantFilterPanel extends UFNclControl<CSNclInstantFilterPanelMetadata, UpdateInstantFilterPanel> {
  protected createDefaultState(): UpdateInstantFilterPanel {
    return new UpdateInstantFilterPanel({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  execute(commandNumber: number) {
    if (this.parent instanceof NclDataGrid) {
      this.parent.executeCommandByNumber_InstantFilter(commandNumber);
    }
  }
}

export class NclFormattableInput extends UFNclControl<CSNclFormattableInputMetadata, UpdateFormattableInput> {
  private toolBar: NclToolBar;

  constructor(ncl: CSNclFormattableInputMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.ToolBar) {
      this.toolBar = new NclToolBar(ncl.ToolBar, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.toolBar);
    }
  }

  public get ToolBar(): NclToolBar {
    return this.toolBar;
  }

  protected createDefaultState(): UpdateFormattableInput {
    return new UpdateFormattableInput({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalInEditMode(): boolean {
    return !this.state.ReadOnly;
  }

  public change = (value: string, accept = false) => {
    if (this.state.ReadOnly) return;

    if (this.state.Text === value) {
      this.initDataRequest();
      return;
    }

    this.internalSetData<InputDataRequest>({ Text: value }, accept, true, accept ? RealizerOperations.Accept : RealizerOperations.None);
  };

  public isVisibleTitle() {
    if (this.Ncl.FrgtData.ShowLabel) {
      return true;
    }

    return false;
  }

  protected internalComputeMinHeight(): number {
    let size: number = this.Size;
    size = this.VCX.InputControl.getInputHeight(size, this.ncl.FrgtData.ShowFrame, this.ncl.FrgtData.ShowLabel && this.isVisibleTitle());
    return size;
  }

  // TODO if defHtml tak projít kontrolou a očistit od HTML kódu
}

export class NclDynamicContent extends NclContainerBase<CSNclDynamicContentMetadata, UpdateDynamicContent> {
  private children: List<UFNclControlBase>;
  private parts: Array<Array<UFNclControlBase>>;

  public updateState<U extends CSUpdateDynamicContent>(data: Partial<U>) {
    let oldContent: UFNclControlBase[] = null;
    let newStructure: CSUFNclControlMetadata = null;

    if (data.Structure) {
      oldContent = this.Children.toArray();
      this.children = this.Children.clear();
      newStructure = data.Structure;
      data.Structure = null;
      data.DynamicContentId = newStructure.ControlUID;
      data.DataVersion = Date.now();
    }

    if (oldContent) {
      oldContent.forEach((control) => {
        control.willUnMount(false);
      });
    }

    if (newStructure) {
      this.children = this.children.push(NclContainer.createControl(newStructure, this, this.vr, this.vrAttachDetachFce));
      this.parts = NclContainerBase.splitToParts(this.children);
      data.DynamicContentId = "";
    }

    super.updateState(data);
  }

  protected getChildren(): List<UFNclControlBase> {
    if (this.children == null) {
      this.children = super.getChildren();
    }
    return this.children;
  }

  protected getParts(): Array<Array<UFNclControlBase>> {
    if (this.parts == null) {
      this.parts = super.getParts();
    }

    return this.parts;
  }

  protected createDefaultState(): UpdateDynamicContent {
    return new UpdateDynamicContent({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclQuickFilter extends NclControl<CSNclQuickFilterMetadata, UpdateQuickFilter> {
  private filters: Array<UFNclControlBase>;
  private container: NclInnerDataGrid;

  constructor(
    ncl: CSNclQuickFilterMetadata,
    parent: NclControlBase,
    container: NclInnerDataGrid,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.container = container;
  }

  public updateState<U extends CSUpdateQuickFilter>(data: Partial<U>) {
    if (data.Controls) {
      if (this.filters) {
        this.filters.forEach((control) => {
          this.removeNestedControl(control);
        });

        this.filters = null;
        data.DataVersion = Date.now();
      }

      this.filters = [];
      let control: UFNclControlBase;
      data.Controls.map((ctrl) => {
        control = NclContainer.createControl(ctrl, this, this.vr, this.vrAttachDetachFce);
        this.filters.push(control);
        this.addNestedControl(control);
      });
    }
    super.updateState(data);
    if (this.container && this.container.Container && data.Visible !== undefined) {
      this.container.Container.refreshMaxRowCount();
    }
  }

  public get Filters(): Array<UFNclControlBase> {
    return this.filters;
  }

  protected createDefaultState(): UpdateQuickFilter {
    return new UpdateQuickFilter({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

/* Client NCL parts */

type ClientExecuteFce<T> = (ctrl: T) => any;

export class ClientNclCommandItem extends NclCommandItem {
  private executeFce: ClientExecuteFce<ClientNclCommandItem> = null;
  private executor: any;
  private name: string;

  constructor(
    caption: string,
    glyphId: GlyphId,
    executor: any,
    executeFce: ClientExecuteFce<ClientNclCommandItem>,
    parent: NclControlBase,
    enabled: boolean,
    context: VRContext,
    name?: string
  ) {
    super(
      {
        FrgtData: {
          Icon: "",
          Decorate: DataActionDecorate.dadeBase,
          FillWidth: true,
          HorizontalAlignment: HorizontalAlignment.fhaLeft,
          IconPosition: IconPosition.ipLeft,
          ShowCaption: false,
          ShowIcon: true,
          DisplayStyle: TUFActionDisplayStyle.ufadsButtonTile,
          Size: 1,
          BandsCount: 1,
          TabStop: false,
          VerticalAlignment: VerticalAlignment.fvaCenter,
          ZoomFactor: 100,
          __type: ControlType.CommandAction,
        } as FrgtCommandItemData,
        Bounds: { Align: Align.Client, FromBandIndex: 0, PartIndex: 0, BandsCount: 0, InteriorBandsCount: 0 },
        ControlUID: parent.MetaData.ControlUID + "" + Math.random(),
        Name: name,
      } as CSNclCommandItemMetadata,
      parent,
      context.viewRealizer,
      context.attachDettachFce
    );
    this.updateState<CSUpdateCommandItem>({ Visible: true, Title: caption, Enabled: enabled, GlyphId: glyphId });
    this.executeFce = executeFce;
    this.executor = executor;
    this.name = name;
  }

  get Name(): string {
    return this.name;
  }

  executeCommand() {
    if (this.executeFce && this.state.Enabled !== false) {
      this.executeFce.call(this.executor, this);
    }
  }
}

export class ClientNclButton extends NclButton {
  private executeFce: ClientExecuteFce<ClientNclButton> = null;
  private executor: any;

  constructor(
    caption: string,
    glyphId: GlyphId,
    executor: any,
    executeFce: ClientExecuteFce<ClientNclButton>,
    parent: NclControlBase,
    enabled: boolean,
    context: VRContext
  ) {
    super(
      {
        FrgtData: {
          Icon: "",
          Decorate: DataActionDecorate.dadeDecorate,
          FillWidth: true,
          HorizontalAlignment: HorizontalAlignment.fhaLeft,
          IconPosition: IconPosition.ipLeft,
          ShowCaption: false,
          ShowIcon: true,
          DisplayStyle: TUFActionDisplayStyle.ufadsButtonTile,
          Size: 1,
          BandsCount: 1,
          TabStop: false,
          VerticalAlignment: VerticalAlignment.fvaCenter,
          ZoomFactor: 100,
          __type: ControlType.Button,
        } as FrgtButtonData,
        Bounds: { Align: Align.Client, FromBandIndex: 0, PartIndex: 0, BandsCount: 0, InteriorBandsCount: 0 },
        ControlUID: parent.MetaData.ControlUID + "" + Math.random(),
        Name: `vk_${caption}`,
      } as CSNclButtonMetadata,
      parent,
      context.viewRealizer,
      context.attachDettachFce
    );
    this.updateState<CSUpdateCommandItem>({ Visible: true, Title: caption, Enabled: enabled, GlyphId: glyphId });
    this.executeFce = executeFce;
    this.executor = executor;
  }

  public setExecuteFce(fce: ClientExecuteFce<ClientNclButton>) {
    if (!this.executeFce) this.executeFce = fce;
  }

  executeCommand() {
    if (this.executeFce && this.state.Enabled !== false) {
      this.executeFce.call(this.executor, this);
    }
  }
}

export class ClientNclInput extends NclInput {
  inputRef: HTMLInputElement | HTMLTextAreaElement;

  constructor(caption: string, glyphId: GlyphId, parent: NclControlBase, enabled: boolean, context: VRContext, zoomFactor = 100) {
    super(
      {
        FrgtData: {
          Icon: "",
          Size: 1,
          HorizontalAlignment: HorizontalAlignment.fhaLeft,
          VerticalAlignment: VerticalAlignment.fvaCenter,
          ZoomFactor: zoomFactor,
          BandsCount: 1,
          Watermark: "",
          TabStop: true,
          ShowLabel: true,
          ShowFrame: true,
          DisableLabelHighlight: false,
          IsPassword: false,
          BackgroundColorEditMode: { ColorType: 0, CustomColor: clNone, AsString: "", __type: "" },
          BackgroundColorReadMode: { ColorType: 0, CustomColor: clNone, AsString: "", __type: "" },
          BackgroundColorDisabledMode: { ColorType: 0, CustomColor: clNone, AsString: "", __type: "" },
          __type: ControlType.Input,
        } as FrgtInputData,
        Bounds: { Align: Align.Top, FromBandIndex: 0, PartIndex: 0, BandsCount: 0, InteriorBandsCount: 0 },
        ControlUID: parent.MetaData.ControlUID + "" + Math.random(),
        SuffixDisplayAs: TUFDisplayValueAs.dvaText,
        PrefixDisplayAs: TUFDisplayValueAs.dvaText,
        Attributes: `[{"Name":"test-id", "Value": "vk_input"}]`,
      } as CSNclInputMetadata,
      parent,
      context.viewRealizer,
      context.attachDettachFce
    );
    this.updateState<CSUpdateInput>({ Visible: true, Title: caption, Enabled: enabled, GlyphId: glyphId, InputType: TNclInputType.nitString });
  }

  public get Text(): string {
    return this.state.Text ? this.state.Text : "";
  }

  public set Text(value: string) {
    if (this.Text !== value) {
      this.updateState<CSUpdateInput>({ Text: value });
    }
  }

  public pressKey(key: string) {
    const isBackspace: boolean = !key || key === "";
    const oldValue: string = this.state.Text;
    let newValue: string = key;
    let newPosition: number = key.length;
    if (oldValue && oldValue.length > 0) {
      const start = this.inputRef.selectionStart;
      const end = this.inputRef.selectionEnd;
      if (start >= 0 && end >= 0) {
        if (start === end) {
          if (start === 0) {
            if (isBackspace) return;
            newValue = `${key}${oldValue}`;
            newPosition = key.length;
          } else if (start === oldValue.length) {
            if (isBackspace) {
              newValue = oldValue.substring(0, oldValue.length - 1);
            } else {
              newValue = `${oldValue}${key}`;
            }
            newPosition = newValue.length;
          } else {
            if (isBackspace) {
              newValue = oldValue.substring(0, start - 1) + oldValue.substring(start);
              newPosition = start - 1;
            } else {
              newValue = oldValue.substring(0, start) + key + oldValue.substring(start);
              newPosition = start + key.length;
            }
          }
        } else if (end > start) {
          newValue = oldValue.substring(0, start) + key + oldValue.substring(end);
          newPosition = start + key.length;
        }
      }
    }

    if (this.Text !== newValue) {
      this.updateState<UpdateInput>({ Text: newValue });
      this.updateFocus(true);
      this.Position = newPosition;
    }
  }

  public change(value: string): void {
    this.Text = value;
  }
}

export class ClientNclKeyboardInput extends NclKeyboardInput {
  inputRef: HTMLInputElement | HTMLTextAreaElement;

  constructor(caption: string, glyphId: GlyphId, parent: NclControlBase, enabled: boolean, context: VRContext, zoomFactor = 100) {
    super(
      {
        FrgtData: {
          Icon: "",
          Size: 1,
          HorizontalAlignment: HorizontalAlignment.fhaLeft,
          VerticalAlignment: VerticalAlignment.fvaCenter,
          ZoomFactor: zoomFactor,
          BandsCount: 1,
          Watermark: "",
          TabStop: false,
          FrontGlyphId: "",
          RearGlyphId: "",
          BackGlyphId: "",
          FrameKind: 0,
          IsPassword: false,
          ColorStyle: 0,
          AcceptKeyAction: 0,
          IgnoreFieldValue: false,
          InputAsEnglishUSLocale: false,
          ShowFrame: false,
          __type: ControlType.Input,
        } as FrgtKeyboardInputData,
        Bounds: { Align: Align.Top, FromBandIndex: 0, PartIndex: 0, BandsCount: 0, InteriorBandsCount: 0 },
        ControlUID: parent.MetaData.ControlUID + "" + Math.random(),
        SuffixDisplayAs: TUFDisplayValueAs.dvaText,
        PrefixDisplayAs: TUFDisplayValueAs.dvaText,
      } as CSNclKeyboardInputMetadata,
      parent,
      context.viewRealizer,
      context.attachDettachFce
    );
    this.updateState<CSUpdateInput>({ Visible: true, Title: caption, Enabled: enabled, GlyphId: glyphId, InputType: TNclInputType.nitString });
  }

  public get Text(): string {
    return this.state.Text ? this.state.Text : "";
  }

  public set Text(value: string) {
    if (this.Text !== value) {
      this.updateState<CSUpdateKeyboardInput>({ Text: value });
    }
  }

  public change(value: string): void {
    this.Text = value;
  }
}

export class NclMenuView extends NclViewBase<CSNclMenuViewMetadata, UpdateHeadered> {
  private menu: NclMenu | undefined;

  constructor(ncl: CSNclMenuViewMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Menu) {
      this.menu = new NclMenu(ncl.Menu, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.menu);
    }
  }

  public get Content(): NclMenu | undefined {
    return this.menu;
  }

  internalComputeMinHeight(): number {
    let h = super.internalComputeMinHeight();
    if (this.menu) {
      h += this.menu.internalComputeMinHeight();
    }
    return h;
  }

  isShowHeader(): boolean {
    return super.isShowHeader() && Context.DeviceInfo.StyleOfModalWindowShow === TBehaviorTypeByDevice.btbdMobile;
  }

  public isOnlyActionsInFirstLevel(maxCount: number): boolean {
    let result = true;

    if (!this.menu || !this.menu.Actions) return result;
    if (this.menu.Actions.count() > maxCount) result = false;

    const isContainer = this.menu.Actions.some((item) => item instanceof NclMenuContainer);

    if (isContainer) result = false;

    return result;
  }
}

export abstract class NclMenuItemBase<T extends CSNclMenuItemBaseMetadata, S extends UpdateControl> extends NclControl<T, S> {
  static createByNcl(
    ncl: CSNclMenuItemBaseMetadata,
    parent: NclMenuContainer<CSNclMenuContainerMetadata, UpdateMenuContainer>,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ): NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl> {
    switch (ncl.__type) {
      case ControlType.Menu:
        return new NclMenu(ncl as CSNclMenuMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MenuItem:
        return new NclMenuItem(ncl as CSNclMenuItemMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.ClientMenuItem:
        return new NclClientMenuItem(ncl as CSNclMenuItemMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.ClientMenuGroup:
        return new NclClientMenuGroup(ncl as CSNclMenuGroupMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MenuDivider:
        return new NclMenuDivider(ncl as CSNclMenuMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MenuGroup:
        return new NclMenuGroup(ncl as CSNclMenuGroupMetadata, parent, vr, vrAttachDetachFce);
    }
    throw new Error("Invalid type of menu control: " + ncl.__type);
  }

  internalComputeMinHeight(): number {
    return this.VCX.LabelControl.getHeight(2);
  }

  public abstract execute(): void;

  get MarginXFactor(): number {
    return 1 / 3;
  }

  get MarginYFactor(): number {
    return 1 / 3;
  }
}

export abstract class NclMenuContainer<T extends CSNclMenuContainerMetadata, S extends UpdateMenuContainer> extends NclMenuItemBase<T, S> {
  private actions: List<NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>> | undefined;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Actions) {
      const list = new Array<NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>>();
      let act: NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>;
      ncl.Actions.forEach((item) => {
        act = NclMenuItemBase.createByNcl(item, this, vr, vrAttachDetachFce);
        list.push(act);
        this.addNestedControl(act);
      });

      this.actions = List<NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>>(list);
    }
  }

  public get Actions(): List<NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>> | undefined {
    return this.actions;
  }

  public execute(forceClose?: boolean, forceOpen?: boolean, closeOthers?: boolean) {
    if (this.Parent instanceof NclMenuContainer) {
      if (closeOthers) {
        this.Parent.Actions?.forEach((element) => {
          if (element instanceof NclMenuContainer && element !== this) {
            if ((element.State as UpdateMenuContainer).isOpen) {
              element.updateState<CSUpdateMenuContainer>({ isOpen: false });
              this.closeAllSubMenuContainer(element);
            }
          } else if (element instanceof NclMenuContainer && element === this && (element.State as UpdateMenuContainer).isOpen) {
            this.closeAllSubMenuContainer(element);
          }
        });
      }
      if (forceClose) {
        this.updateState<CSUpdateMenuContainer>({ isOpen: false });
      } else if (forceOpen) {
        this.updateState<CSUpdateMenuContainer>({ isOpen: true });
      } else {
        this.updateState<CSUpdateMenuContainer>({ isOpen: !(this.State as UpdateMenuContainer).isOpen });
      }
    }
  }

  private closeAllSubMenuContainer(element: NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>) {
    if (element instanceof NclMenuContainer) {
      element.actions?.forEach((subElement) => {
        if ((subElement.State as UpdateMenuContainer).isOpen) {
          subElement.updateState<CSUpdateMenuContainer>({ isOpen: false });
          this.closeAllSubMenuContainer(subElement);
        }
      });
    }
  }

  public getMenuWidth(): number {
    const widestMenuItem = this.getMaxCharCaption();

    if (!widestMenuItem) return 100;

    const caption = this.VCX.GridControl.Font.computeTextWidth(widestMenuItem.caption);
    const iconSpace = (this.VCX.GridControl.Font._MWidth ?? 10) * 2;
    const hotkey = this.VCX.GridControl.Font.computeTextWidth(widestMenuItem.hotkey) + 10; // zohledneni marginu

    return caption + iconSpace + (widestMenuItem?.hasHotkey ? hotkey : 0) + (widestMenuItem?.expand ? 20 : 0) + 20;
  }

  public getMenuHeight(): number {
    let result = 0;
    this.Actions?.forEach((action) => {
      result += action.ComputedMinHeightWithMargin + this.VCX.sizeMap(1) * 2; // button + padding
    });

    return result;
  }

  private getMaxCharCaption() {
    if (this.Ncl.Actions.length < 1) return null;

    const menuItem: { caption: string; hasHotkey: boolean; charCount: number; hotkey: string; expand: boolean }[] = [];

    this.Ncl.Actions.map((item) => {
      const itemWithHotkey = (item as CSNclMenuItemMetadata).HotKey;

      if (itemWithHotkey && itemWithHotkey !== "") {
        menuItem.push({
          caption: item.Caption,
          hasHotkey: true,
          charCount: item.Caption.length + itemWithHotkey.length,
          hotkey: itemWithHotkey,
          expand: false,
        });
      } else {
        if ((item as CSNclMenuContainerMetadata).Actions && (item as CSNclMenuContainerMetadata).Actions.length > 0) {
          menuItem.push({ caption: item.Caption, hasHotkey: false, charCount: item.Caption.length, hotkey: "", expand: true });
        } else {
          menuItem.push({ caption: item.Caption, hasHotkey: false, charCount: item.Caption.length, hotkey: "", expand: false });
        }
      }
    });

    menuItem.sort((a, b) => {
      return b.charCount - a.charCount;
    });

    return menuItem[0];
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }
}

export class NclMenu extends NclMenuContainer<CSNclMenuMetadata, UpdateMenuContainer> {
  constructor(ncl: CSNclMenuMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    NclMenu.initClientActions(ncl, vr.getRealizerUID());
    super(ncl, parent, vr, vrAttachDetachFce);
  }

  protected createDefaultState(): UpdateMenuContainer {
    return new UpdateMenuContainer({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID(), isOpen: true } as CSUpdateMenuContainer);
  }

  internalComputeMinHeight(): number {
    let h = 0;
    h = this.getMenuHeight();
    return h;
  }

  private static initClientActions(ncl: CSNclMenuMetadata, dockRealizerUID: string) {
    if (ncl.SourceControlUID) {
      const emitVR = Context.getApplication().getActiveRealizer();
      if (emitVR) {
        const src: any = emitVR.getControlByUID(ncl.SourceControlUID);
        if (src && (src as ClientContextMenuEmitor).appendActionTo) {
          (src as ClientContextMenuEmitor).appendActionTo(ncl, dockRealizerUID);
        }
      }
    }
  }
}

export class NclMenuGroup extends NclMenuContainer<CSNclMenuGroupMetadata, UpdateMenuContainer> {
  protected createDefaultState(): UpdateMenuContainer {
    return new UpdateMenuContainer({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclMenuItem extends NclMenuItemBase<CSNclMenuItemMetadata, UpdateMenuItem> {
  protected createDefaultState(): UpdateMenuItem {
    return new UpdateMenuItem({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public execute() {
    this.appendFunction({ Name: cJSonFunctionExecute }, true);
  }
}

class NclClientMenuItem extends NclMenuItem {
  protected createDefaultState(): UpdateMenuItem {
    return new UpdateMenuItem({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID(), Visible: true, Enabled: true });
  }

  public execute() {
    if ((this.ncl as CSNclClientMenuItemMetadata).action) {
      (this.ncl as CSNclClientMenuItemMetadata).action();
    }
    if (this.parent instanceof NclMenuView) {
      this.parent.closeView();
    }
  }
}

class NclClientMenuGroup extends NclMenuGroup {
  protected createDefaultState(): UpdateMenuContainer {
    return new UpdateMenuContainer({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID(), Visible: true, Enabled: true });
  }
}

export class NclMenuDivider extends NclMenuItemBase<CSNclMenuDividerMetadata, UpdateControl> {
  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  internalComputeMinHeight(): number {
    return this.VCX.sizeMap(1);
  }

  public execute() {}
}

export class NclLocatorPanel extends NclContainerBase<CSNclLocatorPanelMetadata, UFUpdateControl> {
  private switchBtn: NclCommandItem;

  constructor(ncl: CSNclLocatorPanelMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.SwitchBtn) {
      this.switchBtn = new NclCommandItem(ncl.SwitchBtn, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.switchBtn);
    }
    vr.addLocatorPanel(this);
  }

  public willUnMount(onlyListener: boolean): void {
    if (!onlyListener) this.vr.removeLocatorPanel(this);

    super.willUnMount(onlyListener);
  }

  public get SwitchBtn(): NclCommandItem {
    return this.switchBtn;
  }

  public getLastLocatrInput(): NclInput {
    if (this.Children && this.Children.count() > 0) {
      let child: UFNclControlBase;
      for (let index = this.Children.count() - 1; index >= 0; index--) {
        child = this.Children.get(index);
        if (child.State.Visible && child.State.Enabled !== false && child instanceof NclInput) {
          return child;
        }
      }
    }
    return null;
  }

  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclSignInput extends UFNclControl<CSNclSignInputMetadata, UpdateSignInput> {
  protected createDefaultState(): UpdateSignInput {
    return new UpdateSignInput({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get ComputedMinHeight(): number {
    return this.VCX.InputControl.getInputHeight(this.ncl.FrgtData.Size, true, true);
  }

  public change(value: SignData, accept = false) {
    if (this.state.SignData === value) {
      this.initDataRequest();
      return;
    }

    this.internalSetData<SignInputDataRequest>({ SignData: value }, accept, true, accept ? RealizerOperations.Accept : RealizerOperations.None);
  }
}

export class SectionItems {
  private size: number;
  private rest: number;
  private items: Array<UFNclControlBase>;

  constructor(size: number) {
    this.size = size;
    this.rest = size;
    this.items = new Array<UFNclControlBase>();
  }

  public get Rest(): number {
    return this.rest;
  }

  private addOrRemoveItem(item: NclCommandItem, add: boolean) {
    switch (item.Ncl.FrgtData.DisplayStyle) {
      case TUFActionDisplayStyle.ufadsButtonTile:
        if (add) {
          this.rest -= this.size;
        } else {
          this.rest += this.size;
        }
        break;
      case TUFActionDisplayStyle.ufadsButtonSmall:
        if (add) {
          this.rest -= 1;
        } else {
          this.rest += 1;
        }
        break;
      case TUFActionDisplayStyle.ufadsButtonLine:
        if (add) {
          this.rest -= 1;
        } else {
          this.rest += 1;
        }
        break;
    }
  }

  public pushItem(item: NclCommandItem) {
    this.items.push(item);
    if (item instanceof NclCommandItem) {
      this.addOrRemoveItem(item, true);
    }
  }

  public popItem(): NclCommandItem {
    const item: NclCommandItem = this.items.pop() as NclCommandItem;
    if (item instanceof NclCommandItem) {
      this.addOrRemoveItem(item, false);
    }
    return item;
  }

  public get Items(): Array<UFNclControlBase> {
    return this.items;
  }
}

export class NclInnerSection extends NclControl<CSNclInnerSectionMetaData, UpdateControl> {
  private contentItems: Array<UFNclControlBase>;
  private ribbon: NclRibbon;

  constructor(
    ncl: CSNclInnerSectionMetaData,
    parent: NclControlBase,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce,
    ribbon: NclRibbon
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.ContentItems) {
      this.contentItems = new Array<UFNclControlBase>();
      let item: UFNclControlBase;
      const actGroup: SectionItems = new SectionItems(ribbon.Ncl.FrgtData.Size);
      this.ribbon = ribbon;
      if (this.ncl.ForDataContext) this.vr.registerContextDependency(this.ncl.ForDataContext);
      ncl.ContentItems.map((value, index) => {
        item = this.createControl(value, this, this.vr, vrAttachDetachFce);
        if (item) {
          this.addNestedControl(item);
          this.contentItems.push(item);
        }
      });
    }
  }

  public get Ribbon(): NclRibbon {
    return this.ribbon;
  }

  public get ContentItems(): Array<UFNclControlBase> {
    return this.contentItems;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  private createControl(ncl: CSUFNclControlMetadata, parent: this, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce): UFNclControlBase {
    return NclContainer.createControl(ncl, parent, vr, vrAttachDetachFce);
  }

  public willUnMount(onlyListener: boolean) {
    if (!onlyListener && this.ncl.ForDataContext) {
      this.vr.unRegisterContextDependency(this.ncl.ForDataContext);
    }
    super.willUnMount(onlyListener);
  }
}

export class NclInnerToolBar extends NclControl<CSNclInnerToolBarMetadata, UpdateInnerToolbar> {
  private sections: Array<NclInnerSection>;
  private ribbon: NclRibbon;
  private accessor: NclAccessor;

  constructor(
    ncl: CSNclInnerToolBarMetadata,
    parent: NclRibbon,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce,
    accessor: NclAccessor
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.ribbon = parent;
    this.accessor = accessor;
    if (this.ncl.ForDataContext) this.vr.registerContextDependency(this.ncl.ForDataContext);

    if (ncl.Sections) {
      this.sections = new Array<NclInnerSection>();
      let section: NclInnerSection;
      ncl.Sections.map((value) => {
        section = new NclInnerSection(value, this, this.vr, vrAttachDetachFce, parent);
        this.addNestedControl(section);
        this.sections.push(section);
      });
    }
  }

  public get Sections(): Array<NclInnerSection> {
    return this.sections;
  }

  public get Ribbon(): NclRibbon {
    return this.ribbon;
  }

  protected createDefaultState(): UpdateInnerToolbar {
    return new UpdateInnerToolbar({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    return -1; //(2 * (this.VCX.LabelControl.getHeight(this.ribbon.Size)));
  }

  protected afterSetState(canUpdate: boolean, oldState: UpdateInnerToolbar) {
    super.afterSetState(canUpdate, oldState);
    if (canUpdate && oldState.VisibleAccessor !== this.state.VisibleAccessor) {
      if (this.accessor) {
        this.accessor.updateState({ Visible: this.state.VisibleAccessor });
      }
    }
  }

  public willUnMount(onlyListener: boolean) {
    if (!onlyListener && this.ncl.ForDataContext) {
      this.vr.unRegisterContextDependency(this.ncl.ForDataContext);
    }
    super.willUnMount(onlyListener);
  }
}

export class NclAccessor extends NclControl<CSNclInnerToolBarMetadata, UpdateControl> {
  private ribbon: NclRibbon;

  constructor(ncl: CSNclInnerToolBarMetadata, parent: NclRibbon, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    const old = ncl.ControlUID;
    ncl.ControlUID += "_accesor_";
    super(ncl, parent, vr, vrAttachDetachFce);
    ncl.ControlUID = old;
    this.ribbon = parent;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get MarginXFactor(): number {
    return 4;
  }

  get MarginYFactor(): number {
    return 0;
  }
}

export interface RibbonItem {
  Accessor: NclAccessor;
  ToolBar: NclInnerToolBar;
}

export class NclRibbon extends UFNclControl<CSNclRibbonMetadata, UpdateRibbon> {
  private toolbars: Array<RibbonItem>;
  private othersBtn: NclCommandItem;
  private tabHiddeBtn: NclCommandItem;

  constructor(ncl: CSNclRibbonMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.OthersBtn) {
      this.othersBtn = new NclCommandItem(ncl.OthersBtn, this, this.vr, vrAttachDetachFce);
      this.addNestedControl(this.othersBtn);
    }

    if (ncl.TabHideBtn) {
      this.tabHiddeBtn = new NclCommandItem(ncl.TabHideBtn, this, this.vr, vrAttachDetachFce);
      this.tabHiddeBtn.Ncl.FrgtData.DisplayStyle = TUFActionDisplayStyle.ufadsButtonSmall;
      this.addNestedControl(this.tabHiddeBtn);
    }
    this.toolbars = [];
  }

  public updateState<U extends CSUpdateRibbon>(data: Partial<U>) {
    if (data.ToolBars) {
      if (this.toolbars && this.toolbars.length > 0) {
        this.toolbars.forEach((control) => {
          this.removeNestedControl(control.Accessor);
          this.removeNestedControl(control.ToolBar);
        });

        this.toolbars = [];
        this.setState(this.state.with({ ToolBars: null } as CSUpdateRibbon), true); // update for recreate all components
      }

      let toolBar: NclInnerToolBar;
      let accessor: NclAccessor;
      data.ToolBars.map((value) => {
        accessor = new NclAccessor(value, this, this.vr, this.vrAttachDetachFce);
        this.addNestedControl(accessor);
        toolBar = new NclInnerToolBar(value, this, this.vr, this.vrAttachDetachFce, accessor);
        this.addNestedControl(toolBar);
        this.toolbars.push({ Accessor: accessor, ToolBar: toolBar });
      });

      if (this.toolbars.length > 1) {
        this.othersBtn.Ncl.FrgtData.DisplayStyle = TUFActionDisplayStyle.ufadsButtonSmall;
      } else {
        this.othersBtn.Ncl.FrgtData.DisplayStyle = TUFActionDisplayStyle.ufadsButtonTile;
      }
    }
    super.updateState(data);
  }

  public contextMenu() {
    this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }

  public get ToolBars(): Array<RibbonItem> {
    return this.toolbars;
  }

  public get OthersBtn(): NclCommandItem {
    return this.othersBtn;
  }

  public get TabHideBtn(): NclCommandItem {
    return this.tabHiddeBtn;
  }

  public changeCurrentToolBar(index: number) {
    if (this.state.CurrentToolBarIndex != index) {
      this.internalSetData<RibbonDataRequest>({ CurrentToolBarIndex: index }, true);
    }
  }

  protected createDefaultState(): UpdateRibbon {
    return new UpdateRibbon({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    let height = 0;

    height += this.VCX.sizeMap(23) * this.Size;
    height += this.VCX.LabelControl.getHeight(1); // label
    height += 2 * this.VCX.Data.MarginY;
    height += 2; // border
    height += this.VCX.sizeMap(29); // header

    return height; //-1;
  }
}

export class NclGantt extends NclHeaderedControl<CSNclGanttMetadata, UpdateGantt> {
  private content: NclGanttContent;
  private footer: NclGanttFooter;

  constructor(ncl: CSNclGanttMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Content) {
      this.content = new NclGanttContent(ncl.Content, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.content);
    }

    if (ncl.Footer) {
      this.footer = new NclGanttFooter(ncl.Footer, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.footer);
    }
  }

  get Content(): NclGanttContent {
    return this.content;
  }

  get Footer(): NclGanttFooter {
    return this.footer;
  }

  public invokeAction(op: GridOperations) {
    this.appendFunction({ Name: cJSonFunctionGridOperation, Args: [op] }, true);
  }

  public changeSortBy(column: number, append: boolean) {
    this.appendFunction({ Name: cJSonFunctionGridChangeSortBy, Args: [column, append] }, true);
  }

  public doubleClick() {
    this.appendFunction({ Name: cJSonDefaultAcceptExecute }, true);
  }

  protected internalComputeMinHeight(): number {
    let result = super.internalComputeMinHeight();

    if (this.Content) {
      result += this.content.ComputedMinHeight;
    }

    if (this.Footer && this.showFooter()) {
      result += this.footer.ComputedMinHeight;
    }

    return result;
  }
  public updateState<U extends CSUpdateGantt>(data: Partial<U>) {
    if (data.CapacitiesColor) {
      this.Ncl.CapacitiesColor = data.CapacitiesColor;
    }
    if (data.ClientZoom) {
      this.Ncl.ClientZoom = data.ClientZoom;
    }
    if (data.Fake_VCXColorMap) {
      this.Ncl.Fake_VCXColorMap = data.Fake_VCXColorMap;
    }
    if (data.Fake_VCXZoom) {
      this.Ncl.Fake_VCXZoom = data.Fake_VCXZoom;
    }
    if (data.OwnBackgroundColor) {
      this.Ncl.OwnBackgroundColor = data.OwnBackgroundColor;
    }
    if (data.InEditMode) {
      this.Ncl.FrgtData.InEditMode = data.InEditMode;
    }

    super.updateState(data);
  }

  isShowHeader(): boolean {
    if (this.Header?.MetaData?.ControlUID === undefined) return false;
    return super.isShowHeader() && !this.Ncl.FrgtData?.HideHeader;
  }

  showFooter(): boolean {
    if (this?.Footer?.MetaData?.ControlUID === undefined) return false;
    return !this.Ncl.FrgtData?.HideFooter;
  }

  // isProjectManagement():boolean{
  // 	return this.Ncl.FrgtData.ProjectManagement;
  // }

  // isResourceManagement():boolean{
  // 	return this.Ncl.FrgtData.ResourceManagement;
  // }

  byOperations(): boolean {
    return this.Ncl.FrgtData?.ByOperations ? true : false;
  }

  byResources(): boolean {
    return this.Ncl.FrgtData?.ByResources ? true : false;
  }

  headerAtBottom(): boolean {
    return this.ncl.FrgtData?.HeaderAtBottom ? true : false;
  }

  ownColors(): boolean {
    return this.Ncl.FrgtData?.OwnColors ? true : false;
  }

  hideNavigator(): boolean {
    return this.Ncl.FrgtData?.HideNavigator ? true : false;
  }

  hideXAxis(): boolean {
    return this.Ncl.FrgtData?.HideXAxis ? true : false;
  }

  hideYAxis(): boolean {
    return this.Ncl.FrgtData?.HideYAxis ? true : false;
  }

  hideDateIndicator(): boolean {
    return this.Ncl.FrgtData?.HideDateIndicator ? true : false;
  }

  hideRangeSelectorDates(): boolean {
    return this.Ncl.FrgtData?.HideRangeSelectorDates ? true : false;
  }

  //showDateTime(): boolean {
  //  return this.Ncl.FrgtGanttData.ShowDateTime ? true : false;
  //}

  twoRowXAxis(): boolean {
    return this.Ncl.FrgtData?.TwoRowXAxis ? true : false;
  }

  timeAxisPrecision(): number {
    return this.Ncl.FrgtData?.TimeAxisPrecision;
  }

  draggableStartEnd(): boolean {
    return this.Ncl.FrgtData?.DraggableStartEnd ? true : false;
  }

  pointSelectAllowed(): boolean {
    return this.Ncl.FrgtData?.PointSelectAllowed ? true : false;
  }

  timeAxisAppearanceType(): TimeAxisAppearanceType {
    return this.Ncl.FrgtData?.TimeAxisAppearanceType ?? 0;
  }

  isHoursTimed(): boolean {
    return this.timeAxisAppearanceType() === TimeAxisAppearanceType.taatHours;
  }

  public isLite(): boolean {
    return false; //this.Ncl.FrgtGanttData.LiteHeader;
  }

  get LeftAxisTableColumns() {
    return [""];
  }

  get LeftAxisTableData() {
    return [[""]];
  }
}

export class NclDashboard extends NclHeaderedControl<CSNCLDashboardMetadata, UpdateDashboard> {
  private data: DashboardData;
  private changedParts: Array<string>;
  private expandedMembers: VisibleMember[] = [];
  private foundMember: VisibleMember;
  private partUpdateRequests: Array<string> = [];
  private timer: any;
  private partStates: Map<string, PartState> = new Map<string, PartState>();

  public updateState<U extends CSUpdateDashboard>(data: Partial<U>) {
    this.changedParts = [];
    if (data.PartStates) {
      const parts = data.PartStates;
      delete data.PartStates;
      parts.forEach((part: PartState) => {
        if (part.Id == "" && part.State === TPartState.psShowProgress) {
          this.partStates.clear();
        }
        if (part.Id !== "") this.changedParts.push(part.Id);
        this.updatePartState(part);
        if (part.State === TPartState.psShowProgress) {
          if (this.partUpdateRequests.indexOf(part.Id) < 0) this.partUpdateRequests.push(part.Id);
        } else {
          const i = this.partUpdateRequests.indexOf(part.Id);
          if (i >= 0) {
            this.partUpdateRequests.splice(i, 1);
          }
        }
        if (part.Data) {
          if ("Containers" in part.Data) {
            part.Data.Containers?.map((container) => {
              container?.Parts.map((p) => {
                if ("Series" in p) {
                  p.Series.map((series) => {
                    NclSimpleChart.repairSerie(p as SimpleChartReportPart, series, false, true);
                  });
                }
              });
            });
          } else if ("Series" in part.Data) {
            part.Data.Series.map((series) => {
              NclSimpleChart.repairSerie(part.Data as SimpleChartReportPart, series, false, true);
            });
          }

          const value = this.getPartById(part.Id);
          if (value) {
            Object.assign(value, part.Data);
            data.DataVersion = Date.now();

            if ((value as FilterPart).FilterPartType === "MultiSelectTreeFilter" && this.expandedMembers.length > 0) {
              // add all current expanded nodes to the tree
              this.expandedMembers.map((expandedMember, index) => {
                // resets member state to 0 if member is not present in UpdatedMembers property; should be implemented on server
                if (expandedMember.Children) {
                  expandedMember.Children.map((child) => {
                    const foundMember = (part.Data as FilterPart).UpdatedMembers.find((updatedMember) => updatedMember.Identifier === child.Identifier);
                    if (!foundMember) {
                      child.MemberState = 0;
                    }
                  });
                }

                this.addMembers((value as FilterPart).VisibleMembers, expandedMember.Identifier, this.expandedMembers[index]);
              });
            }

            if ((part.Data as FilterPart).UpdatedMembers) {
              // on member state change, replace the current members with new ones
              (part.Data as FilterPart).UpdatedMembers.map((updatedMember) => {
                this.addMembers((value as FilterPart).VisibleMembers, updatedMember.Identifier, updatedMember);
              });
            }
          } else {
            this.data = part.Data as DashboardData;
            this.ExpandedMembers = [];
            data.DataVersion = Date.now();
          }

          delete part.Data;
        } else if (part.Error) {
          this.data = null;
          data.Error = part.Error;
          data.DataVersion = Date.now();
        }
      });
    } else if (data.MemberStates) {
      // add a new expanded node to the tree
      const memberStates: any = data.MemberStates;
      const part = this.getPartById(memberStates[0].MemberData.PartIdentifier);

      this.findMember((part as FilterPart).VisibleMembers, memberStates[0].MemberData.MemberID);

      (memberStates[0].MemberData.ExpandedMembers as VisibleMember[]).map((expandedMember) => {
        expandedMember.Parent = this.foundMember;
      });

      this.addMembers((part as FilterPart).VisibleMembers, memberStates[0].MemberData.MemberID, memberStates);

      delete data.MemberStates;
      data.DataVersion = Date.now();
    }

    super.updateState(data);

    if (this.partUpdateRequests.length > 0 && !this.timer) {
      this.timer = setTimeout(() => {
        if (this.partUpdateRequests.length > 0) this.appendFunction({ Name: cJSonPartUpdate, Args: this.partUpdateRequests }, true);
        this.timer = null;
      }, 250);
    }
  }
  private updatePartState(part: PartState) {
    const state = this.partStates.get(part.Id);
    if (state) {
      Object.assign(state, part);
    } else {
      if (part.Data) {
        part.State = TPartState.psShowPart; // pri nacitani dat ze skriptu chodi 'Data' okamzite, neprijde 'State = showProgress' - nejedna se o asynchronni akci, takze lze data rovnou zobrazit
      }
      this.partStates.set(part.Id, part);
    }
  }

  public isPartsUpdateCompleted(): boolean {
    return this.partUpdateRequests.length === 0;
  }

  public findMember = (members: VisibleMember[], searchedMemberID: string) => {
    return members.find((member) => {
      if (member.Children) {
        const found = this.findMember(member.Children, searchedMemberID);

        if (found) {
          return true;
        }
      }

      if (member.Identifier === searchedMemberID) {
        this.foundMember = member;
        return true;
      } else {
        return false;
      }
    });
  };

  public addMembers(members: VisibleMember[], searchedMemberID: string, memberStates: any) {
    members.find((member) => {
      if (member.Children) {
        this.addMembers(member.Children, searchedMemberID, memberStates);
      }

      if (member.Identifier === searchedMemberID) {
        if (memberStates.Children) {
          member.Children = memberStates.Children;
        } else if (memberStates.Caption) {
          member.MemberState = memberStates.MemberState;
        } else {
          member.Children = memberStates[0].MemberData.ExpandedMembers;
        }
      }
    });
  }

  public set ExpandedMembers(expandedMembers: VisibleMember[]) {
    this.expandedMembers = expandedMembers;
  }

  public get ExpandedMembers() {
    return this.expandedMembers;
  }

  public isLite(): boolean {
    return true;
  }

  public isChangedParts(id: string): boolean {
    if (this.changedParts) {
      return this.changedParts.indexOf(id) >= 0;
    }
    return false;
  }

  public getPartState(id: string): PartState {
    if (this.partStates) {
      const result = this.partStates.get(id);
      if (result) return result;
      else return this.getPartState("");
    }
    Log.error(`Dashboard part: ${id} not found in stes.`, undefined);
    return undefined;
  }

  private getPartById(ID: string): Part {
    let result: Part = undefined;
    if (this.data && this.data.Containers) {
      this.data.Containers.forEach((item) => {
        if (item.Parts && !result) {
          item.Parts.forEach((prt) => {
            if (prt.PartIdentifier === ID) {
              result = prt;
              return;
            }
          });
        }
      });
    }
    return result;
  }

  public get DashboardData(): DashboardData {
    return this.data;
  }

  createDefaultState() {
    return new UpdateDashboard({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public partClick(partId: string, ids: string[], shiftKey: boolean, ctrlKey: boolean, datasetIndex: number, index: number, mouse: TMouseButton) {
    let args = [partId, ctrlKey, shiftKey, datasetIndex, index, mouse];

    if (ids.length > 0) {
      args = [...args, ...ids];
    }

    this.appendFunction({ Name: cJSonFunctionPartClickExecute, Args: args }, true);
  }

  public updateSelectedMembersInTreeFilter(partId: string, members: { memberId: string; state: number }[]) {
    this.appendFunction({ Name: cJSonTreeViewPartClick, Args: [partId, members] }, true);
  }

  public toggleMember(partId: string, memberId: string) {
    this.appendFunction({ Name: cJSonMemberClickToExpand, Args: [partId, memberId] }, true);
  }

  public dynamicFilterPartClick(partId: string, choice: number) {
    this.appendFunction({ Name: cJSonDynamicFilterPartClick, Args: [partId, choice] }, true);
  }
}

export class NclMultiFormatText extends UFNclControl<CSNclMultiFormatTextMetadata, UpdateMultiFormatText> implements ClientContextMenuEmitor {
  protected createDefaultState(): UpdateMultiFormatText {
    return new UpdateMultiFormatText({ ControlUID: this.Ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public executeCommandByNumber = (commandNumber: number) => {
    this.appendFunction({ Name: cJSonExecuteCommandByNumber, Args: [commandNumber] }, true);
  };

  protected internalComputeMinHeight(): number {
    if (this.state.Measure) {
      const measure = (this.state as any).get("Measure").toJS();
      if (this.VCX.calcMFMeasure(measure)) {
        return measure.Height;
      }
    }
    return super.internalComputeMinHeight();
  }

  public contextMenu() {
    this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }

  appendActionTo(menu: CSNclMenuMetadata): void {
    appendSpecialActionsToMenu(Array.from(this.state.Values), menu);
  }
}

export class NclFlowChart extends UFNclControl<CSNclFlowChartMetadata, UFUpdateControl> {
  constructor(ncl: CSNclFlowChartMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
  }

  protected createDefaultState(): UpdateFlowChart {
    return new UpdateFlowChart({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public async toolBarClick(selectedAction: string) {
    if (selectedAction == "paste") {
      const clipboard = navigator.clipboard;
      if (clipboard && !isDesktopComponent(this.getRealizerUID())) {
        const text = await clipboard.readText();

        this.appendFunction({ Name: cJsonFunctionToolBarClick, Args: [selectedAction, text] }, true);
        return;
      }
    }
    this.appendFunction({ Name: cJsonFunctionToolBarClick, Args: [selectedAction] }, true);
  }

  public nodeClick(id: string) {
    this.appendFunction({ Name: cJsonFunctionNodeClick, Args: [id] }, true);
  }
  public nodeContextMenu(id: string) {
    this.appendFunction({ Name: cJsonFunctionNodeContextMenu, Args: [id] }, true);
  }

  public select(ids: string[]) {
    const IDS = ids.toString();
    this.appendFunction({ Name: cJsonFunctionSelection, Args: [IDS] }, true);
  }

  public nodeDoubleClick(id: string) {
    this.appendFunction({ Name: cJsonFunctionNodeDoubleClick, Args: [id] }, true);
  }

  public edgeClick(id: string) {
    this.appendFunction({ Name: cJsonFunctionEdgeClick, Args: [id] }, true);
  }

  public edgeContextMenu(id: string) {
    this.appendFunction({ Name: cJsonFunctionEdgeContextMenu, Args: [id] }, true);
  }

  public edgeDoubleClick(id: string) {
    this.appendFunction({ Name: cJsonFunctionEdgeDoubleClick, Args: [id] }, true);
  }

  public nodeDragStop(id: string, x: string, y: string) {
    this.appendFunction({ Name: cJsonFunctionNodeDragStop, Args: [id, x, y] }, true);
  }

  public addLink(source: string, target: string, edgeType: string) {
    this.appendFunction({ Name: cJsonFunctionAddLink, Args: [source, target, edgeType] }, true);
  }

  public addNode(nodeType: string, left: string, top: string) {
    this.appendFunction({ Name: cJsonFunctionAddNode, Args: [nodeType, left, top] }, true);
  }
  public edgeUpdate(id: string, actionType: string, elementId: string) {
    console.log(id + " " + actionType + " " + elementId);
    this.appendFunction({ Name: cJsonFunctionEdgeUpdate, Args: [id, actionType, elementId] }, true);
  }
  public backgroundClick() {
    this.appendFunction({ Name: cJsonFunctionBackgroundClick, Args: [] }, true);
  }
}

export enum TWebContentEditableMode {
  wcemThroughAS,
  wcemIntegrated,
}
export class NclWebContentEditor extends UFNclControl<CSUFNclControlMetadata, UpdateWebContentEditor> {
  ContentEditMode: TWebContentEditableMode;

  constructor(ncl: CSUFNclControlMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
  }

  protected createDefaultState(): UpdateWebContentEditor {
    return new UpdateWebContentEditor({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclSimpleChart extends UFNclControl<CSNclSimpleChartMetadata, UpdateSimpleChart> {
  private rightMenuButton: NclButton;
  private forceRefreshButton: NclButton;
  private data: ReportPart;

  constructor(ncl: CSNclSimpleChartMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.RightMenuButton) {
      this.rightMenuButton = new NclButton(ncl.RightMenuButton, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.rightMenuButton);
    }
    if (ncl.ForceRefreshButton) {
      this.forceRefreshButton = new NclButton(ncl.ForceRefreshButton, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.forceRefreshButton);
    }
  }

  public updateState<U extends CSUpdateSimpleChart>(data: Partial<U>) {
    if (data.Data) {
      this.data = NclSimpleChart.repairData(data.Data);
      data.DataVersion = Date.now();
      delete data.Data;
    }

    super.updateState(data);
  }

  public get Data(): ReportPart {
    return this.data;
  }

  public get ForceRefreshButton(): NclButton {
    return this.forceRefreshButton;
  }

  public get RightMenuButton(): NclButton {
    return this.rightMenuButton;
  }

  protected createDefaultState(): UpdateSimpleChart {
    return new UpdateSimpleChart({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public click(datasetIndex: number, index: number) {
    if (this.data.Series.length > 1) {
      if (datasetIndex < this.data.Series.length) {
        if (index < this.data.Series[datasetIndex].DataPoints.length) {
          const dp = this.data.Series[datasetIndex].DataPoints[index];
          if (dp) {
            this.appendFunction({ Name: cJSonFunctionChartClick, Args: [datasetIndex, dp.OriginNdx] }, true);
          }
        }
      }
    } else {
      this.appendFunction({ Name: cJSonFunctionChartClick, Args: [datasetIndex, index] }, true);
    }
  }

  private static formattedValue(value: number, prefix: string, postfix: string, valueFormat: string = undefined): string {
    let val = value;
    let decimals = 0;
    if (valueFormat) {
      decimals = getDecimalsCountByFormat(valueFormat);
      const d = Math.pow(10, decimals);
      val = Math.round((val + Number.EPSILON) * d) / d;
    }

    return `${prefix}${val.toLocaleString(undefined, { maximumFractionDigits: decimals, minimumFractionDigits: 0 })}${postfix}`;
  }

  private static calcPercent(value: number, sum: number): number {
    if (sum > 0) return (value / sum) * 100;
    return -1;
  }

  private static isCalcPercent(part: ReportPart): boolean {
    if (
      [TSChLegendTextStyle.tsPercent, TSChLegendTextStyle.tsRightPercent, TSChLegendTextStyle.tsLeftPercent, TSChLegendTextStyle.tsXAndPercent].includes(
        part.LegendTextStyle
      ) ||
      [
        TSChMarksStyle.msLabelPercent,
        TSChMarksStyle.msLabelPercent,
        TSChMarksStyle.msLabelPercentTotal,
        TSChMarksStyle.msLabelPercentValue,
        TSChMarksStyle.msPercent,
        TSChMarksStyle.msPercentRelative,
        TSChMarksStyle.msPercentTotal,
      ].includes(part.MarksStyle)
    ) {
      return true;
    }
    return false;
  }

  private static repairData(part: ReportPart): SimpleChartReportPart {
    if (part.Series && part.Series.length > 0) {
      const result: SimpleChartReportPart = Object.assign({ Labels: [] }, part) as SimpleChartReportPart;

      if (part.Series.length === 1) {
        NclSimpleChart.repairSerie(result, result.Series[0]);
      } else {
        let xValues: Array<number> = [];
        result.Series.map((serie, sndx) => {
          serie.DataPoints.map((dp) => {
            if (dp.XValue) {
              if (xValues.indexOf(dp.XValue) < 0) {
                xValues.push(dp.XValue);
                result.Labels.push(undefined);
              }
            } else {
              if (result.Labels.indexOf(dp.Independent) <= 0) result.Labels.push(dp.Independent);
            }
          });
          if (xValues.length == 0) NclSimpleChart.repairSerie(result, serie);
        });
        if (xValues.length > 0) {
          xValues = xValues.sort((a, b) => a - b);
          result.Series.map((serie, sndx) => {
            const dps: Array<DataPoint> = [];
            xValues.map((value, i) => {
              let foundNdx = -1;
              serie.DataPoints.map((dp, ndx) => {
                if (dp.XValue === value) {
                  foundNdx = ndx;
                  return;
                }
              });
              if (foundNdx >= 0) {
                const dp = result.Series[sndx].DataPoints[foundNdx];
                if (!result.Labels[i]) {
                  result.Labels[i] = dp.Independent;
                }
                dp.OriginNdx = foundNdx;
                dps.push(dp);
              } else {
                dps.push(undefined);
              }
            });
            serie.DataPoints = dps;
            NclSimpleChart.repairSerie(result, serie);
          });
        }
      }
      return result;
    }
  }

  public static repairSerie(part: SimpleChartReportPart, serie: Series, formatValue = true, correctFormatedValue = false) {
    const ldp = serie.DataPoints;
    if (ldp && ldp.length > 0) {
      const isMultiSeries = part.Series.length > 1;
      let sum = 0;
      let max = 0;
      if (!isMultiSeries && NclSimpleChart.isCalcPercent(part)) {
        ldp.map((item) => {
          sum += item.Dependent;
          max = Math.max(item.Dependent, max);
        });
      }
      ldp.map((item, ndx) => {
        if (item) {
          if (!isMultiSeries && part.Labels) part.Labels.push(item.Independent);
          if (formatValue) item.FomattedValue = NclSimpleChart.getFormatedValue(part, item, sum, ndx);
          if (!formatValue && correctFormatedValue) {
            item.FomattedValue = NclSimpleChart.formattedValue(item.Dependent, "", "", part.ValueFormat ? part.ValueFormat : item.FomattedValue);
          }

          switch (part.MarksStyle) {
            case TSChMarksStyle.msValue:
              item.FomattedLabel = NclSimpleChart.formattedValue(item.Dependent, "", "", part.ValueFormat);
              break;
            case TSChMarksStyle.msPercent:
              item.FomattedLabel = NclSimpleChart.formattedValue(NclSimpleChart.calcPercent(item.Dependent, sum), "", "%");
              break;
            case TSChMarksStyle.msLabel:
              item.FomattedLabel = isMultiSeries ? serie.Title : item.Independent;
              break;
            case TSChMarksStyle.msLabelPercent:
              item.FomattedLabel = NclSimpleChart.formattedValue(NclSimpleChart.calcPercent(item.Dependent, sum), `${item.Independent} `, "%");
              break;
            case TSChMarksStyle.msLabelPercentTotal:
              item.FomattedLabel = NclSimpleChart.formattedValue(
                NclSimpleChart.calcPercent(item.Dependent, sum),
                `${item.Independent} `,
                `% of ${NclSimpleChart.formattedValue(item.Dependent, "", "", part.ValueFormat)}`
              );
              break;
            case TSChMarksStyle.msLabelValue:
              item.FomattedLabel = NclSimpleChart.formattedValue(item.Dependent, `${isMultiSeries ? serie.Title : item.Independent} `, "", part.ValueFormat);
              break;
            case TSChMarksStyle.msLegend:
              item.FomattedLabel = NclSimpleChart.getFormatedValue(part, item, sum, ndx);
              break;
            case TSChMarksStyle.msPercentTotal:
              item.FomattedLabel = NclSimpleChart.formattedValue(
                this.calcPercent(item.Dependent, sum),
                ``,
                `% of ${NclSimpleChart.formattedValue(sum, "", "")}`
              );
              break;
            case TSChMarksStyle.msPercentTotal:
              item.FomattedLabel = NclSimpleChart.formattedValue(
                this.calcPercent(item.Dependent, sum),
                `${item.Independent} `,
                `% of ${NclSimpleChart.formattedValue(sum, "", "")}`
              );
              break;
            case TSChMarksStyle.msXvalue:
              item.FomattedLabel = NclSimpleChart.formattedValue(ndx, ``, ``);
              break;
            case TSChMarksStyle.msXY:
              item.FomattedLabel = NclSimpleChart.formattedValue(item.Dependent, `${ndx} `, ``, part.ValueFormat);
              break;
            case TSChMarksStyle.msXY:
              item.FomattedLabel = NclSimpleChart.formattedValue(item.Dependent, `${ndx} `, ``, part.ValueFormat);
              break;
            case TSChMarksStyle.msSeriesTitle:
              item.FomattedLabel = part.Series[0].Title;
              break;
            case TSChMarksStyle.msSeriesTitle:
              item.FomattedLabel = NclSimpleChart.formattedValue(ndx, ``, ``);
              break;
            case TSChMarksStyle.msPercentRelative:
              item.FomattedLabel = NclSimpleChart.formattedValue(NclSimpleChart.calcPercent(item.Dependent, max), ``, `%`);
              break;
            case TSChMarksStyle.msLabelPercentValue:
              item.FomattedLabel = NclSimpleChart.formattedValue(
                NclSimpleChart.calcPercent(item.Dependent, sum),
                `${item.Independent} `,
                `% ${NclSimpleChart.formattedValue(item.Dependent, "", "")}`
              );
              break;
            case TSChMarksStyle.msLabelOrValue:
              item.FomattedLabel = item.Independent ? item.Independent : NclSimpleChart.formattedValue(item.Dependent, "", "", part.ValueFormat);
              break;
            case TSChMarksStyle.msPointIndex:
              item.FomattedLabel = `${ndx}`;
              break;
          }
        }
      });
    }
  }

  private static getFormatedValue(part: ReportPart, item: DataPoint, sum: number, ndx: number): string {
    switch (part.LegendTextStyle) {
      case TSChLegendTextStyle.tsPlain:
        return item.Independent;
      case TSChLegendTextStyle.tsLeftValues:
        return NclSimpleChart.formattedValue(item.Dependent, "", ` ${item.Independent}`, part.ValueFormat);
      case TSChLegendTextStyle.tsRightValue:
        return NclSimpleChart.formattedValue(item.Dependent, `${item.Independent} `, "", part.ValueFormat);
      case TSChLegendTextStyle.tsLeftPercent:
        return NclSimpleChart.formattedValue(NclSimpleChart.calcPercent(item.Dependent, sum), "", `% ${item.Independent}`);
      case TSChLegendTextStyle.tsRightPercent:
        return NclSimpleChart.formattedValue(NclSimpleChart.calcPercent(item.Dependent, sum), `${item.Independent} `, "%");
      case TSChLegendTextStyle.tsXValue:
        return `${ndx}`;
      case TSChLegendTextStyle.tsValue:
        return NclSimpleChart.formattedValue(item.Dependent, "", "", part.ValueFormat);
      case TSChLegendTextStyle.tsPercent:
        return NclSimpleChart.formattedValue(NclSimpleChart.calcPercent(item.Dependent, sum), "", "%");
      case TSChLegendTextStyle.tsXAndValue:
        return NclSimpleChart.formattedValue(item.Dependent, `${ndx} `, "", part.ValueFormat);
      case TSChLegendTextStyle.tsXAndPercent:
        return NclSimpleChart.formattedValue(NclSimpleChart.calcPercent(item.Dependent, sum), `${ndx} `, "%");
      case TSChLegendTextStyle.tsXAndText:
        return NclSimpleChart.formattedValue(item.Dependent, `${ndx} `, "", part.ValueFormat);
      case TSChLegendTextStyle.tsXAndValueAndText:
        return NclSimpleChart.formattedValue(item.Dependent, `${ndx} `, ` ${item.Independent}`, part.ValueFormat);
    }
  }
}

export class NclMap extends UFNclControl<CSNclMapMetadata, UpdateMap> {
  protected createDefaultState(): UpdateMap {
    return new UpdateMap({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclRadioBox extends UFNclControl<CSUFNclRadioBoxMetadata, UpdateRadioBox> {
  protected createDefaultState(): UpdateRadioBox {
    return new UpdateRadioBox({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public change(index: number) {
    this.setActiveControlRequested();
    this.internalSetData<RadioBoxDataRequest>({ CheckedRadioIndex: index }, true, true);
  }

  protected internalInEditMode(): boolean {
    return !this.state.ReadOnly;
  }
}

export class NclClientWidget extends UFNclControl<CSUFNclClientWidgetMetadata, ClientWidgetUpdate> {
  protected createDefaultState(): ClientWidgetUpdate {
    return new ClientWidgetUpdate({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclColorPicker extends NclViewBase<CSNclColorPickerMetadata, UpdateHeadered> {
  private ok: NclButton;
  private cancel: NclButton;

  constructor(ncl: CSNclColorPickerMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.OKBtn) {
      this.ok = new NclButton(ncl.OKBtn, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.ok);
    }

    if (ncl.CancelBtn) {
      this.cancel = new NclButton(ncl.CancelBtn, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.cancel);
    }
  }

  public get OK(): NclButton {
    return this.ok;
  }

  public get Cancel(): NclButton {
    return this.cancel;
  }

  public changeColor(value: number): boolean {
    this.internalSetData<ColorPickerDataRequest>({ Color: value }, false, true, 0);

    return true;
  }

  protected createDefaultState(): UpdateHeadered {
    return new UpdateHeadered({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclWebcam extends UFNclControl<CSNclWebcamMetadata, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalCollectData(collector: ControlDataRequest[]): void {
    if (this.Ncl.FrgtData.AutoCapturePhotoOnAccept) {
      const isAccept = collector.some((data) => data.Functions?.some((func) => func.Name === cJSonFunctionAcceptData));
      const listener = this.Listener as ControlListener;

      if (isAccept && this.Listener && listener.getScreenshot) {
        this.internalSetData<WebcamDataRequest>({ Data: listener.getScreenshot() }, false);
      }
    }

    super.internalCollectData(collector);
  }
}

export enum ControlType {
  Expander = "TNclExpander",
  Image = "TNclImage",
  VRTab = "TNclVRTabControl",
  LibraryReference = "TNclLibraryReference",
  Container = "TNclContainer",
  Panel = "TNclPanel",
  ListView = "TNclListView",
  PanelBase = "TNclPanelBase",
  PreviewPanel = "TNclPreviewPanel",
  DataLabel = "TNclDataLabel",
  SplitPanel = "TNclSplitPanel",
  Tab = "TNclTabControl",
  DetailTab = "TNclDetailTabControl",
  Button = "TNclButton",
  ButtonFaka = "TNclButtonFaka",
  DataGrid = "TNclDataGrid",
  SimpleDataGrid = "TNclSimpleGrid",
  Input = "TNclInput",
  KeyboardInput = "TNclKeyboardInput",
  FormattableInputData = "TNclFormattableInputData",
  CheckBox = "TNclCheckBox",
  GroupBox = "TNclGroupBox",
  Space = "TNclSpace",
  View = "TNclView",
  ToolBar = "TNclTabToolBar",
  Action = "TNclNoFrgtButton",
  ActionTT = "TNclToolBarNoFrgtButton",
  MenuButton = "TNclToolBarMenuButton",
  CommandAction = "TNclCommandAction",
  ActionSeparator = "TNclNoFrgtControlSeparator",
  StackPanel = "TNclStackPanel",
  DynamicContent = "TNclDynamicContent",
  Page = "TNclPageInfo",
  FloaterAccessor = "TNclFloaterAccessor",
  FloaterView = "TNclFloaterView",
  MultiContent = "TNclMultiContent",
  OpenDialog = "TNclOpenDialog",
  SilentOpenDialog = "TNclSilentOpenDialog",
  OpenDialogContent = "TNclSilentOpenDialog.TNclOpenDialogContent",
  ColorPicker = "TNclColorPicker",
  FormattableInput = "TNclInputFormattable",
  MenuView = "TNclMenuView",
  Menu = "TNclMenu",
  MenuItem = "TNclMenuItem",
  ClientMenuItem = "ClientMenuItem",
  MenuDivider = "TNclMenuDivider",
  MenuGroup = "TNclGroup",
  ClientMenuGroup = "ClientMenuGroup",
  LocatorPanel = "TNclLocatorPanel",
  FilePreview = "TNclFilePreview",
  SignInput = "TNclSignInput",
  Calendar = "TNclCalendar",
  Gantt = "TNclGantt",
  ListViewGroup = "TNclListViewGroup",
  ListViewItem = "TNclListViewItem",
  NclAppointment = "TNclAppointment",
  NclProvider = "TNclProvider",

  Ribbon = "TNclRibbon",
  RibbonAction = "TNclRibbonButton",
  Breaker = "TNclNoFrgtControlBreaker",
  TreeView = "TNclTreeView",
  QuickFilter = "TNclQuickFilter",
  InplaceView = "TNclInPlaceView",
  Dashboard = "TNclDashboard",
  DeviceConnector = "TNclDeviceConnectorDialog",
  GPSReader = "TNclGPSDialog",
  MultiFormatText = "TUFControlNclMultiFormatterText",
  VirtualKeyboardDialog = "TNclVirtualKeyboardDialog",
  WebView = "TNclWebView",
  CodeReaderDialog = "TNclCodeReaderDialog",
  InstantFilterPanel = "TNclDataGrid.TNclInstanntFilterPanel",
  FlowChart = "TNclFlowchart",
  InnerGantt = "TNclInnerGantt",
  WebContentEditor = "TNclWebContentEditor",
  SimpleChart = "TNclSimpleChart",
  SimpleChartDynamic = "TNclSimpleChartDynamic",
  Map = "TNclMap",
  RadioBox = "TNclRadioBox",
  ClientWidget = "TNclClientWidget",
  Webcam = "TNclWebcam",
}
