import {
  CSViewRealizerStructure,
  UpdateBase,
  MultiMessage,
  DataRequest,
  UserInfo,
  TNclInputType,
  DEFAULT_LANG_IMG,
  LoginInfo,
  OutData,
  CSClientData,
  clNone,
  LoginData,
  AppData,
  CSUpdateCommandItem,
  getNotifySvg,
  ModalPosition,
  DataFromDM,
  CSUpdateView,
} from "./common/communication.base";
import { Helper, Log, delay, isNewVersionAvailable, Version, GPSReader, clearLoginCookie, parseData } from "./common/common";
import { Context, __ } from "./appcontext";
import { ViewRealizer, ViewRealizerManager, RealizerOperations } from "./viewrealizer";
import { NclMessageType, NclMessage, NclMessageHelper, ReceiveData, Connection, PostbackMessage } from "./common/communication";
import { NclBaseTabControl, NclCommandItem, NclControlBase, NclDeviceConnectorDialog, NclDockControl, NclInput } from "./common/components.ncl";
import { initSmartlook } from "./smartlook";
import { initFoxentry } from "./foxentry";
import BusyIndicatorProvider from "./components/BusyIndicator/K2BusyIndicator";
import { FullScreenProvider } from "./components/FilePreview/K2FullScreenViewer";
import { FormatUtils, LocalInputInfo, createRESeparator } from "./common/formatUtils";

declare global {
  interface Window {
    DESKTOP?: any;
  }
}

interface NotifyIndicator {
  root: HTMLElement;
  indicator: HTMLElement;
}

interface LastMessage {
  sent: NclMessage & { control: NclControlBase };
}

interface ModalInfo {
  LastModalRealizerUID: string;
  LastActiveRealizerUID: string;
}

/**
 * Základní třída celé aplikace.
 * Navazuje spojení se serverem pomoci websocket a zpracovává zprávy přijimané ze serveru.
 * Obsahuje seznam aktivních realieru kterým jsou zprávy předávané ke zpracování.
 */
export abstract class ApplicationBase {
  private isReconnecting: boolean;
  private reconnectData: LoginData;
  protected userInfo: UserInfo;
  private connection: Connection;
  private _appViewRealizer: ViewRealizer;
  private _screenSize: number;
  private _lastScreenSize: number;
  private ignore = 0; //promenna pro moznost zablokovani posilani pozadavku na server
  private menuPosition: ModalPosition = { x: 0, y: 0 };
  private modalRealizerUID: string;
  public busyIndicator: BusyIndicatorProvider;
  public fullScreenViewer: FullScreenProvider;
  private fullScreenMessage = new FullScreenMessage();
  private timer: any;
  private messagesToSend: Array<NclMessage>;
  private activeVRUID: string;
  private mainTabControlUID: string;
  private companyColor: number;
  private companyID: number;
  private lastUserActivity: Date;
  private actionControlData: { actionControlUID: string; realizerUID: string };
  private activityEvents = ["mousedown", "mousemove", "keydown", "scroll", "touchstart"];
  private modals: Array<ModalInfo> = new Array<ModalInfo>();
  private appData: AppData;
  private notifyIndicator: NotifyIndicator | string;
  private version: string;
  protected hideReloadAlert: boolean;
  private user: string;
  private lastMessage: LastMessage = { sent: null };
  protected lastRequestStartTime: number;
  private errorMessageToken: string;
  private gpsReader: GPSReader;
  private localizationVersion: number;
  private busyMonitoringTime: number;

  constructor() {
    this._appViewRealizer = null;
    this.isReconnecting = false;
    this.messagesToSend = new Array<NclMessage>();
    this.busyIndicator = new BusyIndicatorProvider();
    this.localizationVersion = 0;
  }

  public get ActionControlData(): { actionControlUID: string; realizerUID: string } {
    return this.actionControlData;
  }

  public get BusyIndicator(): BusyIndicatorProvider {
    return this.busyIndicator;
  }

  public set LocalizationVersion(value: number) {
    this.localizationVersion = value;
    this.updateLocalization(this.localizationVersion);
  }

  public set ActionControlData(data: { actionControlUID: string; realizerUID: string }) {
    this.actionControlData = data;
  }

  public get ModalCount(): number {
    return this.modals.length;
  }

  public get Version(): string {
    return this.version;
  }

  public set Version(version: string) {
    this.version = version;
  }

  public get HideReloadAlert(): boolean {
    return this.hideReloadAlert || Context.DeviceInfo.IsTestMode;
  }

  public get User(): string {
    return this.user;
  }

  public get ErrorMessageToken() {
    return this.errorMessageToken;
  }

  public get ReconnectData(): LoginData {
    return this.reconnectData;
  }

  public get LastMessage(): LastMessage {
    return this.lastMessage;
  }

  public get UserInfo(): UserInfo {
    return this.userInfo;
  }

  public get AppData(): AppData {
    return this.appData;
  }
  /**
   * Use only for unit test
   */
  public clearActiveVRUID() {
    this.activeVRUID = undefined;
  }

  public get ActiveVRUID(): string {
    return this.activeVRUID;
  }

  public getGPSReader(): GPSReader {
    if (!this.gpsReader) {
      this.gpsReader = new GPSReader();
    }

    return this.gpsReader;
  }

  protected internalRender(): Promise<void> {
    window.addEventListener("keydown", this.keyDown);
    window.addEventListener("orientationchange", this.orientationchange);
    window.addEventListener("mousemove", this.mousemove);
    window.addEventListener("error", this.handleError);
    window.addEventListener("unhandledrejection", this.handleRejection);
    return null;
  }

  private startNoopTimeout() {
    if (this.userInfo && this.userInfo.NoopTimeout > 0) {
      if (this.userInfo.InactivityTimeout > 0) this.lastUserActivity = new Date();
      let interval = this.userInfo.NoopTimeout;
      if (this.userInfo.InactivityTimeout && interval > this.userInfo.InactivityTimeout) {
        interval = this.userInfo.InactivityTimeout;
      }
      interval = interval < 120 ? 60 : interval / 2;
      const __this = this;
      this.stopNoopTimeout();
      this.timer = setInterval(() => {
        const inactivitySec = __this.userInfo.InactivityTimeout > 0 ? (new Date().getTime() - __this.lastUserActivity.getTime()) / 1000 : undefined;
        if (__this.isReconnecting) return;
        if (
          (new Date().getTime() - __this.getConnection().LastActivity.getTime()) / 1000 >= __this.userInfo.NoopTimeout ||
          (inactivitySec !== undefined && inactivitySec >= __this.userInfo.InactivityTimeout)
        ) {
          __this.internalSendMessage(NclMessageHelper.CreateNoopMsg(inactivitySec));
        }
      }, interval * 1000);
    }
  }

  private stopNoopTimeout() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  getCompanyColor(): number {
    return this.companyColor;
  }

  getCompanyID(): number {
    return this.companyID;
  }

  setCompanyColor(value: number) {
    if (value === clNone) this.companyColor = 0;
    else this.companyColor = value;
  }

  setCompanyID(value: number) {
    this.companyID = value;
  }

  public getMainDockControl(): NclDockControl {
    return null;
  }

  public getActiveRealizer(): ViewRealizer {
    let result: ViewRealizer;
    if (this.activeVRUID) {
      result = ViewRealizerManager.getViewRealizer(this.activeVRUID);
    }

    if (!result) {
      return this.appViewRealizer;
    }

    return result;
  }

  public setActiveRealizerUID(realizer: ViewRealizer) {
    if (realizer && realizer.isActive() && this.activeVRUID !== realizer.getRealizerUID()) {
      const vr = ViewRealizerManager.getViewRealizer(this.activeVRUID);
      if (vr) {
        vr.notifyAsActive(false);
      }
      this.activeVRUID = realizer.getRealizerUID();
      realizer.notifyAsActive(true);
    }
  }

  public registerMainTabControl(ctrl: NclBaseTabControl<any, any>): boolean {
    if (!this.mainTabControlUID) {
      this.mainTabControlUID = ctrl.MetaData.ControlUID;
      return true;
    }
    return false;
  }

  public unRegisterMainTabControl(ctrl: NclBaseTabControl<any, any>) {
    if (this.mainTabControlUID === ctrl.MetaData.ControlUID) {
      this.mainTabControlUID = undefined;
    }
  }

  public get appViewRealizer(): ViewRealizer {
    return this._appViewRealizer;
  }

  public get MainTabControlUID(): string {
    return this.mainTabControlUID;
  }

  private async reconnect(lastmessage: NclMessage) {
    if (this.reconnectData && this.reconnectData.ReconnectUID) {
      if (!this.isReconnecting) {
        const reloadBtn = document.querySelector("#reloadPageBtn") as HTMLButtonElement;

        this.isReconnecting = true;
        this.busyIndicator.setBusyIndicator(true, __("loadingPleaseWait") + "...", __("loadingConnectAttempt"), true, true);
        this.stopNoopTimeout();
        await delay(500);

        let promise: Promise<void>;
        if (!this.connection.isConnected) {
          this.connection = null;
          promise = this.getConnection().connect();
        } else {
          promise = Promise.resolve();
        }

        promise
          .then(async () => {
            if (Context.DeviceInfo.AutoLogin === "1") {
              const version = await isNewVersionAvailable();

              if (version === Version.new) {
                this.reloadPage();
              }
            }

            this.close(true, false);
            this.ignore = 0;
            this.internalSendMessage(
              NclMessageHelper.CreateRealizeAppMsg(this.reconnectData),
              (message) => {
                this.busyIndicator.setBusyIndicator(false);

                this.isReconnecting = false;
                this.allowSendMessages();
                if (lastmessage) {
                  this.sendMessage(lastmessage);
                }

                this.startNoopTimeout();
              },
              (reason) => {
                location.reload();
              }
            );
          })
          .catch((reason) => {
            this.busyIndicator.setBusyIndicator(false);
            return this.reconnect(lastmessage);
          });
      } else {
        this.reconnectForm(async () => {
          this.isReconnecting = false;
          await this.reconnect(lastmessage);
        });
      }
    } else {
      location.reload();
    }
  }

  private liCache: Array<LocalInputInfo> = new Array<LocalInputInfo>();
  private liFormCache: Map<string, LocalInputInfo> = new Map<string, LocalInputInfo>();

  public getLocalInputInfo(input: NclInput): LocalInputInfo {
    let result: LocalInputInfo;
    if (input.State.Format) result = FormatUtils.getLocalInfoByFormat(input.State.Format);
    if (!result) result = this.getLocalInfoByType(input.State.InputType);
    if (!result) {
      switch (input.State.InputType) {
        case TNclInputType.nitInteger:
          {
            result = this.getIntegerInputType(input);
          }
          break;
        case TNclInputType.nitFloat:
          {
            result = this.getFloatInputType(input);
          }
          break;
        case TNclInputType.nitDate:
          {
            result = this.getDateInputType();
          }
          break;
        case TNclInputType.nitTime:
          {
            if (input.State.Format) result = FormatUtils.getTimeInputTypeByFormat(input.State.Format);
            if (!result) result = this.getTimeInputType(false);
          }
          break;
        case TNclInputType.nitDateTime:
          {
            const dInfo = this.getDateInputType();
            const tInfo = this.getTimeInputType(true);

            result = { regExpr: new RegExp(`${dInfo.regExpr.source.slice(0, -1)}[ ]?${tInfo.regExpr.source.substring(1).slice(0, -1)}?$`) };
            this.liCache[TNclInputType.nitDateTime] = result;
          }
          break;
      }
    }

    return result;
  }

  private getIntegerInputType(input: NclInput) {
    const result: LocalInputInfo = { regExpr: undefined };
    result.regExpr = new RegExp(`^-?[0-9]*$`);
    this.liCache[TNclInputType.nitInteger] = result;

    return result;
  }

  private getFloatInputType(input: NclInput) {
    const result: LocalInputInfo = { regExpr: undefined };
    const n = 1.1;
    const resSep = createRESeparator(FormatUtils.getSeparator(TNclInputType.nitFloat));
    result.regExpr = new RegExp(`^-?[0-9]*${resSep}?[0-9]${input.getDecimalsRegExpByFormat()}$`);

    return result;
  }

  private getDateInputType() {
    const result: LocalInputInfo = { regExpr: undefined };
    const date = new Date("2020-12-24");
    const dateStr = date.toLocaleDateString();
    const dayStr = date.getDate().toString();
    const monthStr = (date.getMonth() + 1).toString();
    const yearStr = date.getFullYear().toString();
    let sepNdx = dateStr.indexOf(dayStr);
    if (sepNdx + dayStr.length < dateStr.length) {
      sepNdx += dayStr.length;
    } else {
      sepNdx = dateStr.indexOf(monthStr) + monthStr.length;
    }
    const separator = FormatUtils.getSeparator(TNclInputType.nitDate);

    const resSep = createRESeparator(separator);
    const strs = dateStr.split(separator);
    const parts: Array<string> = new Array<string>();
    if (strs && strs.length === 3) {
      let part: string;
      strs.map((val, i) => {
        part = undefined;
        val = val.trim();
        if (val === yearStr) part = `(\\d{1,4})`;
        if (val === monthStr) part = `(0?[1-9]|1?[0-2]|\\d)`;
        if (val === dayStr) part = `(0?[1-9]|[1-2]?\\d|3?[0-1]|\\d)`;

        if (part) {
          parts.push(part);
        }
      });

      if (parts.length !== strs.length) throw Error("Invalid regular exprtession.");

      result.regExpr = new RegExp(
        `^(${parts[0]}|${parts[0]}?${resSep}|${parts[0]}?${resSep}${parts[1]}|${parts[0]}?${resSep}${parts[1]}?${resSep}|${parts[0]}?${resSep}${parts[1]}?${resSep}${parts[2]}?)$`
      );
    }
    this.liCache[TNclInputType.nitDate] = result;

    return result;
  }

  public getTimeInputType(forDateTime: boolean) {
    const result: LocalInputInfo = { regExpr: undefined };

    const resSep = createRESeparator(FormatUtils.getSeparator(TNclInputType.nitTime));
    result.regExpr = new RegExp(
      `^((([01]?\\d|2[0-3])(${resSep}?([0-5]?\\d)?)${resSep}([0-5]?\\d)?)|(${resSep}([0-5]?\\d)?)${resSep}?([0-5]?\\d)?|([01]?\\d|2[0-3]))$`
    );

    if (forDateTime == false) this.liCache[TNclInputType.nitTime + Number.MAX_SAFE_INTEGER] = result;

    return result;
  }

  public getLocalInfoByType(type: TNclInputType): LocalInputInfo {
    if (type === TNclInputType.nitFloat) return; // Float má proměnný formát. Cache není žádoucí
    if (this.liCache && type < this.liCache.length) {
      return this.liCache[type];
    }
  }

  private clear() {
    this.modalRealizerUID = null;
    this.modals = new Array<ModalInfo>();
    this._appViewRealizer = null;
    this.ignore = 0;
    ViewRealizerManager.clear();
    this.internalClear();
  }

  protected abstract internalClear(): void;

  public async render(vr: ViewRealizer) {
    this._appViewRealizer = vr;

    if (this.userInfo && this.userInfo.InactivityTimeout > 0) {
      const __this = this;
      this.activityEvents.forEach(function (eventName) {
        document.addEventListener(eventName, __this.userActivityHandler, true);
      });
    }
    await this.internalRender();
  }

  protected abstract showError(errorMessage: string): void;
  protected abstract updateLocalization(localizationVersion: number): void;

  public getPictureServerUrl(isStatic: boolean): string {
    return isStatic ? `${this.getConnection().getBaseUrl()}/images` : `${this.getConnection().getBaseUrl()}/Picture/GetPicture?`;
  }

  public getCurrentK2LanguageImgFolder(): string {
    if (!this.userInfo) return DEFAULT_LANG_IMG;
    else return `Lang/Lang${this.userInfo.Lng}`;
  }

  public addActualScreenSize(request: DataRequest) {
    if (this._screenSize !== this._lastScreenSize) {
      request.ScreenSize = this._screenSize;
      request.TransformColumnsCount = Context.DeviceInfo.TransformColumnsCount;
      this._lastScreenSize = this._screenSize;
    }
  }

  public getUserLanguage(): string {
    const userLang = navigator.language;
    if (userLang && userLang != "undefined") {
      return userLang;
    }
    return "en-US";
  }

  /**
   * Spuštění aplikace.
   */
  public async run() {
    this.loginForm();
  }

  public async showModal(realizerUID: string): Promise<void> {
    if (this.appViewRealizer) {
      const mi: ModalInfo = {
        LastModalRealizerUID: this.modalRealizerUID,
        LastActiveRealizerUID: undefined,
      };

      this.modals.push(mi);

      if (this.ActiveVRUID != "") {
        const vr = ViewRealizerManager.getViewRealizer(this.ActiveVRUID);
        if (vr && vr.getRoot() && vr.getRoot().State.Enabled) {
          vr.getRoot().updateState({ Enabled: false });
          mi.LastActiveRealizerUID = this.ActiveVRUID;
        }
      }
      this.modalRealizerUID = realizerUID;
      await ViewRealizer.getNearestListener(this.appViewRealizer).showModal(realizerUID);
      return Promise.resolve();
    }
    return Promise.reject("App realizer not found.");
  }

  public async closeModal(realizerUID: string): Promise<void> {
    if (this.appViewRealizer) {
      const mi = this.modals.pop();
      this.modalRealizerUID = mi.LastModalRealizerUID;
      if (mi.LastActiveRealizerUID !== "") {
        const vr = ViewRealizerManager.getViewRealizer(mi.LastActiveRealizerUID);
        if (vr && vr.getRoot()) {
          vr.getRoot().updateState({ Enabled: true });
          this.setActiveRealizerUID(vr);
        }
      }
      await ViewRealizer.getNearestListener(this.appViewRealizer).closeModal(realizerUID);
      return Promise.resolve();
    }

    return Promise.reject("App realizer not found.");
  }

  protected abstract loginForm(): void;

  protected abstract reconnectForm(reconnectFce: () => void): void;

  public realizeApp(data: LoginData, username: string) {
    this.user = username;
    this.errorMessageToken = data.ErrorMessageToken;
    this.busyMonitoringTime = data.Settings?.BusyMonitoringTime ?? 10000;

    let asInfo = "unknown";
    if (data.AS3Pipe && data.AS3Server) {
      asInfo = `${data.AS3Pipe}(${data.AS3Server})`;
    }
    initSmartlook(data.Settings?.SmartlookProjectKey, data.Settings?.EnableSmartlookForUsers, username, asInfo);
    initFoxentry(data.Settings?.FoxentryProjectKey);
    if (this.appViewRealizer === null) {
      if (data) {
        this.reconnectData = data;
        if (this.reconnectData.ReconnectUID) {
          this.reconnect(null);
          return;
        }
      }
      this.internalSendMessage(NclMessageHelper.CreateRealizeAppMsg(undefined));
    }
  }

  public closeSession(user: string, sessionId: string, useQuickLogin: boolean, password?: string): Promise<boolean> {
    return this.getConnection().closeSession(user, sessionId, useQuickLogin, password);
  }

  public login(user: string, sessionId: string, useQuickLogin: boolean, password?: string): Promise<LoginData> {
    return this.getConnection().login(user, sessionId, useQuickLogin, password);
  }

  public wait2FResult(wsid: string): Promise<LoginData> {
    return this.getConnection().wait2FResult(wsid);
  }

  public getLoginInfo(user: string, password: string, useQuickLogin: boolean): Promise<LoginInfo> {
    return this.getConnection().getLoginInfo(user, password, useQuickLogin);
  }

  /**
   * Ukončení aplikace.
   */
  public async close(safeReconnect: boolean, sendTerminate: boolean) {
    if (!this._appViewRealizer) return;
    if (sendTerminate) {
      navigator.sendBeacon(
        `${this.connection.getBaseUrl()}/close`,
        JSON.stringify(NclMessageHelper.CreateTerminateMsg(this._appViewRealizer ? this._appViewRealizer.getRealizerUID() : ""))
      );
    }

    if (safeReconnect === false) this.reconnectData = null;
    window.removeEventListener("keydown", this.keyDown);
    window.removeEventListener("orientationchange", this.orientationchange);
    window.removeEventListener("mousemove", this.mousemove);
    window.removeEventListener("error", this.handleError);
    window.removeEventListener("unhandledrejection", this.handleRejection);

    if (this.userInfo && this.userInfo.InactivityTimeout > 0) {
      const __this = this;
      this.activityEvents.forEach(function (eventName) {
        document.removeEventListener(eventName, __this.userActivityHandler, true);
      });
    }

    this.clear();
  }

  /**
   * Methods forbid send messages to server.
   */
  public forbidenSendMessages() {
    this.ignore++;
  }

  /**
   * Methods allow send messages to server and send message from queue.
   */
  public allowSendMessages() {
    if (this.ignore > 0) this.ignore--;

    if (this.ignore === 0) {
      if (this.messagesToSend.length > 0) {
        const msg = this.messagesToSend.shift();
        if (msg) {
          if (this.sendMessage(msg) !== true) {
            this.allowSendMessages();
          }
        }
      }
    }
  }

  private userActivityHandler() {
    const __this = Context.getApplication();
    __this.lastUserActivity = new Date();
  }

  private canSendMessage(msg: NclMessage, control?: NclControlBase): boolean {
    if (Context.WaitingForDeviceConnector && !(control instanceof NclDeviceConnectorDialog)) return false;
    return !this.modalRealizerUID || this.modalRealizerUID === msg.realizerUID || msg.messageType == NclMessageType.nmsgCheckUnexpected;
  }

  public isBusy(): boolean {
    return this.ignore > 0 || this.isReconnecting === true;
  }

  public get BusyMonitoringTime(): number {
    return this.busyMonitoringTime;
  }

  public reloadPage() {
    this.hideReloadAlert = true;
    location.reload();
  }

  public sendMessage(message: NclMessage, control?: NclControlBase): boolean {
    if (!this.canSendMessage(message, control)) return false;
    if (!this.isBusy()) {
      const vr = ViewRealizerManager.getViewRealizer(message.realizerUID);
      if (message.realizerUID && (!vr || !vr.canSendRequest())) return false;

      if (message.messageType !== NclMessageType.nmsgCheckUnexpected) {
        this.lastMessage.sent = {
          ...message,
          control: control,
        };
      }

      this.internalSendMessage(message);
      return true;
    }

    this.messagesToSend.push(message);
    return true;
  }

  private internalSendMessage(message: NclMessage, onResolve: (message: NclMessage) => void = null, onReject: (reason: any) => void = null) {
    this.forbidenSendMessages();
    this.lastRequestStartTime = new Date().getTime();
    if (!this.getConnection().send(message)) {
      this.allowSendMessages();
      this.reconnect(message);
      return;
    }
    if (message.messageType !== NclMessageType.nmsgNoop) this.stopNoopTimeout();

    if (!this.busyIndicator.isActive) {
      this.busyIndicator.setBusyIndicator(true);
    }

    //console.time('sendMessage');
    this.getConnection()
      .receive()
      .then(async (result: ReceiveData) => {
        //console.timeEnd('sendMessage');
        Log.debug(
          "REQUEST:" +
            JSON.stringify(message) +
            " RESPONSE: " +
            JSON.stringify(result) +
            " - DURATION: " +
            (new Date().getTime() - this.lastRequestStartTime) +
            "ms"
        );

        if (this.messagesToSend?.length == 0) this.busyIndicator.setBusyIndicator(false);

        if (result) {
          if (result.message && result.message.messageType === NclMessageType.nmsgTerminate) {
            if (onReject !== null) {
              onReject("Terminated"); //reconnect session on server was killed
            } else {
              this.terminate(result.message);
            }
            this.busyIndicator.setBusyIndicator(false); //Terminate ukoncuje Busy vzdy
            return;
          }
          if (message.messageType !== NclMessageType.nmsgNoop) this.startNoopTimeout();

          if (message.messageType === NclMessageType.nmsgNoop) {
            if (this.reconnectData && result.message.realizerUID) {
              this.reconnectData.ReconnectUID = result.message.realizerUID;
            }
            if (this.messagesToSend?.length == 0) this.busyIndicator.setBusyIndicator(false); //Noop jen pokud je prazdna fronta
            this.allowSendMessages();
            return;
          }

          await NclMessageHelper.unzipMessage(result.message);
          await this.receiveNclMessage(result.message);
          if (this.messagesToSend?.length == 0) this.busyIndicator.setBusyIndicator(false); //NCL odpoved jen pokud je fronta prazdna
          this.allowSendMessages();
          this.executeModalMenuAction();

          if (result.error) {
            //
            Log.error("Send message: " + message + "response: " + result.error.message, result.error);
            if (onReject) onReject(result.error);
          } else {
            if (onResolve) onResolve(result.message);
          }
        } else {
          Log.error("Send message: " + message + "response: null.", null);
          this.busyIndicator.setBusyIndicator(false); //Null odpoved vzdy
          this.allowSendMessages();
        }
      })
      .catch((reason: any) => {
        Log.error(reason, null);
        this.busyIndicator.setBusyIndicator(false);
        if (reason.code === 1000) {
          if (onReject) onReject(reason);
        } else {
          this.allowSendMessages();
          this.reconnect(message);
        }
      });
  }

  public executeModalMenuAction() {
    if (this.ActionControlData) {
      (
        ViewRealizerManager.getViewRealizer(this.actionControlData.realizerUID).getControlByUID(this.ActionControlData.actionControlUID) as NclCommandItem
      ).executeCommand();
      this.ActionControlData = null;
    }
  }

  public canStopPostback(message: PostbackMessage): boolean {
    if (this.busyIndicator) {
      this.busyIndicator.setPostbackProgress(message.ProgressTitle, message.ProgressPercentage);
    }
    return this.busyIndicator.canStopPostback();
  }

  public setMenuPosition(x: number, y: number) {
    if (x && y) {
      this.menuPosition = { x: x, y: y };
    }
  }

  private receiveNclMessage(data: NclMessage): Promise<void | void[]> {
    if (data) {
      if (data.messageType == NclMessageType.nmsgMulti) {
        const messages: MultiMessage[] = parseData(data.json);
        if (messages != null && messages.length > 0) {
          return new Promise((resolve, reject) => {
            this.next(messages, 0, resolve);
          });
        }
      } else {
        return new Promise((resolve, reject) => {
          this.processMessage(data, resolve);
        });
      }
    }
  }

  private next(messages: MultiMessage[], index: number, callback: () => void) {
    if (messages && messages.length > 0 && index < messages.length) {
      this.processMessage(NclMessageHelper.Create(messages[index]), () => {
        return this.next(messages, index + 1, callback);
      });
    } else {
      callback();
    }
  }

  private async processMessage(message: NclMessage, callback: () => void) {
    switch (message.messageType) {
      case NclMessageType.nmsgRealize:
        const structure: CSViewRealizerStructure = parseData(message.json);
        if (this.appViewRealizer && structure.RealizerUID === this.appViewRealizer.getRealizerUID()) {
          this.close(true, false);
        }
        const vr = ViewRealizerManager.getOrCreate(message.realizeCounter, structure);
        if (vr != null) {
          vr.processData(message.realizeCounter, structure, callback);
          return;
        }
        break;
      case NclMessageType.nmsgData:
        {
          const vr = ViewRealizerManager.getViewRealizer(message.realizerUID);
          if (vr != null) {
            const data: UpdateBase = parseData(message.json);
            vr.processData(message.realizeCounter, data, callback);
            return;
          }
        }
        break;
      case NclMessageType.nmsgClose:
        {
          const vr = ViewRealizerManager.getViewRealizer(message.realizerUID);
          if (vr != null) {
            await vr.close();
            //reamove all request for closing VR.
            this.messagesToSend = this.messagesToSend.filter((msg) => {
              return msg.realizerUID !== message.realizerUID;
            });
            if (callback) callback();
            return;
          }
        }
        break;
      case NclMessageType.nmsgOutData: {
        const files: OutData = parseData(message.json);
        this.processClientData(files.OutData);
        break;
      }
      case NclMessageType.nmsgTerminate:
        this.terminate(message);
        break;
      case NclMessageType.nmsgOK:
      default:
        if (this.reconnectData) {
          if (message.realizerUID && ViewRealizerManager.getViewRealizer(message.realizerUID) == undefined) {
            this.reconnectData.ReconnectUID = message.realizerUID;
          }
          if (!this.userInfo) {
            this.userInfo = parseData(message.json) as UserInfo;
            if (this.userInfo) {
              Context.LocalizationManager.setLang(this.userInfo.Lng);
              if (this.userInfo.NoopTimeout > 0) {
                this.startNoopTimeout();
              }
            }
          }

          if (!this._appViewRealizer) {
            this.internalSendMessage(NclMessageHelper.CreateRealizeMsg(this.isReconnecting), callback);
            return;
          } else {
            if (message.realizerUID === this.appViewRealizer.getRealizerUID() && message.json) {
              const appData = JSON.parse(message.json);
              if (appData) {
                this.setAppData(appData);
              }
            }
          }
        }

        break;
    }

    if (callback) {
      callback();
    }
  }
  private setAppData(appData: AppData) {
    if (!this.appData) {
      this.appData = {} as AppData;
    }
    const nc = this.appData.NotifyCount;
    const msg = this.appData.ReinstallMsg;
    Object.assign(this.appData, appData);
    if (msg != appData.ReinstallMsg) {
      this.appViewRealizer.getRoot().updateState<CSUpdateView>({ ReinstallMsg: this.appData.ReinstallMsg });
    }
    if (this.appData.NotifyCount !== nc) {
      if (this.notifyIndicator) {
        if (typeof this.notifyIndicator == "string") {
          const root = document.getElementById(this.notifyIndicator);
          if (root) {
            const ni = { root: root } as NotifyIndicator;
            ni.indicator = document.getElementById(this.notifyIndicator + "_indicator");
            if (ni.indicator) this.notifyIndicator = ni;
          }
        }
        if (this.notifyIndicator && typeof this.notifyIndicator !== "string") {
          if (!this.notifyIndicator.indicator) {
            this.notifyIndicator = undefined;
          } else {
            if (this.appData.NotifyCount <= 0) {
              this.notifyIndicator.indicator.setAttribute("visibility", "hidden");
            } else {
              this.notifyIndicator.indicator.setAttribute("visibility", "visible");
            }
          }
        }
      }

      if (!this.notifyIndicator) {
        const ctrl = this.appViewRealizer.findControlByName("ShowNotification");
        if (ctrl instanceof NclCommandItem) {
          this.notifyIndicator = `notifyIndicator_${this.appViewRealizer.getRealizerUID()}`;
          ctrl.updateState<CSUpdateCommandItem>({ GlyphId: `svg*${getNotifySvg(this.notifyIndicator, this.appData.NotifyCount)}` });
        }
      }
    }
  }

  public terminate(message?: NclMessage) {
    // console.log("Receive terminate");
    if (message && message.json) {
      console.error(message.json);
      this.showError(message.json);
      return;
    } else {
      clearLoginCookie(false);
    }

    this._appViewRealizer = null;
    this.stopNoopTimeout();
    location.reload();
  }

  protected abstract processClientData(list: Array<CSClientData>): void;

  protected createConnection(): Connection {
    let url: string = window.location.protocol + "//" + window.location.host + window.location.pathname;

    if (process.env.NODE_ENV === "development") {
      url = process.env.URL; // Webpack Dev
    }

    if (window.DESKTOP?.server_url) {
      url = window.DESKTOP?.server_url; // Web Connector
    }

    if (!url.match(/\b(?:https?:\/\/)\S+/g)) {
      url = "http://" + url;
    }

    return new Connection(new URL(url));
  }

  private getConnection(): Connection {
    if (this.connection == null) {
      this.connection = this.createConnection();
    }

    return this.connection;
  }

  /**
   * Odchyt stisku tl. Vyvolá metodu keyDown na vsech zaregistrovaných realizerech a pokud ji nektery obslouží tak
   * končí a události zakáže probublání dále.
   */
  public keyDown = (ev: KeyboardEvent) => {
    let vr: ViewRealizer = Context.getApplication().getActiveRealizer();
    vr = vr == null ? this.appViewRealizer : vr;
    if (vr != null) {
      const hk = Helper.convertToTxt(ev);
      if (hk != "" && vr.processHotKey(hk)) {
        if (hk.includes("LEFT") || hk.includes("RIGHT")) return false;

        ev.preventDefault();
        ev.stopPropagation();
        return false;
      }
    }
  };

  private orientationchange = (event: Event) => {
    window.addEventListener("resize", this.handleResize);
  };

  handleResize = () => {
    const size = Context.DeviceInfo.ScreenWidth;
    if (size != this._lastScreenSize) {
      this._screenSize = size;
      Context.getApplication().getActiveRealizer().sendRequest(RealizerOperations.Update);
    }
    window.removeEventListener("resize", this.handleResize);
  };

  private mousemove(event: MouseEvent) {
    Context.getApplication().setMenuPosition(event.clientX, event.clientY);
  }

  private async handleError(e: ErrorEvent) {
    if (e.error) {
      await Helper.sendErrorMessage(e.message, e.error.stack);
    }
  }

  private handleRejection(e: PromiseRejectionEvent) {
    throw new Error(e.reason.stack);
  }

  public getMenuPositon(): ModalPosition {
    return this.menuPosition;
  }
}

export class FullScreenMessage {
  private fullScreenMessageContainer: HTMLElement = document.getElementById("fullScreenMessage");
  private fullScreenMessageText: HTMLElement = document.getElementById("fullScreenMessageText");

  public showMessage = (text: string): void => {
    if (this.fullScreenMessageContainer && this.fullScreenMessageText) {
      this.fullScreenMessageContainer.setAttribute("style", "display: block;");
      this.fullScreenMessageText.innerHTML = text + this.getReloadButton();
    }
  };

  private getReloadButton(buttonText = __("loadingAgain")): string {
    return '<div class="cleaner height20"></div><button onClick="location.reload(true);">' + buttonText + "</button>";
  }
}

export const K2Key = "K2NejlepsiERP";
